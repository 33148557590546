import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Typography, Grid, Box } from "@mui/material";
import useStyleBuilder from "../../../hooks/useStyleBuilder";
import ActionButton from "./Button";
import { useIsMobile } from "../../../omnia/hooks/use-is-mobile";
import useImageLoader from "../../../omnia/hooks/use-image-loader";
import useTranslator from "../../../hooks/useTranslator";

function FeatureImage({src, height}){
    const { imageSrc } = useImageLoader(src, '/assets/images/loading_cms_dark.jpg');
    return <div style={{ marginBottom: 30, height: height, width: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url(' + imageSrc + ')' }} />;
}

function Feature({component, texts, setup, images, sendMessage, t}){

    const [data, setData] = useState([]);
    const [col, setCol] = useState(12);
    const { isMobile } = useIsMobile();
    const { getTextStyles } = useStyleBuilder();

    useEffect(() => {
        if(setup && (typeof setup['misc'] !== "undefined")){

            let data = [];
            let topline, headline, text, buttonText, height, image = "";
            for(let i = 0; i < setup['misc']['count']; i++){

                topline = component.texts.filter(t => t.identifier === "topline-" + i).length === 1 ? component.texts.find(t => t.identifier === "topline-" + i) : null;
                headline = component.texts.filter(t => t.identifier === "headline-" + i).length === 1 ? component.texts.find(t => t.identifier === "headline-" + i) : null;
                text = component.texts.filter(t => t.identifier === "text-" + i).length === 1 ? component.texts.find(t => t.identifier === "text-" + i) : null;
                buttonText = component.texts.filter(t => t.identifier === "buttonText-" + i).length === 1 ? component.texts.find(t => t.identifier === "buttonText-" + i) : null;
                height = setup['misc']["height-" + i] ? parseInt(setup['misc']["height-" + i]) : 200;

                image = images.filter(img => img.id === setup['images']['feature-' + i]);
                image = image.length === 1 ? image[0].view : "";

                data = data.concat([{
                    'topline': topline,
                    'headline': headline,
                    'text': text,
                    'height': height,
                    'image': image,
                    'url': setup['misc']['buttonLink-' + i],
                    'buttonText': buttonText,
                }]);
            }

            setData(data);
            if(isMobile){
                setCol(12);
            } else {
                if(setup['misc']['count'] === 1){
                    setCol(12);
                } else if(setup['misc']['count'] === 2){
                    setCol(6);
                } else if(setup['misc']['count'] === 3){
                    setCol(4);
                } else if(setup['misc']['count'] === 4){
                    setCol(3);
                } else {
                    setCol(12);
                }
            }
        }
    }, [component, setup, images, isMobile]);

    return (
        <Grid container spacing={3}>
            {data.map((single, i) => (
                <Grid item key={'iaousgd' + i} xs={col} style={{textAlign: 'center', paddingBottom: isMobile ? 10 : 25}}>
                    {(typeof single['image'] !== "undefined") && (
                        <FeatureImage src={single['image']} height={single['height']} />
                    )}
                    {((typeof single['topline'] !== "undefined") && single['topline']) && (
                        <Typography variant="caption" style={getTextStyles(single['topline'].styles, {})}>
                            {t(single['topline'].value)}
                        </Typography>
                    )}
                    {((typeof single['headline'] !== "undefined") && single['headline']) && (
                        <Typography variant="h1" style={getTextStyles(single['headline'].styles, {})}>
                            {t(single['headline'].value)}
                        </Typography>
                    )}
                    {((typeof single['text'] !== "undefined") && single['text']) && (
                        <Box mt={3}>
                            <Typography variant="subtitle1" style={getTextStyles(single['text'].styles, {})}>
                                {t(single['text'].value)}
                            </Typography>
                        </Box>
                    )}
                    {((typeof single['buttonText'] !== "undefined") && single['buttonText']) && (
                        <Box mt={3}>
                            <ActionButton buttonId={i.toString()} t={t} setup={setup} component={component} sendMessage={sendMessage} />
                        </Box>
                    )}
                </Grid>
            ))}
        </Grid>
    );
}

Feature.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func,
    t: PropTypes.func,
}

export default Feature;