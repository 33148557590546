import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { ThemeProvider, useTheme } from "@mui/system";
import { createTheme } from "../omnia/theme/index";
import getThemeConfig from "../utils/get-theme";

const SimplyWarmLayout = ({siteSetup, sendMessage, children }) => {

    const theme = createTheme(getThemeConfig('dark'))

    if(!siteSetup || typeof(siteSetup) === "undefined")
        return null;

    return (
        <Fragment>
            <Header
                navbar={siteSetup.menu.design}
                theme={siteSetup.menu.theme}
                stickyNav={siteSetup.menu.stickyNavbar}
                transparent={siteSetup.menu.transparent}
                showTopbar={siteSetup.menu.showTopbar}
                sendMessage={sendMessage}
            />
            {children}
            <ThemeProvider theme={theme}>
                {/*<CssBaseline />*/}
                <Footer
                    footer={siteSetup.footer}
                    sendMessage={sendMessage}
                />
            </ThemeProvider>
        </Fragment>
    );
};

SimplyWarmLayout.propTypes = {
    children: PropTypes.any,
    siteSetup: PropTypes.object,
    headerContainerClass: PropTypes.string,
    headerPaddingClass: PropTypes.string,
};

SimplyWarmLayout.defaultProps = {
    showTopbar: true,
    menuTheme: "light",
    headerTransparent: false,
    footer: 'full',
    siteSetup: null
}

export default SimplyWarmLayout;
