import React from "react";
import useTranslator from "../../../hooks/useTranslator";

const BlogView = () => {

    const { t } = useTranslator();

    return (
        <div>Blog ist in Arbeit</div>
    )
}

export default BlogView;