import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
import useTranslator from "../../hooks/useTranslator";
import { useNotifications } from "../../omnia/hooks/use-notifications";

function NewsletterSignup({onDone}){

    const [email, setEmail] = useState(null);
    const { t } = useTranslator();
    const { notify } = useNotifications();

    const saveEmail = () => {
        if(email === null || email === ""){
            alert(t("Bitte gebe deine Email Adresse an"));
        } else {
            notify(t("Du bist angemeldet"), "success");
            onDone(email);
        }
    }

    const handleClose = () => {
        onDone();
    }

    const handleChange = (event) => {
        setEmail(event.target.value);
    }

    return (
        <>
            <Dialog
                // classes={{ paper: classes.paper }}
                onClose={handleClose}
                maxWidth="xs"
                aria-labelledby="simple-dialog-title"
                open={true}
            >
                <DialogContent sx={{pb: 0}}>
                    <Box >
                        <Typography variant="h5">
                            {t("Newsletter?!")}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body1">
                            {t("Melde dich zu unserem Newsletter an, damit du das nächste große Angebot auch nicht verpasst.")}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <TextField
                            size="medium"
                            fullWidth
                            label="Email"
                            onChange={handleChange}
                            placeholder={t("Email Adresse")}
                            value={email}

                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: 3}}>
                    <Button variant="outlined" onClick={handleClose}>
                        {t("Abbrechen")}
                    </Button>
                    <Button variant="contained" onClick={saveEmail}>
                        {t("Anmelden")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

NewsletterSignup.propTypes = {
    onDone: PropTypes.func
}

export default NewsletterSignup;