import React from "react";
import { Autocomplete, Paper, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCountry } from "../../redux/actions/shopActions";
import useTranslator from "../../hooks/useTranslator";

const CustomPaper = (props) => {
    return <Paper elevation={10} {...props} />;
};

function CountrySelector({onChange, ...rest}){

    const dispatch = useDispatch();
    const { t } = useTranslator();
    const { country, countries } = useSelector(state => state.shop);
    const handleCountryChange = (code) => {
        dispatch(setCountry(countries.find(c => c.code === code)));
    }

    return (
        <Autocomplete
            sx={{minWidth: 350}}
            fullWidth
            id="country-selector"

            onChange={(event, value) => {
                if(value){
                    handleCountryChange(value.code);
                    if(onChange)
                        onChange(value.code);
                }
            }}
            PaperComponent={CustomPaper}
            value={country ? country : null}
            options={countries}
            filterOptions={(options, value) => {
                return options.filter(o => {
                    let directMatch = o.name.toLowerCase().includes(value['inputValue'].toLowerCase());
                    let isDeutschland = "deutschland".includes(value['inputValue'].toLowerCase());

                    return directMatch || (o.name === "Germany" && isDeutschland);
                });
            }}
            getOptionLabel={(option) => {
                if(typeof option.code === "undefined" || !option)
                    return t("Bitte auswählen");
                if(countries.filter(o => o.code === option.code).length > 0)
                    return countries.find(o => o.code === option.code).name;
                return "Unbekannt";
            }}
            renderInput={(params) => <TextField {...params} label={t("Land")} />}
            {...rest}
        />
    )
}

export default CountrySelector;