import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetMissingTranslations, updateMissingTranslation } from "../redux/actions/shopActions";

const useTranslator = () => {

    const { languages, currentLanguageCode } = useSelector(state => state.multilanguage);
    const dispatch = useDispatch();
    const { missing_translations } = useSelector(state => state.shop);

    const t = (val) => {

        // Check if the given val contains data
        if((val !== null) && (typeof val === "string")){

            // Check if the desired language exists
            if(typeof languages[currentLanguageCode] !== "undefined"){

                // Check if the desired string exists
                if((typeof languages[currentLanguageCode][val] !== "undefined") && languages[currentLanguageCode][val] !== ""){

                    // Return the translated string
                    return languages[currentLanguageCode][val];

                }

            }

            // send missing translation to omnia (to be added to "texts")
            if(typeof(missing_translations) !== "undefined"){
                if(!Object.keys(missing_translations).includes(val))
                    dispatch(updateMissingTranslation(val));
            }

            // Fallback is to return the identifier that exists for now
            return val;

        } else {

            // There is no translation
            return "";

        }

    }

    const tp = (product, field) => {
        let languageId = parseInt(currentLanguageCode.split('_')[1]);
        if(typeof product.translations !== "undefined"){
            let resultSet = product.translations.filter(t => t.language === languageId);
            if(resultSet.length > 0){
                return resultSet[0][field];
            } else {
                if(product.translations.length > 0){
                    return product.translations[0][field];
                }
            }
        }
        return "";
    }

    return { t, tp };

}

export default useTranslator;