import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import useImageLoader from "../../../omnia/hooks/use-image-loader";
import useStyleBuilder from "../../../hooks/useStyleBuilder";
import ActionButton from "./Button";

function SplitImage({src}){
    // const { imageSrc } = useImageLoader(src, '/assets/images/loading_cms_dark.jpg');
    const { imageSrc } = useImageLoader(src, '/assets/images/loading_image.jpg');
    return <div style={{ height: '100%', width: '100%', backgroundPosition: 'center', backgroundSize: 'cover', backgroundImage: 'url(' + imageSrc + ')' }} />;
}

function SplitInfoScreen({component, texts, setup, images, sendMessage, t}){

    const [verticalSpacing, setVerticalSpacing] = useState(0);
    const [imageLeft, setImageLeft] = useState(true);
    const [ratio, setRatio] = useState('ratio-50-50');
    const [textAlign, setAlign] = useState('left');
    const [spacing, setSpacing] = useState(3);
    const [buttonLink, setButtonLink] = useState(null);
    const [colRight, setRight] = useState(6);
    const [colLeft, setLeft] = useState(6);
    const [imageSrc, setImageSrc] = useState(null);
    const { getTextStyles } = useStyleBuilder();

    useEffect(() => {
        if(typeof setup['misc'] !== "undefined"){
            if(typeof setup['misc']['verticalSpacing'] !== "undefined"){
                setVerticalSpacing(2 * parseInt(setup['misc']['verticalSpacing']));
            }
            if(setup['misc']['variant'] === 'image-right'){
                setImageLeft(false);
            } else {
                setImageLeft(true);
            }
            if(typeof setup['misc']['ratio'] !== "undefined")
                setRatio(setup['misc']['ratio']);
            if(typeof setup['misc']['buttonLink'] !== "undefined")
                setButtonLink(setup['misc']['buttonLink']);
            if(typeof setup['misc']['align'] !== "undefined")
                setAlign(setup['misc']['align']);
            if(typeof setup['misc']['spacing'] !== "undefined")
                setSpacing(parseInt(setup['misc']['spacing']));
        }
        if(typeof setup['images'] !== "undefined"){
            if(typeof setup['images']['info-image'] !== "undefined"){
                if(images.filter(i => i.id === setup['images']['info-image']).length > 0){
                    setImageSrc(images.find(i => i.id === setup['images']['info-image']).view);
                }
            }
        }
    }, [texts, images, setup]);

    useEffect(() => {
        setLeft(6);
        setRight(6);
        if(ratio === 'ratio-75-25'){
            setLeft(8);
            setRight(4);
        }
        if(ratio === 'ratio-25-75'){
            setLeft(4);
            setRight(8);
        }
    }, [ratio]);

    return (
        <Grid container spacing={spacing}>
            {imageLeft ? (
                <>
                    <Grid item xs={12} sm={colLeft}>
                        <SplitImage src={imageSrc} />
                    </Grid>
                    <Grid item xs={12} sm={colRight}>
                        <Box p={2} mt={3 + verticalSpacing} mb={3 + verticalSpacing} style={{textAlign: textAlign}}>
                            {texts.filter(t => t.identifier === 'title').length > 0 && (
                                <Box mb={2}>
                                    <Typography variant="h1" style={getTextStyles(texts.find(t => t.identifier === 'title').styles, {})} >
                                        {t(texts.find(t => t.identifier === 'title').value)}
                                    </Typography>
                                </Box>
                            )}
                            {texts.filter(t => t.identifier === 'title').length > 0 && (
                                <Box mb={2}>
                                    <Typography variant="subtitle1" style={getTextStyles(texts.find(t => t.identifier === 'text').styles, {})} >
                                        {t(texts.find(t => t.identifier === 'text').value)}
                                    </Typography>
                                </Box>
                            )}
                            {buttonLink && (
                                <ActionButton style={{textAlign: textAlign}} t={t} setup={setup} component={component} sendMessage={sendMessage} />
                            )}
                        </Box>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid item xs={12} sm={colLeft}>
                        <Box p={2} mt={3 + verticalSpacing} mb={3 + verticalSpacing} style={{textAlign: textAlign}}>
                            {texts.filter(t => t.identifier === 'title').length > 0 && (
                                <Box mb={2}>
                                    <Typography variant="h1" style={getTextStyles(texts.find(t => t.identifier === 'title').styles, {})} >
                                        {t(texts.find(t => t.identifier === 'title').value)}
                                    </Typography>
                                </Box>
                            )}
                            {texts.filter(t => t.identifier === 'title').length > 0 && (
                                <Box mb={2}>
                                    <Typography variant="subtitle1" style={getTextStyles(texts.find(t => t.identifier === 'text').styles, {})} >
                                        {t(texts.find(t => t.identifier === 'text').value)}
                                    </Typography>
                                </Box>
                            )}
                            {buttonLink && (
                                <ActionButton style={{textAlign: textAlign}} t={t} setup={setup} component={component} sendMessage={sendMessage} />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={colRight}>
                        <SplitImage src={imageSrc} />
                    </Grid>
                </>
            )}
        </Grid>
    );
}

SplitInfoScreen.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func
}

export default SplitInfoScreen;