import React, { useEffect } from "react";
import useImageLoader from "../../../../omnia/hooks/use-image-loader";

const ProductPicture = ({product}) => {

    // const classes = useStyles();
    const { imageSrc } = useImageLoader(product.featured ? product.featured.view : null);

    return (
        <div className={'default-img img-fluid'} style={{
            backgroundImage: `url(${imageSrc})`,
            position: 'relative',
            height: 350,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: 7,
            backgroundPosition: 'center',
        }} />
    )
}

export default ProductPicture;