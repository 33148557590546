import { loadLanguages } from "redux-multilanguage";

export const UPDATE_SETTING = '@shop/update-setting';
export const UPDATE_VISITOR = '@shop/update-visitor';
export const UPDATE_ORDER = '@shop/update-order';
export const UPDATE_SCROLL = '@shop/update-scroll';
export const UPDATE_MISSING_TRANSLATION = '@shop/update-missing-translation';
export const MARK_SYNCED_MISSING_TRANSLATION = '@shop/update-synced-translation';
export const RESET_TRANSLATION_STORE = '@shop/reset-missing-translation';
export const UPDATE_CART = '@shop/update-cart';
export const ADD_VOUCHER = '@shop/add-voucher';
export const REMOVE_VOUCHER = '@shop/remove-voucher';
export const RESET_VOUCHERS = '@shop/reset-vouchers';
export const REMOVE_FROM_CART = '@shop/remove-from-cart';
export const CLEAR_CART = '@shop/clear-cart';
export const UPDATE_WISHLIST = '@shop/update-wishlist';
export const REMOVE_FROM_WISHLIST = '@shop/remove-from-wishlist';
export const CLEAR_WISHLIST = '@shop/clear-wishlist';
export const SET_COUNTRY = '@shop/set-country';
export const RESET_COUNTRY = '@shop/reset-country';
export const SET_DELIVERABLE_PRODUCTS = '@shop/set-deliverable-products';
export const SET_COUNTRIES = '@shop/set-countries';
export const SET_PRIVACY_HANDLED = '@shop/set-privacy-handled';
export const ADD_PRIVACY_SETTING = '@shop/add-privacy-setting';
export const UPDATE_PRIVACY_SETTING = '@shop/udpate-privacy-setting';
export const REMOVE_PRIVACY_SETTING = '@shop/remove-privacy-setting';
export const UPDATE_OPEN_MOTIVATIONS = '@shop/update-open-motivations';
export const UPDATE_SHIPPING_COST = '@shop/update-shipping-cost';
export const SET_DELIVERABLE_COUNTRIES = '@shop/set-deliverable-countries';

export function updatePrivacySetting(allPrivacySettings){
    return dispatch => dispatch({
        type: UPDATE_PRIVACY_SETTING,
        payload: {
            allPrivacySettings
        }
    })
}

export function addPrivacySetting(privateToAdd){
    return dispatch => dispatch({
        type: ADD_PRIVACY_SETTING,
        payload: {
            privateToAdd
        }
    })
}

export function removePrivacySetting(privateToRemove){
    return dispatch => dispatch({
        type: REMOVE_PRIVACY_SETTING,
        payload: {
            privateToRemove
        }
    })
}

export function markPrivacyAsHandled(){
    return dispatch => dispatch({
        type: SET_PRIVACY_HANDLED,
        payload: {}
    })
}

export function updateShippingCost(shippingCost){
    return dispatch => dispatch({
        type: UPDATE_SHIPPING_COST,
        payload: {
            shippingCost
        }
    })
}

export function setAllCountries(allCountries){
    return dispatch => dispatch({
        type: SET_COUNTRIES,
        payload: {
            allCountries
        }
    })
}

export function setDeliverableCountries(deliverableCountries){
    return (dispatch) => dispatch({
        type: SET_DELIVERABLE_COUNTRIES,
        payload: {
            deliverableCountries
        }
    })
}

export function setDeliverableProducts(deliverables){
    return (dispatch) => dispatch({
        type: SET_DELIVERABLE_PRODUCTS,
        payload: {
            deliverables
        }
    })
}

export function setCountry(countryData){
    return (dispatch) => dispatch({
        type: SET_COUNTRY,
        payload: {
            countryData
        }
    })
}

export function resetCountry(){
    return (dispatch) => dispatch({
        type: RESET_COUNTRY,
        payload: {}
    })
}

export function addVoucher(voucher){
    return (dispatch) => dispatch({
        type: ADD_VOUCHER,
        payload: {
            voucher
        }
    })
}

export function removeVoucher(voucherCode){
    return (dispatch) => dispatch({
        type: REMOVE_VOUCHER,
        payload: {
            voucherCode
        }
    })
}

export function resetVouchers(){
    return (dispatch) => dispatch({
        type: RESET_VOUCHERS,
        payload: {}
    })
}

export function saveSetting(data, key) {
    return (dispatch) => dispatch({
        type: UPDATE_SETTING,
        payload: {
            data, key
        }
    })
}

export function updateVisitor(visitor){
    return (dispatch) => dispatch({
        type: UPDATE_VISITOR,
        payload: {
            visitor
        }
    })
}

export function updateCart(variantId, quantity){
    return dispatch => dispatch({
        type: UPDATE_CART,
        payload: {
            variantId, quantity
        }
    })
}

export function removeFromCart(removeCartId){
    return dispatch => dispatch({
        type: REMOVE_FROM_CART,
        payload: {
            removeCartId
        }
    })
}

export function clearCart(){
    return dispatch => dispatch({
        type: CLEAR_CART,
        payload: {}
    })
}

export function addToWishlist(productId){
    return dispatch => dispatch({
        type: UPDATE_WISHLIST,
        payload: {
            productId
        }
    })
}

export function removeFromWishlist(removeProductId){
    return dispatch => dispatch({
        type: REMOVE_FROM_WISHLIST,
        payload: {
            removeProductId
        }
    })
}

export function clearWishlist(){
    return dispatch => dispatch({
        type: CLEAR_WISHLIST,
        payload: {}
    })
}

export function updateMissingTranslation(phrase){
    return dispatch => dispatch({
        type: UPDATE_MISSING_TRANSLATION,
        payload: {
            phrase
        }
    })
}

export function markTranslationSynced(translation){
    return dispatch => dispatch({
        type: MARK_SYNCED_MISSING_TRANSLATION,
        payload: {
            translation
        }
    })
}

export function resetMissingTranslations(){
    return dispatch => dispatch({
        type: RESET_TRANSLATION_STORE,
        payload: {}
    })
}

export function updateScrollPosition(scroll) {
    return (dispatch) => dispatch({
        type: UPDATE_SCROLL,
        payload: {
            scroll
        }
    })
}

export function saveLanguages(translations) {
    let data = {
        languages: translations
    };
    return (dispatch) => dispatch(loadLanguages(data));
}

export function updateOpenMotivations(motivations){
    return dispatch => dispatch({
        type: UPDATE_OPEN_MOTIVATIONS,
        payload: {
            motivations
        }
    })
}
