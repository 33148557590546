import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hero from "./elements/Hero";
import Blog from "./elements/Blog";
import Button from "./elements/Button";
import Feature from "./elements/Feature";
import Picture from "./elements/Picture";
import SplitInfoScreen from "./elements/SplitInfoScreen";
import Team from "./elements/Team";
import Text from "./elements/Text";
import useTranslator from "../../hooks/useTranslator";
import Slider from "./elements/Slider";
import { useIsMobileVersion } from "../../hooks/useIsMobileVersion";
import Gallery from "./elements/Gallery";
import CustomerVoices from "./elements/CustomerVoices";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";


function Component({component, sendMessage}){

    const [componentStyles, setComponentStyles] = useState({});
    const [hideMobile, setHideMobile] = useState(false);
    const [hideDesktop, setHideDesktop] = useState(false);
    const [styles, setStyles] = useState({});
    const [setup, setSetup] = useState({});
    const [texts, setTexts] = useState([]);
    const [images, setImages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const allowPrivacy = useSelector(state => state.shop.allowPrivacy);
    const { isMobile } = useIsMobileVersion();
    const { t } = useTranslator();

    const handleClick = () => {
        if(allowPrivacy.includes('usage')) {
            sendMessage({
                'type': 'activityUpdate',
                'method': 'click',
                'content': component.id,
                'site_id': component.site,
            });
        }
    }

    useEffect(() => {

        let stylesObject = {};

        // Handle the component background
        if(typeof styles['background'] !== "undefined"){

            if(typeof styles['background']['type'] !== "undefined"){

                // Background color desired
                if(styles['background']['type'] === "color"){
                    let r = styles['background']['backgroundColor']['r']
                    let g = styles['background']['backgroundColor']['g']
                    let b = styles['background']['backgroundColor']['b']
                    let o = parseFloat(styles['background']['opacity']) / 100
                    stylesObject['backgroundColor'] = 'rgba(' + r + ',' + g + ',' + b + ',' + o + ')'
                }

                // Background image desired
                if(styles['background']['type'] === "image"){
                    if(images.filter(i => i.id === setup['images']['background']).length === 1){
                        let image = images.find(i => i.id === setup['images']['background']);
                        stylesObject['background'] = 'url(' + image.view + ')';
                        stylesObject['backgroundRepeat'] = 'no-repeat';
                        stylesObject['backgroundSize'] = 'cover';
                    }
                }

                // TODO: more could come

            }
        }

        // Handle the component margin and padding
        if(typeof styles['margin'] !== "undefined"){
            stylesObject['marginLeft'] = styles['margin']['left'] ? parseInt(styles['margin']['left']) : 0
            stylesObject['marginRight'] = styles['margin']['right'] ? parseInt(styles['margin']['right']) : 0
            stylesObject['marginTop'] = styles['margin']['top'] ? parseInt(styles['margin']['top']) : 0
            stylesObject['marginBottom'] = styles['margin']['bottom'] ? parseInt(styles['margin']['bottom']) : 0
        }
        if(typeof styles['padding'] !== "undefined"){
            stylesObject['paddingLeft'] = styles['padding']['left'] ? parseInt(styles['padding']['left']) : 0
            stylesObject['paddingRight'] = styles['padding']['right'] ? parseInt(styles['padding']['right']) : 0
            stylesObject['paddingTop'] = styles['padding']['top'] ? parseInt(styles['padding']['top']) : 0
            stylesObject['paddingBottom'] = styles['padding']['bottom'] ? parseInt(styles['padding']['bottom']) : 0
        }

        // Check for hide mobile
        if(typeof styles['hideMobile'] !== "undefined"){
            setHideMobile(styles['hideMobile']);
        } else {
            setHideMobile(false);
        }

        // Check for hide desktop
        if(typeof styles['hideDesktop'] !== "undefined"){
            setHideDesktop(styles['hideDesktop']);
        } else {
            setHideDesktop(false);
        }

        setComponentStyles(stylesObject);

    }, [styles, images, setup]);

    useEffect(() => {
        try {
            // Read the component's setup and styles
            setSetup(JSON.parse(component['setup']));
            setStyles(JSON.parse(component['styles']));
            setImages(component['images']);
            setTexts(component['texts']);
            setLoaded(true);
        } catch (e) {
            console.log('Could not read element ' + component.id + ': ' + e);
        }
    }, [component]);

    if(!loaded)
        return null;

    if((isMobile && hideMobile) || (!isMobile && hideDesktop))
        return null;

    const content = (
        <>
            {component.component === 'blog' && (
                <Blog component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'button' && (
                <Button component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'feature' && (
                <Feature component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'gallery' && (
                <Gallery component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'customer_voices' && (
                <CustomerVoices component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'hero' && (
                <Hero component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'picture' && (
                <Picture component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'split_screen_info' && (
                <SplitInfoScreen component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'team' && (
                <Team component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'text' && (
                <Text component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
            {component.component === 'slider' && (
                <Slider component={component} t={t} texts={texts} setup={setup} images={images} sendMessage={sendMessage} />
            )}
        </>
    )

    return (
        <div style={componentStyles} onClick={handleClick}>
            {styles['fullwidth'] ? (
                <>
                    {content}
                </>
            ) : (
                <Container maxWidth={styles['container'] ? styles['container'] : 'lg'}>
                    {content}
                </Container>
            )}
        </div>
    )
}

Component.propTypes = {
    component: PropTypes.object,
    sendMessage: PropTypes.func
}

export default Component;