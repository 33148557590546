import React  from "react";
import { useIsMobile } from "../omnia/hooks/use-is-mobile";

const useStyleBuilder = () => {

    const getTextStyles = (val, custom={}) => {

        let styleObject = custom;

        if((typeof val !== "undefined") && val){
            if(val['fontColor'] !== "#65748B"){
                styleObject['color'] = val['fontColor'];
            }
            if(val['fontSize'] !== null){
                styleObject['fontSize'] = val['fontSize'];
            }
            if(val['bold']){
                styleObject['fontWeight'] = 700;
            }
        }

        return styleObject;
    }

    return { getTextStyles };

}

export default useStyleBuilder;