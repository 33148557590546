import React from "react";
import { Box, Typography } from "@mui/material";
import { Heart } from "react-feather";
import { useTheme } from "@mui/system";
import useTranslator from "../../../hooks/useTranslator";

function ThankYou(){

    const theme = useTheme();
    const { t } = useTranslator();

    return (
        <>
            <div style={{height: 150, marginTop: 100, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <Box>
                    <Heart size={150} color={theme.palette.primary.main} />
                </Box>
            </div>
            <div style={{height: 200, marginBottom: 150, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <Box>
                    <Typography variant="h2" textAlign="center" style={{marginBottom: 15}}>
                        {t("Vielen Dank!")}
                    </Typography>
                    <Typography variant="body1" style={{fontSize: 20}} textAlign="center">
                        {t("Wir haben Deine Bestellung erhalten und melden uns gleich per Mail")}
                    </Typography>
                </Box>
            </div>
        </>
    );
}

export default ThankYou;