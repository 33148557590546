import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import useTranslator from "../../hooks/useTranslator";
import { DataGrid } from "@mui/x-data-grid";

function Payments({sendMessage}){

    const { t } = useTranslator();

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: "#F3F3F3",
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100vh - 350px)",
                paddingTop: 4,
                paddingBottom: 4
            }}
        >
            <Container maxWidth="md">
                <Grid container sx={{pt: 10}} spacing={3}>

                    <Grid item xs={12}>
                        <Typography color="textPrimary" variant="h2">
                            {t("Zahlungsweisen")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 8}}>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("In unserem Online-Shop stehen euch folgende Zahlungsarten zur Verfügung: Vorkasse, SOFORT Überweisung, Apple Pay, Kreditkarte, Klarna und über Paypal. Suche dir einfach die für dich optimale Zahlungsart aus.")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Die in den jeweiligen Angeboten angeführten Preise stellen Gesamtpreise dar. Sie beinhalten alle Preisbestandteile einschließlich der gesetzlichen Umsatzsteuer. Nur bei grenzüberschreitender Lieferung können im Einzelfall weitere Steuern (z.B. im Falle eines innergemeinschaftlichen Erwerbs) und/oder Abgaben (z.B. Zölle) von dir zu zahlen sein, jedoch nicht an den Verkäufer, sondern an die dort zuständigen Zoll- bzw. Steuerbehörden.")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Die anfallenden Liefer- und Versandkosten sind nicht im Kaufpreis enthalten, sie sind über die Seite „Lieferung & Versand“ aufrufbar, werden im Laufe des Bestellvorganges gesondert ausgewiesen und sind von dir zusätzlich zu tragen. Die Möglichkeit zum Skontoabzug besteht nicht.")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("PayPal")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Noch schneller und sicherer bezahlen mit deinem PayPal-Konto. Du wirst während des Bestellprozesses auf die Webseite des Online-Anbieters PayPal weitergeleitet  https://www.paypal.com/de/home/. Hier kannst du deine Zahlungsdaten angeben und die Zahlungsanweisung an PayPal bestätigen. Wir fordern PayPal nach Abgabe der Bestellung im Shop zur Einleitung der Zahlungstransaktion auf und nehmen dadurch dein Angebot an. Du kannst auch ohne Registrierung für ein Kundenkonto unter “PayPal-Gastzahlung“ bzw. “Ohne PayPal-Konto bezahlen“ die Zahlungsanweisung per Lastschrift oder Kreditkarte an uns bestätigen.")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Apple Pay ")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Wenn Apple Pay auf deinem Gerät (z.B. MacBook, iPad oder iPhone mit Touch/Face ID) aktiviert ist und Safari als Browser verwendet wird, erkennt unser System das und ermöglicht dir den Abschluss deiner Bestellung damit.")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("SOFORT Überweisung")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Zunächst wählst du dein Land und die Bank aus, bei der du Kunde bist. Danach logst du dich auf der Anmeldeseite des Zahlungsformulars mit deinen Online-Banking-Daten ein. Du bestätigst die Übertragung mit einem PIN-Code/TAN oder einem Kartenlesegerät. Daraufhin erhältst du eine Bestätigung der Übertragung und/oder Bestätigung der Bestellung durch unseren Online-Shop.")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Klarna")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Schnell und einfach online bezahlen per online Banking.")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Bei Auswahl einer über den Zahlungsdienst “Klarna” angebotenen Zahlungsart erfolgt die Zahlungsabwicklung über die Klarna Bank AB (publ), Sveavägen 46, 111 34 Stockholm, Schweden (nachfolgend „Klarna“). Wenn du die Zahlung per Rechnung wählst, bezahlst du mit einem Fälligkeitsdatum 14 Tage ab Versand der Waren. Wir treten die Forderung an Klarna ab. Klarna schickt dir eine Zahlungsanweisung, und du bezahlst deine Bestellung direkt an Klarna. Kontaktdaten und weitere Informationen über Klarna findest du hier. Die vollständigen Rechnungsbedingungen für die Länder in denen diese Zahlart verfügbar ist findest du hier: Deutschland, Österreich")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Kreditkarte")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Wenn du dich für die Zahlung mit deiner Kreditkarte entscheidest, dann breche bitte den Zahlungsvorgang nicht frühzeitig ab. Nach deiner zahlungspflichtigen Bestellung im Online-Shop wirst du an deine Bank/App weitergeleitet, um die Kreditkartenzahlung abschließend zu bestätigen.")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Vorkasse")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Zahle den Kaufpreis unverzüglich nach Vertragsschluss. Wenn deine Zahlung trotz Fälligkeit auch nach erneuter Aufforderung nicht bis zu einem Zeitpunkt von 14 Kalendertagen nach Absendung der Bestellbestätigung bei uns eingegangen ist, treten wir vom Vertrag zurück mit der Folge, dass die Bestellung hinfällig ist und uns keine Lieferpflicht trifft. Die Bestellung ist dann für dich als Käufer und uns als Verkäufer ohne weitere Folgen erledigt. Eine Reservierung des Artikels bei Vorkassezahlungen erfolgt daher längstens für 14 Kalendertage. Bitte überweise deshalb den Gesamtbetrag deiner Bestellung auf unser Konto:")}
                        </Typography>
                        <Typography color="testPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Grenke Bank,  BICGREBDEH1,  IBAN DE94 2013 0400 0060 2888 26")}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

export default Payments;