import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Typography } from "@mui/material";
import { useIsMobile } from "../../../omnia/hooks/use-is-mobile";
import useStyleBuilder from "../../../hooks/useStyleBuilder";

function CustomerVoices({ component, texts, setup, images, sendMessage, t }) {

    const [headline, setHeadline] = useState(null);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(1);
    const [col, setCol] = useState(12);
    const { getTextStyles } = useStyleBuilder();
    const { isMobile } = useIsMobile();

    useEffect(() => {

        if (typeof setup["misc"] !== "undefined") {
            setCount(setup["misc"]["voiceCount"]);

            if(component.texts.filter(t => t.identifier === "headline").length === 1){
                setHeadline(component.texts.find(t => t.identifier === "headline"));
            } else {
                setHeadline(null);
            }

            let data = [];
            let voice, headline, name, role = "";
            for(let i = 0; i < setup["misc"]["voiceCount"]; i++){

                voice = component.texts.filter(t => t.identifier === "customer-voice-" + i).length === 1 ? component.texts.find(t => t.identifier === "customer-voice-" + i) : null;
                headline = component.texts.filter(t => t.identifier === "customer-headline-" + i).length === 1 ? component.texts.find(t => t.identifier === "customer-headline-" + i) : null;
                name = component.texts.filter(t => t.identifier === "customer-name-" + i).length === 1 ? component.texts.find(t => t.identifier === "customer-name-" + i) : null;
                role = component.texts.filter(t => t.identifier === "customer-role-" + i).length === 1 ? component.texts.find(t => t.identifier === "customer-role-" + i) : null;

                data = data.concat([{
                    'voice': voice,
                    'headline': headline,
                    'name': name,
                    'role': role,
                }]);
            }

            setData(data);

        }

    }, [setup]);

    useEffect(() => {
        if(isMobile){
            setCol(12);
        } else {
            if (count === 1) {
                setCol(12);
            } else if (count === 2) {
                setCol(6);
            } else if (count === 3) {
                setCol(4);
            } else {
                setCol(3);
            }
        }
    }, [count]);

    return (
        <Grid container spacing={3}>
            {headline && (
                <Grid item xs={12}>
                    <Box mb={3}>
                        <Typography variant="h1" style={getTextStyles(headline.styles, {textAlign: 'center'})}>
                            {t(headline.value)}
                        </Typography>
                    </Box>
                </Grid>
            )}
            {data.map((single, index) => (
                <Grid item xs={12} px={2} sm={col} key={'in' + index}>
                    <Box mt={3}>
                        <Typography variant="caption">
                            {t("Kundenstimmen")}
                        </Typography>
                    </Box>
                    {((typeof single['headline'] !== "undefined") && single['headline']) && (
                        <Box mt={1}>
                            <Typography variant="h4" style={getTextStyles(single['headline'].styles, {})}>
                                {t(single['headline'].value)}
                            </Typography>
                        </Box>
                    )}
                    {((typeof single['voice'] !== "undefined") && single['voice']) && (
                        <Box mt={3}>
                            <Typography variant="body1" style={getTextStyles(single['voice'].styles, {color: '#898989', lineHeight: 1.6})}>
                                {t(single['voice'].value)}
                            </Typography>
                        </Box>
                    )}
                    {((typeof single['name'] !== "undefined") && single['name']) && (
                        <Box mt={3}>
                            <Typography variant="h6" style={getTextStyles(single['name'].styles, {})}>
                                {t(single['name'].value)}
                            </Typography>
                        </Box>
                    )}
                    {((typeof single['role'] !== "undefined") && single['role']) && (
                        <Box mt={0}>
                            <Typography variant="caption" style={getTextStyles(single['role'].styles, {color: '#898989'})}>
                                {t(single['role'].value)}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            ))}
        </Grid>
    );
}

CustomerVoices.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func
};

export default CustomerVoices;