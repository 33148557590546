import axios from 'src/omnia/utils/axios';
import {APP_SETTING} from "../../setup";

class AuthService {

    setAxiosInterceptors(onLogout){
        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response?.data?.detail === "Invalid token.") {
                    this.setSession(null);
                    onLogout();
                }
                return Promise.reject(error);
            }
        );
    }

    logout = () => {
        this.setSession(null);
        axios.post(APP_SETTING.protocol + '://' + APP_SETTING.domain + '/api/me/logout/');
    };

    setAccessToken = (token) => {
        this.setSession(token);
    };

    setSession = (accessToken) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
        } else {
            localStorage.removeItem('accessToken');
        }
    };

    getAccessToken = () => localStorage.getItem('accessToken');

}

const authService = new AuthService();

export default authService;
