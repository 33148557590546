import { alpha } from "@mui/material/styles";
import chroma from "chroma-js";
import { error, getDynamicColor, info, neutral, success, warning } from "../colors";
import adjustLuminanceToTarget from "../../utils/adjust-color-luminence";

export const createPalette = (config) => {
    const { primaryColor, secondaryColor, accentColor, contrast } = config;

    return {
        action: {
            active: neutral[500],
            disabled: alpha(neutral[100], 0.38),
            disabledBackground: alpha(neutral[100], 0.12),
            focus: alpha(neutral[100], 0.16),
            hover: alpha(neutral[100], 0.04),
            selected: alpha(neutral[100], 0.12)
        },
        background: {
            default: contrast === "high" ? adjustLuminanceToTarget(primaryColor, 0.005) : adjustLuminanceToTarget(primaryColor, 0.0075),
            paper: adjustLuminanceToTarget(primaryColor, 0.01),
            discrete: adjustLuminanceToTarget(primaryColor, 0.01),
            evident: adjustLuminanceToTarget(primaryColor, 0.03),
        },
        divider: adjustLuminanceToTarget(primaryColor, 0.04),
        error,
        info,
        mode: "dark",
        neutral,
        primary: getDynamicColor(secondaryColor),
        secondary: getDynamicColor(primaryColor),
        accent: getDynamicColor(accentColor),
        success,
        text: {
            primary: "#EDF2F7",
            secondary: "#A0AEC0",
            disabled: "rgba(255, 255, 255, 0.48)"
        },
        warning
    };
};
