import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import useTranslator from "../../../../hooks/useTranslator";
import { useTheme } from "@mui/system";

function Price({product, variant}){

    const { t } = useTranslator();
    const theme = useTheme();
    const [discount, setDiscount] = useState(false);
    const [price, setPrice] = useState(null);
    const [starting, setStarting] = useState(false);
    const [oldPrice, setOldPrice] = useState(null);

    useEffect(() => {
        setStarting(false);
        if(variant){
            setDiscount(false);
            setOldPrice(null);
            setPrice(parseFloat(variant.price));
            // setOldPrice('-');
            // setPrice(parseFloat(variant.price).toFixed(2) + ' €');
        } else {
            if(product.has_price_range){
                setDiscount(false);
                setOldPrice(null);
                // setOldPrice('-');
                setStarting(true);
                // setPrice('Ab ' + parseFloat(product.cheapest_price_euro).toFixed(2) + ' €');
                setPrice(parseFloat(product.cheapest_price_euro));
            } else {
                if(product.offer_price_euro){
                    setDiscount(true);
                    setPrice(parseFloat(product.offer_price_euro));
                    setOldPrice(parseFloat(product.price_euro));
                    // setPrice(parseFloat(product.offer_price_euro).toFixed(2) + ' €');
                    // setOldPrice(parseFloat(product.price_euro).toFixed(2) + ' €');
                } else {
                    setDiscount(false);
                    setPrice(parseFloat(product.price_euro));
                    setOldPrice(null);
                    // setPrice(parseFloat(product.price_euro).toFixed(2) + ' €');
                    // setOldPrice('-');
                }
            }
        }
    }, [product, variant]);

    return (
        <>
            {discount ? (
                <Fragment>
                    <span>
                        <NumberFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={parseFloat(price)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={' €'}
                        />
                    </span>
                    <span style={{marginLeft: '10px', fontWeight: 400, color: theme.palette.text.secondary, textDecoration: 'line-through'}}>
                        <NumberFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={parseFloat(oldPrice)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={' €'}
                        />
                    </span>
                </Fragment>
            ) : (
                <span>
                    <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale
                        value={price}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        prefix={starting ? (t('Ab') + ' ') : ''}
                        suffix={' €'}
                    />
                </span>
            )}
        </>
    )
}

Price.propTypes = {
    product: PropTypes.object,
    variant: PropTypes.object
}

Price.defaultProps = {
    variant: null
}

export default Price;