import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    Typography,
    DialogActions,
    Button,
    Link
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    addPrivacySetting,
    markPrivacyAsHandled,
    removePrivacySetting,
    updatePrivacySetting
} from "../redux/actions/shopActions";
import useTranslator from "../hooks/useTranslator";
import PrivacyDialogOptions from "./PrivacyDialogOptions";

function PrivacyDialog(){

    const { t } = useTranslator();
    const allSettings = ['fb', 'ga', 'usage'];
    const [settingsOpen, setSettingsOpen] = useState(false);
    const { allowPrivacy } = useSelector(state => state.shop);
    const dispatch = useDispatch();

    const handleAcceptAll = () => {
        dispatch(updatePrivacySetting(allSettings));
        dispatch(markPrivacyAsHandled());
    }

    const handleChange = (event) => {
        if(!allowPrivacy.includes(event.target.name)){
            dispatch(addPrivacySetting(event.target.name));
        } else {
            dispatch(removePrivacySetting(event.target.name));
        }
    }

    const handleDeclineAll = () => {
        dispatch(updatePrivacySetting([]));
        dispatch(markPrivacyAsHandled());
    }

    const handleAbortSettings = () => {
        setSettingsOpen(false);
    }

    const openSettings = () => {
        setSettingsOpen(true);
    }

    const markAsHandled = () => {
        dispatch(markPrivacyAsHandled());
    }

    const handleSaveSettings = () => {
        dispatch(markPrivacyAsHandled());
    }

    useEffect(() => {
        if(typeof allowPrivacy === "undefined")
            dispatch(updatePrivacySetting(allSettings));
    }, [allowPrivacy]);

    return (
        <Dialog maxWidth="sm" fullWidth open={true} onClose={markAsHandled}>
            {/* position: "absolute",
        left: 0,
        bottom: 0 */}
            <DialogTitle sx={{p: 2}}>
                <Typography variant="h4">
                    {t("Datenschutz")}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{p: 2}}>
                <Grid container spacing={3}>
                    {settingsOpen ? (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    {t("Wir verwenden keine Cookies, möchten aber Daten von unseren Besucher:innen speichern, um die Leistungsfähigkeit und Qualität unserer Seite zu verbessern. " +
                                        "Du kannst entscheiden, welche Art von Daten wir speichern dürfen. Alle von uns aufgenommen Daten werden nicht weitergegebn. " +
                                        "Wir verwenden Facebook Marketing für Anzeigen in Sozialen Medien (sofern du zustimmst). ")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{mt: 2}}>
                                <PrivacyDialogOptions
                                    sx={{ml: 2}}
                                    allowPrivacy={allowPrivacy}
                                    handleChange={handleChange}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {t("Wir verwenden so wenig Drittanbieter-Tools wie möglich und " +
                                    "gehen verantwortungs-bewusst mit deinen Daten um. Hier kannst du Einstellen, welche " +
                                    "Daten wir speichern dürfen, um unsere Seite zu verbessern.")}
                                {" "}
                                <Link onClick={openSettings}>
                                    Einstellungen
                                </Link>
                                {". "}
                                <Link onClick={openSettings}>
                                    Mehr Details
                                </Link>
                                {". "}
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions sx={{p: 2}}>
                <Grid container spacing={3}>
                    {settingsOpen ? (
                        <>
                            <Grid item xs={6}>
                                <Button fullWidth onClick={handleAbortSettings}>
                                    Abbrechen
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant="contained" onClick={handleSaveSettings}>
                                    Speichern
                                </Button>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Button fullWidth onClick={handleDeclineAll}>
                                    Ablehnen
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button fullWidth variant="contained" onClick={handleAcceptAll}>
                                    Akzeptieren
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default PrivacyDialog;