export const SET_TOP = '@client/set-top';
export const SET_BOTTOM = '@client/set-bottom';
export const SET_MIDDLE = '@client/set-middle';
export const LOG_ERROR = '@client/log-error';
export const SET_ERROR_SYNCED = '@client/set-error-synced';
export const SET_BANNER_SEEN = '@client/set-banner-seen';

export function setBannerSeen(){
    return dispatch => dispatch({
        type: SET_BANNER_SEEN,
        payload: {}
    })
}

export function logRenderingError(error, info, type){
    return (dispatch) => dispatch({
        type: LOG_ERROR,
        payload: {
            error, info, type
        }
    })
}

export function setErrorSynced(id){
    return (dispatch) => dispatch({
        type: SET_ERROR_SYNCED,
        payload: {
            id
        }
    })
}

export function setScrollPositionTop() {
    return (dispatch) => dispatch({
        type: SET_TOP
    })
}
