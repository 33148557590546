import React, { useEffect, useRef, useState } from "react";
import OpenPayment from "./OpenPayment";
import { useDispatch, useSelector } from "react-redux";
import GoToCheckout from "./GoToCheckout";
import { useLocation, useParams } from "react-router-dom";
import PrivacyDialog from "../PrivacyDialog";
import SelectCountry from "./SelectCountry";
import GiveEmailWishlist from "./GiveEmailWishlist";
import { useNotifications } from "../../omnia/hooks/use-notifications";
import NewsletterSignup from "./NewsletterSignup";
import { useCartManager } from "../../hooks/useCartManager";
import { updateOpenMotivations } from "../../redux/actions/shopActions";
import produce from "immer";

function MotivationCoordinator({countryOpen, onEmailUpdate, onCloseCountry, onSetCountry}){

    // Value Range: privacy, country, checkoutButton, wishlist, newsletter
    const [currentOpenMotivations, setOpenMotivations] = useState([]);
    const { openMotivations } = useSelector(state => state.shop);
    const [handledNewsletter, setHandledNewsletter] = useState(false);
    const [handledWishlist, setHandledWishlist] = useState(false);
    const [motivationsCache, setMotivationsCache] = useState([]);
    const [noNewsletter, setNoNewsletter] = useState(false);
    const { url } = useParams();
    const { screenIsVisible } = useNotifications();
    const { productsTotal } = useCartManager();
    const { handled, visitor, cart, wishlist, open_order } = useSelector(state => state.shop);
    const location = useLocation();
    const dispatch = useDispatch();
    const urlRef = useRef(null);
    const visitorRef = useRef(null);

    urlRef.current = url;
    visitorRef.current = visitor;

    const handleEmailWishlistDone = (email) => {
        if(typeof email !== "undefined" && email !== ""){
            onEmailUpdate(email);
        } else {
            setNoNewsletter(true);
        }
    }

    const handleNewsletterComplete = (email) => {
        handleEmailWishlistDone(email);
        setHandledNewsletter(true);
    }

    const handleWishlistComplete = (email) => {
        handleEmailWishlistDone(email);
        setHandledWishlist(true);
    }

    useEffect(() => {
        // let open = false;
        // if(open){
        //     setOpenMotivations(prev => (prev.includes('payment') ? prev : prev.concat(['payment'])));
        // } else {
        //     setOpenMotivations(prev => prev.filter(m => m !== 'payment'));
        // }
    }, [open_order]);

    useEffect(() => {
        if(!handled){
            setOpenMotivations(prev => (prev.includes('privacy') ? prev : prev.concat(['privacy'])));
        } else {
            setOpenMotivations(prev => prev.filter(m => m !== 'privacy'));
        }
        if(countryOpen){
            setOpenMotivations(prev => (prev.includes('country') ? prev : prev.concat(['country'])));
        } else {
            setOpenMotivations(prev => prev.filter(m => m !== 'country'));
        }
    }, [handled, countryOpen]);

    useEffect(() => {
        if((productsTotal > 0) && (!['/checkout', '/cart'].includes(location.pathname))){
            setOpenMotivations(prev => (prev.includes('checkoutButton') ? prev : prev.concat(['checkoutButton'])));
        } else {
            setOpenMotivations(prev => prev.filter(m => m !== 'checkoutButton'));
        }
    }, [productsTotal, location]);

    useEffect(() => {
        if(wishlist.length > 0 && (url !== "wishlist") && (url !== "checkout") && !noNewsletter && !handledWishlist){
            if(typeof visitor['allows_contacting'] === "undefined"){
                setTimeout(() => {
                    if(typeof visitorRef.current['allows_contacting'] === "undefined")
                        setOpenMotivations(prev => (prev.includes('wishlist') ? prev : prev.concat(['wishlist'])));
                }, 120000);
            } else {
                setOpenMotivations(prev => prev.filter(m => m !== 'wishlist'));
            }
        } else {
            setOpenMotivations(prev => prev.filter(m => m !== 'wishlist'));
        }
    }, [wishlist, url, visitor, noNewsletter, handledWishlist]);

    useEffect(() => {
        if((url !== "checkout") && !noNewsletter && !handledNewsletter){
            if(typeof visitor['allows_contacting'] === "undefined"){
                setTimeout(() => {
                    if(urlRef.current !== "checkout")
                        setOpenMotivations(prev => (prev.includes('newsletter') ? prev : prev.concat(['newsletter'])));
                }, 100000);
            } else {
                setOpenMotivations(prev => prev.filter(m => m !== 'newsletter'));
            }
        } else {
            setOpenMotivations(prev => prev.filter(m => m !== 'newsletter'));
        }
    }, [url, visitor, noNewsletter, handledNewsletter]);

    useEffect(() => {
        if(screenIsVisible){
            if(motivationsCache.length > 0){
                setTimeout(() => {
                    setOpenMotivations([...new Set([...openMotivations, ...motivationsCache])]);
                    setMotivationsCache([]);
                }, 5000);
            }
        } else {
            if(openMotivations.length > 0){
                setOpenMotivations([]);
                setMotivationsCache([...new Set([...openMotivations, ...motivationsCache])]);
            }
        }
    }, [screenIsVisible, openMotivations, motivationsCache]);

    useEffect(() => {
        let display = [];
        if(currentOpenMotivations.includes('privacy')){
            display = ['privacy'];
        } else if (currentOpenMotivations.includes('payment')){
            display = ['payment'];
        } else if(currentOpenMotivations.includes('wishlist')){
            display = ['wishlist'];
        } else if(currentOpenMotivations.includes('newsletter')){
            display = ['newsletter'];
        } else if(currentOpenMotivations.includes('country')){
            display = ['country'];
        } else if(currentOpenMotivations.includes('checkoutButton'))
            display = ['checkoutButton'];
        dispatch(updateOpenMotivations(display));
    }, [currentOpenMotivations]);

    return (
        <>

            {openMotivations.includes('privacy') && (
                <PrivacyDialog />
            )}
            {openMotivations.includes('wishlist') && (
                <GiveEmailWishlist onDone={handleWishlistComplete} />
            )}
            {openMotivations.includes('newsletter') && (
                <NewsletterSignup onDone={handleNewsletterComplete} />
            )}
            {openMotivations.includes('country') && (
                <SelectCountry open={true} onClose={onCloseCountry} onSetCountry={onSetCountry} />
            )}
            {openMotivations.includes('checkoutButton') && (
                <GoToCheckout />
            )}

            {/* TODO: open payment must be checked and implemented on it's own */}
            {/*<OpenPayment onOpenChange={handlePaymentChange} />*/}
        </>
    )
}

export default MotivationCoordinator;