import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { multilanguage } from "redux-multilanguage";
import { useSelector } from "react-redux";
import useTranslator from "../../../hooks/useTranslator";
import { Box, Button } from "@mui/material";

const MobileNavMenu = ({ strings }) => {

    const { t } = useTranslator();
    const { sites } = useSelector(state => state.shop);
    const navigate = useNavigate();

    const handleCartClick = () => {
        navigate('/cart');
        closeMobileMenu();
    }

    const handleCheckoutClick = () => {
        navigate('/checkout');
        closeMobileMenu();
    }

    const closeMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.remove("active");
    };

    return (
        <>
            <nav className="offcanvas-navigation" id="offcanvas-navigation">
                <ul>
                    {sites.filter(s => !s.is_landing).map((s, i) => (
                        <li key={'site-' + i}>
                            <Link to={"/" + s['url']} style={{textTransform: 'uppercase', fontSize: 13, marginRight: 5, letterSpacing: 1.5}}>
                                {t(s['title'])}
                            </Link>
                        </li>
                    ))}
                </ul>
                <Box mt={2}>
                    <Button fullWidth onClick={handleCartClick}  color="primary" sx={{color: 'black', fontWeight: 400, borderColor: 'black'}}>
                        {t("Warenkorb")}
                    </Button>
                    <Button fullWidth onClick={handleCheckoutClick} variant="contained" sx={{marginTop: 1, fontWeight: 400}}>
                        {t("Checkout")}
                    </Button>
                </Box>
            </nav>
        </>
    );
};

MobileNavMenu.propTypes = {
    strings: PropTypes.object
};

export default multilanguage(MobileNavMenu);
