import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import ProductPicture from "./ProductPicture";
import { useNotifications } from "../../../../omnia/hooks/use-notifications";
import useTranslator from "../../../../hooks/useTranslator";
import { Maximize2 as OpenIcon, Heart } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import Price from "./Price";
import { removeFromWishlist, addToWishlist } from "../../../../redux/actions/shopActions";
import { useCartManager } from "../../../../hooks/useCartManager";
import { Tooltip } from "@mui/material";
import NumberFormat from "react-number-format";

const ProductPreview = ({ product, sendMessage }) => {

    const [modalShow, setModalShow] = useState(false);
    const [onWishlist, setOnWishlist] = useState(false);
    const [inCart, setInCart] = useState(false);
    const { notify } = useNotifications();
    const dispatch = useDispatch();
    const { handleAddToCart, productIsDeliverable } = useCartManager();
    const { t, tp } = useTranslator();
    const navigate = useNavigate();
    const { wishlist, cart } = useSelector(state => state.shop);

    const handleOpenProduct = () => {
        navigate('/product/' + product.id);
    }

    const handleAddToWishlist = () => {
        dispatch(addToWishlist(product.id));
        notify(t("Zu Wunschliste hinzugefügt"), "success");
    }

    const handleRemoveFromWishlist = () => {
        dispatch(removeFromWishlist(product.id));
        notify(t("Von Wunschliste entfernt"), "success");
    }

    const handleOpenModal = () => {
        alert("Produkt Modal in Arbeit");
        // setModalShow(true);
    }

    const handleCloseModal = () => {
        setModalShow(false);
    }

    useEffect(() => {
        setOnWishlist(wishlist.includes(product.id));
        setInCart(cart.filter(ci => product.variants.map(v => v.id).includes(ci.id)).length > 0);
    }, [product, wishlist, cart]);

    return (
        <Fragment>
            <div className='col-xs-12'>
                <div className='product-wrap'>
                    <div className="product-img" style={{borderRadius: 7}}>
                        <Link to={'/product/' + product.id}>
                            <ProductPicture product={product} />
                        </Link>
                        {((product.offer_price_euro !== null) || product.is_new) && (
                            <div className="product-img-badges">
                                {!product.has_price_range && product.offer_price_euro && (
                                    <span className="purple" style={{height: 25}}>
                                        <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale
                                            value={parseFloat(parseFloat(product.price_euro) - parseFloat(product.offer_price_euro))}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            prefix={'-'}
                                            suffix={' €'}
                                        />
                                        {/*-{"" + (parseFloat(product.price_euro) - parseFloat(product.offer_price_euro)).toFixed(2)}€*/}
                                    </span>
                                )}
                                {product.is_new && (
                                    <span className="purple" style={{height: 25, paddingTop: 6}}>
                                        {t('Neu')}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className="product-action">
                            <div className="pro-same-action pro-wishlist">
                                {onWishlist ? (
                                    <Tooltip title={t("Von Wunschliste entfernen")}>
                                        <button
                                            className="active"
                                            title={t("Entfernen")}
                                            onClick={handleRemoveFromWishlist}
                                        >
                                            <Heart />
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={t("Zu Wunschliste hinzufügen")}>
                                        <button
                                            title={t("Hinzufügen")}
                                            onClick={handleAddToWishlist}
                                        >
                                            <Heart />
                                        </button>
                                    </Tooltip>
                                )}
                            </div>
                            <div className="pro-same-action pro-cart">
                                {productIsDeliverable(product) ? (
                                    <>
                                        {(product.variants && product.variants.length > 1) ? (
                                            <>
                                                {product.variants.filter(pv => cart.map(c => c.id).includes(pv.id)).length > 0 ? (
                                                    <button
                                                        style={{textTransform: 'none'}}
                                                        className={"active"}
                                                        disabled
                                                        title={t('Im Warenkorb')}
                                                    >
                                                        {t('Im Warenkorb')}
                                                    </button>
                                                ) : (
                                                    <Link to={'/product/' + product.id}>
                                                        {t('Variante auswählen')}
                                                    </Link>
                                                )}
                                            </>
                                        ) : (((product.warehouse_active && product.stock > 0) || !product.warehouse_active)) ? (
                                            <button
                                                style={{textTransform: 'none'}}
                                                onClick={() => handleAddToCart(product)}
                                                className={inCart ? "active" : ""}
                                                disabled={inCart}
                                                title={inCart ? t('Im Warenkorb') : t('Jetzt Hinzufügen')}
                                            >
                                                {inCart ? t('Im Warenkorb') : t('Jetzt Hinzufügen')}
                                            </button>
                                        ) : (
                                            <button disabled className="active">
                                                {t('Ausverkauft')}
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <button disabled className="active">
                                        {t('Nicht lieferbar')}
                                    </button>
                                )}
                            </div>
                            <div className="pro-same-action pro-quickview">
                                <Tooltip title={t("Zum Produkt")}>
                                    <button onClick={handleOpenProduct} title={t("Zum Produkt")}>
                                        <OpenIcon />
                                    </button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <div className="product-content text-center">
                        <Link to={"/product/" + product.id} variant="h3" style={{fontWeight: 600}}>
                            {tp(product, 'name')}
                        </Link>
                        {/*{product.rating && product.rating > 0 ? (<div className="product-rating">*/}
                        {/*    <Rating ratingValue={product.rating} />*/}
                        {/*</div>) : ("")}*/}
                        <div className="product-price">
                            <Price product={product} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

ProductPreview.propTypes = {
    product: PropTypes.object,
    sendMessage: PropTypes.func
};

export default ProductPreview;
