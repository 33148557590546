import React, { useEffect, useState } from "react";
import useImageLoader from "../../../../omnia/hooks/use-image-loader";
import { LightgalleryItem } from "react-lightgallery";
import { IconButton } from "@mui/material";
import { Maximize2 } from "react-feather";
import { useIsMobile } from "../../../../omnia/hooks/use-is-mobile";
import { useTheme } from "@mui/system";

function SwiperImageSlide({source, getWidth}){

    const theme = useTheme();
    const { imageSrc } = useImageLoader(source);
    const { isMobile } = useIsMobile();

    return (
        <>
            <LightgalleryItem group="any" src={source}>
                <IconButton>
                    <Maximize2 color={theme.palette.primary.main} />
                </IconButton>
            </LightgalleryItem>
            <div
                // onClick={handleOpenGallery}
                className="single-image"
                style={{
                    height: isMobile ? 400 : 600,
                    width: getWidth(),
                    backgroundPosition: 'center',
                    backgroundImage: 'url(' + imageSrc + ')',
                    backgroundSize: 'cover'
                }}
            />
        </>
    )
}

export default SwiperImageSlide;