import { createComponents } from "./create-components";
import { createPalette } from "./create-palette";
import { createShadows } from "./create-shadows";

export const createOptions = (config) => {
    const { primaryColor, secondaryColor, accentColor, contrast, logoLight, logoDark, noAdmin } = config;
    const palette = createPalette(config);
    const components = createComponents({ palette, config });
    const shadows = createShadows();

    return {
        components,
        palette,
        shadows,
        logo: {
            light: logoLight,
            dark: logoDark
        },
        setup: noAdmin
    };
};
