import React, { useEffect, useRef, useState } from "react";
import { ShoppingBag, Trash2 as TrashIcon } from "react-feather";
import {
    Link,
    Grid,
    Box,
    Badge,
    IconButton,
    Tooltip,
    Button,
    Popover,
    Divider,
    Typography
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import useTranslator from "../../../hooks/useTranslator";
import useShopTheme from "../../../hooks/useShopTheme";
import Price from "../../../pages/shop/ShopView/ProductPreview/Price";
import { removeFromCart } from "../../../redux/actions/shopActions";
import { useCartManager } from "../../../hooks/useCartManager";
import { useNotifications } from "../../../omnia/hooks/use-notifications";
import useImageLoader from "../../../omnia/hooks/use-image-loader";
import NumberFormat from "react-number-format";

// const useStyles = makeStyles((theme) => ({
//     badge: {
//         backgroundColor: theme.palette.primary.main,
//     },
//     popover: {
//         // width: 320,
//         marginTop: theme.spacing(2),
//         padding: theme.spacing(2)
//     }
// }));

function CartMenuItem({item, cartItem}){

    const theme = useTheme();
    const dispatch = useDispatch();
    const { productIsDeliverable } = useCartManager();
    const { imageSrc } = useImageLoader(cartItem.product.featured ? cartItem.product.featured.view : null);
    const { t, tp } = useTranslator();
    const { notify } = useNotifications();

    const handleRemoveCart = () => {
        dispatch(removeFromCart(cartItem.id));
        notify(t("Aus Warenkorb entfernt"), "success");
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Link component={RouterLink} to={"/product/" + cartItem.product.id}>
                    {/*<img alt="" src={cartItem.product.featured ? cartItem.product.featured.view : "/assets/images/empty_product_vertical.jpg"} className="img-fluid" />*/}
                    <div
                        className="img-fluid"
                        style={{
                            borderRadius: 3,
                            backgroundImage: 'url(' + imageSrc + ')',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: 100
                        }}
                    />

                </Link>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1}>
                    <Grid item xs={10} style={{paddingTop: 9}}>
                        <Link component={RouterLink} variant="body1" sx={{fontWeight: 600}} color="textPrimary" to={"/product/" + cartItem.product.id}>
                            {' ' + tp(cartItem.product, 'name') + ' '}
                        </Link>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex">
                            <Box flexGrow={1} />
                            <Box>
                                <Tooltip title={t("Aus Warenkorb entfernen")} placement="left-start">
                                    <IconButton onClick={handleRemoveCart} >
                                        <TrashIcon size={16} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={1}>
                    {productIsDeliverable(cartItem.product) ? (
                        <>
                            <span>
                                {t('Menge')}: {cartItem.quantity}
                            </span>
                            <br />
                            <span>
                                {t('Preis')}: <Price product={cartItem.product} variant={item} />
                            </span>
                        </>
                    ) : (
                        <span style={{color: theme.palette.error.main}}>
                            {t("Produkt nicht lieferbar")}
                        </span>
                    )}

                </Box>
            </Grid>
        </Grid>
    )
}

function CartMenu({themeMode}) {

    // const classes = useStyles();
    const theme = useTheme();
    const [cartTotalPrice, setTotalCartValue] = useState(0);
    const { cartItems, grandTotal, positions, productIsDeliverable } = useCartManager();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { deliverable_products } = useSelector(state => state.shop);
    const userPopover = useRef();
    const { getTheme } = useShopTheme();
    let { t } = useTranslator();
    const navigate = useNavigate();

    const handleOpenMenu = () => {
        setDropdownOpen(true);
    };

    const handleCloseMenu = () => {
        setDropdownOpen(false);
    };

    const handleCartClick = () => {
        navigate('/cart');
        setDropdownOpen(false);
    }

    const handleCheckoutClick = () => {
        navigate('/checkout');
        setDropdownOpen(false);
    }

    useEffect(() => {
        let totalValue = 0;
        for(let i = 0; i < cartItems.length; i++){
            if(productIsDeliverable(cartItems[i].product.id)){
                totalValue += parseFloat(parseFloat(cartItems[i].price * cartItems[i].quantity).toFixed(2));
            }
        }
        setTotalCartValue(totalValue);
    }, [cartItems, deliverable_products]);

    return (
        <>
            <div style={{width: 35}} className="same-style d-none d-lg-block">
                <Tooltip title={t("Mein Einkaufswagen")}>
                    <IconButton size="small" ref={userPopover} onClick={handleOpenMenu}>
                        <Badge
                            componentsProps={{style: {backgroundColor: theme.palette.primary.main}}}
                            // classes={{ badge: classes.badge }}
                            badgeContent={cartItems.length}
                            color="primary"
                        >
                            <ShoppingBag style={getTheme(themeMode)} />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>
            <Popover
                open={dropdownOpen}
                onClose={handleCloseMenu}
                anchorEl={userPopover.current}
                // sx={{marginTop: 2}}
                // classes={{paper: classes.popover}}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2}>
                    <Typography variant="h4" style={{fontSize: 20, marginBottom: 25}} color="textPrimary">
                        {t("Einkaufswagen")}
                    </Typography>
                    {cartItems.length > 0 ? (
                        <Grid container spacing={2} minWidth={300} maxWidth={400}>
                            <Grid item xs={12}>
                                {cartItems.map((item, i) => (
                                    <Box mb={2} key={'cart-item-' + i}>
                                        <CartMenuItem item={item} cartItem={item} />
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={12} pb={2}>
                                <Divider />
                            </Grid>
                            {positions.filter(p => p.name !== "Produkte").map((p, i) => (
                                <Grid item key={'positions-' + i} xs={12} style={{paddingTop: 2}}>
                                    <Box display="flex" >
                                        <Box>{t(p.name)}:</Box>
                                        <Box flexGrow={1} />
                                        <Box>
                                            <NumberFormat
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={parseFloat(p.value)}
                                                displayType={'text'}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                suffix={' €'}
                                            />
                                            {/*{cartTotalPrice.toFixed(2) + ' €'}*/}

                                        </Box>
                                    </Box>
                                </Grid>

                            ))}
                            <Grid item xs={12} style={{paddingTop: 2, fontWeight: 600}}>
                                <Box display="flex">
                                    <Box>{t("Gesamtsumme")}:</Box>
                                    <Box flexGrow={1} />
                                    <Box>
                                        <NumberFormat
                                            decimalScale={2}
                                            fixedDecimalScale
                                            value={parseFloat(grandTotal)}
                                            displayType={'text'}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            suffix={' €'}
                                        />
                                        {/*{cartTotalPrice.toFixed(2) + ' €'}*/}

                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <Button size="large" fullWidth onClick={handleCartClick} variant="outlined" color="primary" sx={{color: 'black', fontWeight: 400, borderColor: 'black'}}>
                                            {t("Warenkorb")}
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button size="large" fullWidth onClick={handleCheckoutClick} variant="contained" sx={{fontWeight: 400}}>
                                            {t("Checkout")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3} minWidth={300} maxWidth={400}>
                            <Grid item xs={12}>
                                <div style={{height: 65, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                    <Typography variant="body1" style={{marginBottom: 20}}>
                                        {t("Dein Warenkorb ist leer")}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Popover>
        </>
    )

}

export default CartMenu;