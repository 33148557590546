export default function getThemeConfig(paletteMode){
    const primaryColorMain = "#fe5000";
    const secondaryColorMain = "#fe5000";

    const themeConfig = {
        paletteMode: paletteMode,
        primaryColor: primaryColorMain,
        secondaryColor: secondaryColorMain,
        accentColor: primaryColorMain,
        logoLight: '/images/logo-light.png',
        logoDark: '/images/logo-dark.png',
        typography: {
            button: {
                fontWeight: 600
            },
            // fontFamily: "\"Inter\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", Helvetica, Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\"",
            fontFamily: "Roboto, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\"",
            body1: {
                fontSize: "14px",
                fontWeight: 400,
                lineHeight: 1.3,
                letterSpacing: '0.5px'
            },
            body2: {
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: 1.3,
                letterSpacing: '0.5px'
            },
            subtitle1: {
                fontSize: "18px",
                fontWeight: 400,
                lineHeight: 1.5
            },
            subtitle2: {
                fontSize: "0.875rem",
                fontWeight: 500,
                lineHeight: 1.5
            },
            overline: {
                fontSize: "0.75rem",
                fontWeight: 600,
                letterSpacing: "0.5px",
                lineHeight: 2.5,
                textTransform: "uppercase"
            },
            caption: {
                fontSize: "14px",
                color: primaryColorMain,
                fontWeight: 400,
                lineHeight: 1.0
            },
            h1: {
                fontWeight: 500,
                fontSize: "45px",
                lineHeight: 1.2,
                textTransform: 'uppercase'
            },
            h2: {
                fontWeight: 500,
                fontSize: "40px",
                lineHeight: 1.2
            },
            h3: {
                fontWeight: 500,
                fontSize: "35px",
                lineHeight: 1.2
            },
            h4: {
                fontWeight: 500,
                fontSize: "30px",
                lineHeight: 1.2
            },
            h5: {
                fontWeight: 500,
                fontSize: "25px",
                lineHeight: 1.2
            },
            h6: {
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: 1.2
            }
        }
    };

    const { typography, ...otherConfig } = themeConfig;

    const transformedTypographyConfig = {
        ...otherConfig,
        bodyFont: typography.fontFamily,
        headlineFont: typography.fontFamily, // Assuming headlineFont is the same as bodyFont
        body1Size: typography.body1?.fontSize,
        body1FontWeight: typography.body1?.fontWeight,
        body1LineHeight: typography.body1?.lineHeight,
        body2Size: typography.body2?.fontSize,
        body2FontWeight: typography.body2?.fontWeight,
        body2LineHeight: typography.body2?.lineHeight,
        subtitle1Size: typography.subtitle1?.fontSize,
        subtitle1FontWeight: typography.subtitle1?.fontWeight,
        subtitle1LineHeight: typography.subtitle1?.lineHeight,
        subtitle2Size: typography.subtitle2?.fontSize,
        subtitle2FontWeight: typography.subtitle2?.fontWeight,
        subtitle2LineHeight: typography.subtitle2?.lineHeight,
        overlineSize: typography.overline?.fontSize,
        overlineFontWeight: typography.overline?.fontWeight,
        overlineLineHeight: typography.overline?.lineHeight,
        captionSize: typography.caption?.fontSize,
        captionFontWeight: typography.caption?.fontWeight,
        captionLineHeight: typography.caption?.lineHeight,
        h1FontWeight: typography.h1?.fontWeight,
        h1Size: typography.h1?.fontSize,
        h1LineHeight: typography.h1?.lineHeight,
        h2FontWeight: typography.h2?.fontWeight,
        h2Size: typography.h2?.fontSize,
        h2LineHeight: typography.h2?.lineHeight,
        h3FontWeight: typography.h3?.fontWeight,
        h3Size: typography.h3?.fontSize,
        h3LineHeight: typography.h3?.lineHeight,
        h4FontWeight: typography.h4?.fontWeight,
        h4Size: typography.h4?.fontSize,
        h4LineHeight: typography.h4?.lineHeight,
        h5FontWeight: typography.h5?.fontWeight,
        h5Size: typography.h5?.fontSize,
        h5LineHeight: typography.h5?.lineHeight,
        h6FontWeight: typography.h6?.fontWeight,
        h6Size: typography.h6?.fontSize,
        h6LineHeight: typography.h6?.lineHeight
    };

    return transformedTypographyConfig;
}