import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Container, Grid } from "@mui/material";
import Breadcrumb from "../../../wrappers/breadcrumb/Breadcrumb";
import ShopActions from "./ShopActions";
import ProductPreview from "./ProductPreview";
import produce from "immer";

function Shop({sendMessage}){

    const [view, setView] = useState('grid');
    const [sort, setSort] = useState('new');
    const [results, setResults] = useState([]);
    const { products } = useSelector(state => state.shop);

    useEffect(() => {
        switch (sort){
            case 'new': {
                setResults(produce(products, draft => draft.sort((a, b) => {
                    if (a.is_new && !b.is_new) {
                        return -1;
                    } else if (!a.is_new && b.is_new) {
                        return 1;
                    } else {
                        return a.created_at > b.created_at ? -1 : 1;
                    }
                })));
                break;
            }
            case 'price_asc': {
                setResults(produce(products, draft => draft.sort((a, b) => {
                    return a.cheapest_price_euro > b.cheapest_price_euro ? 1 : -1;
                })));
                break;
            }
            case 'price_desc': {
                setResults(produce(products, draft => draft.sort((a, b) => {
                    return a.cheapest_price_euro > b.cheapest_price_euro ? -1 : +1;
                })));
                break;
            }
            default: {
                setResults(products);
                break;
            }
        }
    }, [products, sort]);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: 'background.dark',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                paddingTop: 0,
                paddingBottom: 15,
            }}
        >
            <Breadcrumb />
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <ShopActions
                            sendMessage={sendMessage}
                            sort={sort}
                            view={view}
                            onViewChange={setView}
                            onSortChange={setSort}
                        />
                    </Grid>
                    {view === 'grid' && (
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {results.map((product, i) => (
                                    <Grid item xs={12} sm={6} md={4} key={'shop-item-' + i}>
                                        <ProductPreview
                                            key={'product-' + i}
                                            product={product}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {view === 'table' && (
                        <Grid item xs={12}>

                        </Grid>
                    )}
                </Grid>
            </Container>
        </Box>
    )
}

export default Shop;