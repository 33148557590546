import React from "react";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { Box, Grid, Container, Typography } from "@mui/material";
import useTranslator from "../../hooks/useTranslator";

function Imprint({sendMessage}){

    const { t } = useTranslator();

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: "#F3F3F3",
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100vh - 115px)",
                paddingTop: 4,
                paddingBottom: 4
            }}
        >
            <Container maxWidth="md">
                <Grid container sx={{pt: 10}} spacing={1}>

                    <Grid item xs={12}>
                        <Typography color="textPrimary" variant="h2">
                            {t("Impressum")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 8}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2}}>
                            {t("Firma")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 1}}>
                            {t("SIMPLY WARM UG (haftungsbeschränkt)")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 1}}>
                            {t("Unterberg 15 b")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 1}}>
                            {t("21033 Hamburg")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 1}}>
                            {t("Deutschland")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} p={0} mt={6}>
                        <Typography color="textPrimary" variant="body1">
                            {t("Kontakt: Telefon +49-40-411 89 88-14 · E-Mail info@simply-warm.com")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} p={0}>
                        <Typography color="textPrimary" variant="body1">
                            {t("Geschäftsführerin: Imke Belser")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} p={0} >
                        <Typography color="textPrimary" variant="body1">
                            {t("Handelsregister: HRA 128125 · Amtsgericht Hamburg")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} p={0}>
                        <Typography color="textPrimary" variant="body1">
                            {t("Bankverbindung: Grenke Bank · BIC GREBDEH1 · IBAN DE94 2013 0400 0060 2888 26")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="textPrimary" variant="body1">
                            {t("Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: USt-IdNr. DE34 83 96 140")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="textPrimary" variant="body1">
                            {t("Gestaltung und Implementation: Art Works! Agentur für Kommunikation und Design UG, Hamburg")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography color="textPrimary" variant="body1">
                            {t("Bildnachweis: Franziska Sack by FS Bildpoesie")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2}}>
                            {t("Urheberrecht")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 1, lineHeight: 1.8}}>
                            {t("Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht der Bundesrepublik Deutschland. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 1, lineHeight: 1.8}}>
                            {t("Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht verletzen, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 4, mb: 8}}>
                        <Typography color="textPrimary" variant="h5" sx={{mb: 2}}>
                            {t("EU-Streitschlichtung")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 1, lineHeight: 1.8}}>
                            {t("Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform (OS-Plattform) informieren. Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen Kommission unter https://ec.europa.eu/consumers/odr  zu richten. Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.")}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

export default Imprint;