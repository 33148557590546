import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../wrappers/breadcrumb/Breadcrumb";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Price from "./../ShopView/ProductPreview/Price";
import useTranslator from "../../../hooks/useTranslator";
import { removeFromWishlist } from "../../../redux/actions/shopActions";
import { useNotifications } from "../../../omnia/hooks/use-notifications";
import { useCartManager } from "../../../hooks/useCartManager";
import { useShopFinder } from "../../../hooks/useShopFinder";
import WishlistImage from "./WishlistImage";
import { Box, Button, Typography } from "@mui/material";
import { Heart } from "react-feather";

function Wishlist() {

    const [wishlistItems, setWishlistItems] = useState([]);
    const { wishlist, cart, products } = useSelector(state => state.shop);
    const { t, tp } = useTranslator();
    const { shopUrl, shopPageExists } = useShopFinder();
    const { handleAddToCart, productIsDeliverable } = useCartManager();
    const dispatch = useDispatch();
    const { notify } = useNotifications();
    const navigate = useNavigate();

    const gotoProduct = (id) => {
        navigate('/product/' + id);
    }

    const deleteAllFromWishlist = () => {
        if(window.confirm("Möchtest du deine Wunschliste wirklich löschen?")){
            for(let i = 0; i < wishlist.length; i++)
                dispatch(removeFromWishlist(wishlist[i]));
            notify(t("Wunschliste gelöscht"), "success");
        }
    }

    const handleGoForIt = (id) => {
        const product = products.find(p => p.id === id);
        handleAddToCart(product);
    }

    const deleteFromWishlist = (id) => {
        dispatch(removeFromWishlist(id));
        notify(t("Von Wunschliste entfernt"), "success");
    }

    useEffect(() => {
        setWishlistItems(products.filter(p => wishlist.includes(p.id)));
    }, [wishlist, products]);

    return (
        <>
            <Breadcrumb />
            <div className="cart-main-area pt-90 pb-100">
                <div className="container">
                    {wishlistItems && wishlistItems.length > 0 ? (
                        <Fragment>
                            <h3 className="cart-page-title">{t("Deine Wunschliste")}</h3>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-content table-responsive cart-table-content">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{t("Vorschau")}</th>
                                                    <th>{t("Produkt")}</th>
                                                    <th>{t("Stückpreis")}</th>
                                                    <th>{t("Hinzufügen")}</th>
                                                    <th>{t("Aktion")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {wishlistItems.map((wishlistItem, key) => (
                                                <tr key={key}>
                                                    <td className="product-thumbnail">
                                                        <Link to={"/product/" + wishlistItem.id}>
                                                            <WishlistImage image={wishlistItem.featured ? wishlistItem.featured.view : null} />
                                                        </Link>
                                                    </td>
                                                    <td className="product-name text-center">
                                                        <Link to={"/product/" + wishlistItem.id}>
                                                            {tp(wishlistItem, 'name')}
                                                        </Link>
                                                    </td>
                                                    <td className="product-price-cart">
                                                        <Price product={wishlistItem} />
                                                    </td>
                                                    <td className="" style={{minWidth: 200, textAlign: 'center', paddingLeft: 0}}>
                                                        {productIsDeliverable(wishlistItem.id) ? (
                                                            <>
                                                                {wishlistItem.variants.length > 1 ? (
                                                                    // <button title={t("Auswählen")} onClick={() => gotoProduct(wishlistItem.id)}>
                                                                    //     {t("Auswählen")}
                                                                    // </button>
                                                                    <Button onClick={() => gotoProduct(wishlistItem.id)} variant="contained">{t("Auswählen")}</Button>
                                                                ) : (((wishlistItem.warehouse_active && wishlistItem.stock > 0) || !wishlistItem.warehouse_active)) ? (
                                                                    <>
                                                                        {(cart.filter(c => products.map(p => p.variants).flat().map(v => v.id).includes(c.id)).length > 0) ? (
                                                                            // <button className="active" disabled title={t("Im Warenkorb")}>
                                                                            //     {t("Im Warenkorb")}
                                                                            // </button>
                                                                            <Button disabled variant="contained">{t("Im Warenkorb")}</Button>
                                                                        ) : (
                                                                            // <button onClick={() => handleGoForIt(wishlistItem.id)} title={t("Hinzufügen")}>
                                                                            //     {t("Hinzufügen")}
                                                                            // </button>
                                                                            <Button variant="contained" onClick={() => handleGoForIt(wishlistItem.id)}>{t("Hinzufügen")}</Button>
                                                                        )}
                                                                    </>

                                                                ) : (
                                                                    <Button disabled variant="contained">{t("Ausverkauft")}</Button>
                                                                    // <button disabled className="active">
                                                                    //     {t("Ausverkauft")}
                                                                    // </button>
                                                                )}
                                                            </>
                                                        ) : (
                                                            // <button disabled className="active">
                                                            //     {t("Nicht lieferbar")}
                                                            // </button>
                                                            <Button disabled variant="contained">{t("Nicht lieferbar")}</Button>
                                                        )}

                                                    </td>
                                                    <td className="product-remove">
                                                        <button onClick={() => deleteFromWishlist(wishlistItem.id)}>
                                                            <i className="fa fa-times" />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="cart-shiping-update-wrapper">
                                        <div className="cart-shiping-update">
                                            {shopPageExists ? (
                                                <Button size="large" variant="text" onClick={() => navigate(shopUrl)}>
                                                    {t("Weitere Produkte")}
                                                </Button>
                                                // <Link to={shopUrl}>
                                                //     {t("Zurück zum ShopView")}
                                                // </Link>
                                            ) : (
                                                <Button size="large" variant="text" onClick={() => navigate('/')}>
                                                    {t("Simply Warm entdecken")}
                                                </Button>
                                                // <Link to='/'>
                                                //   {/*{t("Zur Startseite")}*/}
                                                // </Link>
                                            )}
                                        </div>
                                        <div className="">
                                            <Button size="large" variant="text" onClick={deleteAllFromWishlist}>
                                                {t("Wunschliste löschen")}
                                            </Button>
                                            {/*<button onClick={deleteAllFromWishlist}>*/}
                                            {/*</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="item-empty-area text-center">
                                    <div className="item-empty-area__icon mb-30">
                                        {/*<i className="pe-7s-like" />*/}
                                        <Heart size={95} />
                                    </div>
                                    <div className="item-empty-area__text">
                                        <Typography variant="subtitle1">
                                            {t("Deine Wunschliste ist aktuell leer")}
                                        </Typography>
                                        <br />
                                        <Box mt={4}>
                                            {shopPageExists ? (
                                                <Button size="large" variant="contained" onClick={() => navigate(shopUrl)}>
                                                    {t("Produkte hinzufügen")}
                                                </Button>
                                            ) : (
                                                <Button size="large" variant="contained" onClick={() => navigate('/')}>
                                                    {t("Zur Startseite")}
                                                </Button>
                                            )}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Wishlist;