import { common } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { error, getDynamicColor, info, neutral, success, warning } from "../colors";
import adjustLuminanceToTarget from "../../utils/adjust-color-luminence";

export const createPalette = (config) => {
    const { primaryColor, secondaryColor, accentColor, contrast } = config;

    return {
        action: {
            active: neutral[500],
            disabled: alpha(neutral[900], 0.38),
            disabledBackground: alpha(neutral[900], 0.12),
            focus: alpha(neutral[900], 0.16),
            hover: alpha(neutral[900], 0.04),
            selected: alpha(neutral[900], 0.12)
        },
        background: {
            default: contrast === "high" ? neutral[50] : common.white,
            paper: common.white,
            discrete: neutral[50],
            evident: adjustLuminanceToTarget(primaryColor, 0.025),
        },
        divider: "#F2F4F7",
        error,
        info,
        mode: "light",
        neutral,
        primary: getDynamicColor(primaryColor),
        secondary: getDynamicColor(secondaryColor),
        accent: getDynamicColor(accentColor),
        success,
        text: {
            primary: neutral[900],
            secondary: neutral[500],
            disabled: alpha(neutral[900], 0.38)
        },
        warning
    };
};
