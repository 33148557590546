import PropTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
// import MetaTags from "react-meta-tags";
import useTranslator from "../hooks/useTranslator";
import SimplyWarmLayout from "../layouts/SimplyWarmLayout";
import { Button, Typography } from "@mui/material";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";

const PageNotFound = ({siteSetup, sendMessage}) => {

    const { t } = useTranslator();
    const location = useLocation();
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/');
    }

    useEffect(() => {
        sendMessage({
            type: '404',
            location: location.pathname
        })
    }, []);

    return (
        <Fragment>
            {/*<MetaTags>*/}
            {/*    <title>Simply Warm | Nicht gefunden</title>*/}
            {/*    <meta name="Simlpy Warm" content="404,simply-warm,not found" />*/}
            {/*</MetaTags>*/}
            <SimplyWarmLayout siteSetup={siteSetup} sendMessage={sendMessage}>
                <div className="error-area pt-80 pb-100" style={{minHeight: 'calc(100vh - 200px)'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-8 text-center">
                                <div className="error">
                                    <Typography variant="h1" color="primary">
                                        404
                                    </Typography>
                                    <Typography variant="h3">
                                        {t('Die Seite wurde nicht gefunden')}
                                    </Typography>
                                    <Typography sx={{mt: 2}} variant="subtitle1">
                                        {t('Was auch immer du hier gesucht hast, konnten wir leider nicht finden. Das tut und leid.')}
                                    </Typography>
                                    <Button size="large" sx={{mt: 6}} variant="outlined" onClick={handleGoHome}>
                                        {t("Zur Startseite")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SimplyWarmLayout>
        </Fragment>
    );
};

PageNotFound.propTypes = {
    location: PropTypes.object
};

export default PageNotFound;
