import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useImageLoader from "../../../omnia/hooks/use-image-loader";

function GalleryImage({src, height}){
    const { imageSrc } = useImageLoader(src, '/assets/images/loading_cms_dark.jpg');
    return <div style={{backgroundSize: 'cover', backgroundImage: 'url(' + imageSrc + ')', height: height + 'vh', width: '100%'}} />;
}

function Gallery({a, images, texts, component, setup, sendMessage, t}){

    const [height, setHeight] = useState(50);
    const [arrows, setArrows] = useState(false);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        if(typeof setup['misc'] !== "undefined"){
            setHeight(setup['misc']['height']);
            setArrows(setup['misc']['showArrows']);
            setStatus(setup['misc']['showStatus']);
        }
    }, [setup]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Carousel
                    // showStatus={status}
                    showThumbs={3}
                    infiniteLoop={true}
                    // showArrows={arrows}
                    // centerSlidePercentage={5}
                >
                    {images.map((image, i) => (
                        <div key={'item-' + i}>
                            <GalleryImage src={image.view} height={height} />
                        </div>
                    ))}
                </Carousel>
            </Grid>
        </Grid>
    );
}

Gallery.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func,
    t: PropTypes.func
}

Gallery.defaultProps = {
    buttonId: ""
}

export default Gallery;