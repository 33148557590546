import React  from "react";
import { changeLanguage } from "redux-multilanguage";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, TextField } from "@mui/material";
import { setCountry } from "../../../redux/actions/shopActions";
import useTranslator from "../../../hooks/useTranslator";

const MobileSettings = () => {

    const { t } = useTranslator();
    const dispatch = useDispatch();
    const { languages, countries, country } = useSelector(state => state.shop);
    const { currentLanguageCode } = useSelector(state => state.multilanguage);

    const handleLanguageChange = (ident) => {
        dispatch(changeLanguage(ident));
    }

    const handleCountryChange = (code) => {
        dispatch(setCountry(countries.find(c => c.code === code)));
    }

    return (
        <div className="">
            <div className="">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box mt={4}>
                            <TextField
                                fullWidth
                                label="Sprache"
                                name="language"
                                onChange={(event) => handleLanguageChange(event.target.value)}
                                select
                                SelectProps={{native: true}}
                                value={currentLanguageCode}

                            >
                                {languages.map(lan => (
                                    <option
                                        key={lan.ident}
                                        value={lan.ident}
                                    >
                                        {t(lan.name)}
                                    </option>
                                ))}
                            </TextField>
                        </Box>
                        <Box mt={2}>
                            <TextField
                                fullWidth
                                label="Land"
                                name="country"
                                onChange={(event) => handleCountryChange(event.target.value)}
                                select
                                SelectProps={{native: true}}
                                value={country ? country.code : null}

                            >
                                <option
                                    value={null}
                                >
                                    Bitte auswählen
                                </option>
                                {countries.map(c => (
                                    <option
                                        key={c.code}
                                        value={c.code}
                                    >
                                        {c.name}
                                    </option>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

MobileSettings.propTypes = {};

export default MobileSettings;
