import PropTypes from "prop-types";
import React, { useState } from "react";
import { Controller } from 'swiper/modules';
import { LightgalleryProvider } from "react-lightgallery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid } from "@mui/material";
import SwiperImageSlide from "./SwiperImageSlide";
import NumberFormat from "react-number-format";
import useTranslator from "../../../../hooks/useTranslator";
import "lightgallery.js/dist/css/lightgallery.css";

const ProductImages = ({ product }) => {

    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const { t } = useTranslator();

    const getWrapperDimensions = () => {
        const el = document.getElementById('thumbnailSwiperWrapper');
        if (el)
            return el.offsetWidth;
        return 0;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <div className="product-large-image-wrapper">
                    {(product.offer_price_euro || product.is_new) && (
                        <div className="product-img-badges">
                            {product.offer_price_euro && (
                                <span className="purple" style={{height: 25}}>
                                    <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={parseFloat(parseFloat(product.price_euro) - parseFloat(product.offer_price_euro))}
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        sx={{m: 0}}
                                        decimalSeparator={','}
                                        prefix={'-'}
                                        suffix={' €'}
                                    />
                                </span>
                            )}
                            {product.is_new && (
                                <span className="purple" style={{height: 25, paddingTop: 6}}>{t("Neu")}</span>
                            )}
                        </div>
                    )}
                    <LightgalleryProvider>
                        <Swiper
                            effect="fade"
                            spaceBetween={10}
                            loopedSlides={4}
                            loop={true}
                            modules={[Controller]}
                            onSwiper={setFirstSwiper}
                            controller={{ control: secondSwiper }}
                        >
                            {product.pictures.map((single, i) => {
                                return (
                                    <SwiperSlide key={i}>
                                        <SwiperImageSlide source={single.view} getWidth={getWrapperDimensions} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </LightgalleryProvider>
                </div>
            </Grid>
            <Grid item xs={12} sx={{mt: 1}} id="thumbnailSwiperWrapper">
                <Swiper
                    spaceBetween={10}
                    slidesPerView={4}
                    touchRatio={0.2}
                    loop={true}
                    slideToClickedSlide={true}
                    modules={[Controller]}
                    onSwiper={setSecondSwiper}
                    controller={{ control: firstSwiper }}
                    direction="horizontal"
                >
                    {product.pictures.map((single, i) => {
                        return (
                            <SwiperSlide key={i}>
                                <div className="single-image" style={{cursor: 'pointer'}}>
                                    <img src={single.view} className="img-fluid" alt={single.name} />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </Grid>
        </Grid>
    )

};

ProductImages.propTypes = {
    product: PropTypes.object,
    thumbPosition: PropTypes.string
};

export default ProductImages;
