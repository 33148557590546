import React from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import 'swiper/css';
import { Toaster } from "react-hot-toast";
import { ThemeProvider, StyledEngineProvider } from "@mui/system";
import { createTheme } from "./omnia/theme/index";
import SiteBuilder from "./SiteBuilder";
import { useRoutes } from "react-router";
import getThemeConfig from "./utils/get-theme";

const App = () => {
    
    const routes = [
        {
            index: true,
            path: '/:url?/:identifier?',
            element: <SiteBuilder />,
        }
    ]

    const element = useRoutes(routes);

    const theme = createTheme(getThemeConfig('light'))

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Toaster
                    position="top-center"
                    autoClose={2000}
                    newestOnTop={true}
                />
                <BreadcrumbsProvider>
                    {element}
                </BreadcrumbsProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default connect()(multilanguage(App));
