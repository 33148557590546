import React from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import useTranslator from "../hooks/useTranslator";
import { useSelector } from "react-redux";

function PrivacyDialogOptions({allowPrivacy, handleChange, ...rest}){

    const { t } = useTranslator();
    const { facebook_pixel } = useSelector(state => state.shop);

    return (
        <Grid container {...rest}>
            <Grid item xs={12} sx={{mt: 1}}>
                <FormControlLabel
                    control={
                        <Switch checked disabled />
                    }
                    label={(
                        <>
                            <Typography variant="body1" sx={{fontWeight: 600}}>
                                {t("Notwendige Daten")}
                            </Typography>
                            <Typography variant="caption">
                                ({t("z.B. wenn du eine Bestellung absendest")})
                            </Typography>
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={12} sx={{mt: 1}}>
                <FormControlLabel
                    control={
                        <Switch checked={allowPrivacy.includes('usage')} onChange={handleChange} name="usage" />
                    }
                    label={(
                        <>
                            <Typography variant="body1" sx={{fontWeight: 600}}>
                                {t("Interne Nutzungsdaten")}
                            </Typography>
                            <Typography variant="caption">
                                ({t("z.B. welche Seiten du besuchst")})
                            </Typography>
                        </>
                    )}
                />
            </Grid>
            {(facebook_pixel && facebook_pixel !== "") && (
                <Grid item xs={12} sx={{mt: 1}}>
                    <FormControlLabel
                        control={
                            <Switch checked={allowPrivacy.includes('fb')} onChange={handleChange} name="fb" />
                        }
                        label={(
                            <>
                                <Typography variant="body1" sx={{fontWeight: 600}}>
                                    {t("Facebook Marketing")}
                                </Typography>
                                <Typography variant="caption">
                                    ({t("z.B. Pixel für Facebook Kampagnen")})
                                </Typography>
                            </>
                        )}
                    />
                </Grid>
            )}
            {/*<Grid item xs={12} sx={{mt: 1}}>*/}
            {/*    <FormControlLabel*/}
            {/*        control={*/}
            {/*            <Switch checked={allowPrivacy.includes('ga')} onChange={handleChange} name="ga" />*/}
            {/*        }*/}
            {/*        label={(*/}
            {/*            <>*/}
            {/*                <Typography variant="body1" sx={{fontWeight: 600}}>*/}
            {/*                    {t("Google Analytics")}*/}
            {/*                </Typography>*/}
            {/*                <Typography variant="caption">*/}
            {/*                    ({t("wir möchten unsere Website optimieren")})*/}
            {/*                </Typography>*/}
            {/*            </>*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</Grid>*/}
        </Grid>
    )
}

export default PrivacyDialogOptions;