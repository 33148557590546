import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from "@mui/material";
// import { makeStyles } from "@mui/system";
import useTranslator from "../../hooks/useTranslator";

function GiveEmailWishlist({onDone}){

    const [email, setEmail] = useState(null);
    const { t } = useTranslator();
    // const classes = useStyles();

    const saveEmail = () => {
        onDone(email);
    }

    const handleClose = () => {
        onDone();
    }

    const handleChange = (event) => {
        setEmail(event.target.value);
    }

    return (
        <>
            <Dialog
                // classes={{ paper: classes.paper }}
                onClose={handleClose}
                maxWidth="xs"
                aria-labelledby="simple-dialog-title"
                open={true}
            >
                <DialogContent sx={{pb: 0}}>
                    <Box >
                        <Typography variant="h5">
                            {t("Wunschliste")}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <Typography variant="body1">
                            {t("Gib uns deine Email Adresse und wir melden uns, wenn es Info's zu den Produkten auf deiner Wunschliste gibt.")}
                        </Typography>
                    </Box>
                    <Box mt={2}>
                        <TextField
                            size="medium"
                            fullWidth
                            label="Email"
                            onChange={handleChange}
                            placeholder={t("Email Adresse")}
                            value={email}

                        />
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: 3}}>
                    <Button variant="outlined" onClick={handleClose}>
                        {t("Abbrechen")}
                    </Button>
                    <Button disabled={email === null || email === ""} variant="contained" onClick={saveEmail}>
                        {t("Speichern")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

GiveEmailWishlist.propTypes = {
    onDone: PropTypes.func
}

export default GiveEmailWishlist;