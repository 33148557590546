import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import useComponentImage from "../../../hooks/useComponentImage";
import useStyleBuilder from "../../../hooks/useStyleBuilder";
import { Button, Typography } from "@mui/material";
import ActionButton from "./Button";
import { ChevronLeft, ChevronRight } from "react-feather";
import { useIsMobile } from "../../../omnia/hooks/use-is-mobile";
import { useTheme } from "@mui/system";
import useImageLoader from "../../../omnia/hooks/use-image-loader";
import useTranslator from "../../../hooks/useTranslator";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

function SliderContent({single, contentAlign, setup, component, key, sendMessage, textAlign, sliderHeight}){

    const { imageSrc } = useImageLoader(single["image"], '/assets/images/loading_cms_dark.jpg');
    const { isMobile } = useIsMobile();
    const { getTextStyles } = useStyleBuilder();
    const theme = useTheme();
    const { t } = useTranslator();

    return (
        <div
            className="single-slider-2 slider-height-4 slider-height-res15 d-flex align-items-center slider-height-res bg-img swiper-slide"
            style={{ backgroundImage: 'url(' + imageSrc + ')', width: '100%', height: sliderHeight + 'vh', align: 'center' }}
        >
            <div className="container">
                <div className="row">
                    <div className={(contentAlign === "center") ? "col-xl-12" : ("col-xl-6 col-lg-6 col-md-7 " + (contentAlign === "left" ? "" : 'ml-auto'))}>
                        <div className="">
                            <div style={isMobile ? {} : {textAlign: textAlign}}>
                                {((typeof single["topline"] !== "undefined") && (single['topline'].value !== "")) && (
                                    <Typography variant="caption" style={getTextStyles(single['topline'].styles, {})}>
                                        {t(single['topline'].value)}
                                    </Typography>
                                )}
                                {((typeof single["title"] !== "undefined") && (single['title'].value !== "")) && (
                                    <Typography variant="h1" sx={{mb: 3}} className="title" style={getTextStyles(single['title'].styles, {color: 'white'})}>
                                        {t(single["title"].value)}
                                    </Typography>
                                )}
                                {((typeof single["text"] !== "undefined") && (single['text'].value !== "")) && (
                                    <Typography variant="subtitle1" sx={{mb: 3}} className="title" style={getTextStyles(single['title'].styles, {color: 'white'})}>
                                        {t(single["text"].value)}
                                    </Typography>
                                )}
                                {(((typeof single["button"] !== "undefined") && (single['button'].value !== "")) && ((typeof single["url"] !== "undefined") && (single['url'].value !== ""))) && (
                                    <ActionButton style={{marginTop: 5}} buttonId={(typeof key !== "undefined") ? key.toString() : 0} component={component} sendMessage={sendMessage} setup={setup} t={t} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function Slider({ component, texts, setup, images, sendMessage, t }) {

    const [textAlign, setTextAlign] = useState('center');
    const [contentAlign, setContentAlign] = useState('left');
    const [sliderData, setSliderData] = useState([]);
    const [sliderHeight, setSliderHeight] = useState(75);
    const [params, setParams] = useState({});
    const [currentSlide, setCurrentSlide] = useState(0);
    const { getImage } = useComponentImage();

    const handleForward = () => {
        if(currentSlide < sliderData.length)
            setCurrentSlide(prev => prev + 1);
    }

    const handleBackward = () => {
        if(currentSlide > 0)
            setCurrentSlide(prev => prev - 1);
    }

    useEffect(() => {
        setParams({
            effect: "fade",
            loop: false,
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: true
            },
            watchSlidesVisibility: true,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            renderPrevButton: () => (
                <Button
                    className="swiper-button-prev ht-swiper-button-nav"
                    disabled={currentSlide === 0} onClick={handleBackward}
                    style={(sliderData.length === 1) ? {display: 'none'} : {top: (sliderHeight / 2) + '%'}}
                >
                    <ChevronLeft size={40} className="pe-7s-angle-right" />
                </Button>
            ),
            renderNextButton: () => (
                <Button
                    className="swiper-button-next ht-swiper-button-nav"
                    disabled={currentSlide === (sliderData.length - 1)}
                    onClick={handleForward}
                    style={(sliderData.length === 1) ? {display: 'none'} : {top: (sliderHeight / 2) + '%'}}
                >
                    <ChevronRight size={40} className="pe-7s-angle-right" />
                </Button>
            )
        })
    }, [currentSlide, sliderData, sliderHeight]);

    useEffect(() => {
        if (typeof setup["misc"] !== "undefined" && typeof setup["misc"]["sliderCount"] !== "undefined") {
            let sd = [];
            let topline, title, text, buttonText = "";
            for (let i = 0; i < setup['misc']['sliderCount']; i++) {
                topline = component.texts.find(t => t.identifier === ("topline-" + i));
                title = component.texts.find(t => t.identifier === ("title-" + i));
                text = component.texts.find(t => t.identifier === ("text-" + i));
                buttonText = component.texts.find(t => t.identifier === ("buttonText-" + i));
                sd = sd.concat([{
                    "id": i,
                    "topline": topline,
                    "title": title,
                    "button": buttonText,
                    "text": text,
                    "image": getImage(images, setup["images"]["slider-" + i]),
                    "url": setup["misc"]["buttonLink-" + i]
                }]);
            }
            setSliderHeight(setup['misc']['height']);
            setSliderData(sd);
        }
    }, [setup, texts, component, images]);

    useEffect(() => {
        if(typeof setup['misc'] !== "undefined" && typeof setup['misc']['align'] !== "undefined")
            setTextAlign(setup['misc']['align']);
        if(typeof setup['misc'] !== "undefined" && typeof setup['misc']['alignContent'] !== "undefined")
            setContentAlign(setup['misc']['alignContent']);
    }, [setup]);

    if (sliderData.length === 0)
        return null;

    return (
        <div className="slider-area">
            <div className="slider-active nav-style-1" >
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    activeSlideKey={'slider-' + currentSlide}
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    {...params}
                >
                    {sliderData && sliderData.map((single, key) => (
                        <SwiperSlide key={'slider-' + key}>
                            <SliderContent
                                single={single}
                                key={key}
                                sendMessage={sendMessage}
                                sliderHeight={sliderHeight}
                                component={component}
                                setup={setup}
                                textAlign={textAlign}
                                contentAlign={contentAlign}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    )

}

Slider.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func
};

export default Slider;