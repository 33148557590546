import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ActionButton from "./Button";
import useStyleBuilder from "../../../hooks/useStyleBuilder";
import { useTheme } from "@mui/system";
import { Typography } from "@mui/material";
import useComponentImage from "../../../hooks/useComponentImage";

function Hero({component, texts, setup, images, sendMessage, t}){

    const [data, setData] = useState({title: null, align: 'left', text: null, topline: null, subline: null, buttonText: null, link: null, image: {}});
    const { getTextStyles } = useStyleBuilder();
    const { getImage } = useComponentImage();
    const theme = useTheme();

    useEffect(() => {

        let title = null;
        let align = 'left';
        let text = null;
        let topline = null;
        let buttonText = null;
        let link = null;
        let image = {};

        if(texts.filter(t => t.identifier === 'title').length === 1)
            title = texts.find(t => t.identifier === 'title');
        if(texts.filter(t => t.identifier === 'text').length === 1)
            text = texts.find(t => t.identifier === 'text');
        if(texts.filter(t => t.identifier === 'buttonText').length === 1)
            buttonText = texts.find(t => t.identifier === 'buttonText');
        if(texts.filter(t => t.identifier === 'topline').length === 1)
            topline = texts.find(t => t.identifier === 'topline');
        if((typeof setup['misc'] !== "undefined") && (typeof setup['misc']['buttonLink'] !== "undefined"))
            link = setup['misc']['buttonLink'];
        if((typeof setup['misc'] !== "undefined") && (typeof setup['misc']['align'] !== "undefined"))
            align = setup['misc']['align'];
        if((typeof setup['images'] !== "undefined") && (typeof setup['images']['main'] !== "undefined"))
            image['backgroundImage'] = 'url(' + getImage(images, setup['images']['main']) + ')';
        if((typeof setup['misc'] !== "undefined") && (typeof setup['misc']['height'] !== "undefined"))
            image['height'] = setup['misc']['height'] + 'vh'

        setData({
            title: title,
            text: text,
            align: align,
            topline: topline,
            buttonText: buttonText,
            url: link,
            image: image
        })

    }, [setup, texts, images]);

    return (
        <div className="slider-area swiper-button-disabled">
            <div className="single-slide bg-img" style={data.image}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 ml-auto mr-auto">
                            <div className="" style={{backgroundColor: 'none', textAlign: data.align}}>
                                {data.topline && (
                                    <Typography variant="caption" style={getTextStyles(data.topline.styles, {color: theme.palette.primary.main})}>
                                        {t(data.topline.value)}
                                    </Typography>
                                )}
                                {data.title && (
                                    <Typography variant="h1" className="title" style={getTextStyles(data.title.styles)}>
                                        {t(data.title.value)}
                                    </Typography>
                                )}
                                {data.text && (
                                    <Typography variant="subtitle1" className="title" style={getTextStyles(data.text.styles)}>
                                        {t(data.text.value)}
                                    </Typography>
                                )}
                                {data.buttonText && data.url && (
                                    <ActionButton style={{marginTop: 5}} component={component} sendMessage={sendMessage} setup={setup} t={t} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

Hero.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func
}

export default Hero;