import React, { useEffect, useState } from "react";
import ProductSelection from "./ProductSelection";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Breadcrumb from "../../../wrappers/breadcrumb/Breadcrumb";
import ProductDetails from "./ProductDetails";
import PropTypes from "prop-types";

function ProductView({sendMessage}){

    const [product, setProduct] = useState(null);
    const { identifier } = useParams();
    const { products } = useSelector(state => state.shop);

    useEffect(() => {
        if(products.filter(p => p.id === parseInt(identifier)).length === 1){
            setProduct(products.find(p => p.id === parseInt(identifier)));
        } else {
            setProduct(null);
        }
    }, [identifier, products]);

    if(!product)
        return null;

    return (
        <>
            <Breadcrumb />
            <ProductSelection sendMessage={sendMessage} product={product} />
            <ProductDetails sendMessage={sendMessage} product={product} />
        </>
    );
}

ProductView.propTypes = {
    sendMessage: PropTypes.func
}

export default ProductView;