import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import produce from "immer";
import { updateCart } from "../redux/actions/shopActions";
import { useNotifications } from "../omnia/hooks/use-notifications";
import useTranslator from "./useTranslator";

export const useCartManager = () => {

    const [cartItems, setCartItems] = useState([]);
    const [positions, setPositions] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslator();
    const [productsTotal, setProductsTotal] = useState(0);
    const { cart, vouchers, products, country, deliverable_products, shippingCost } = useSelector(state => state.shop);
    const { notify } = useNotifications();

    const cartRef = useRef();
    const grossValRef = useRef();
    const voucherRef = useRef();
    const countryRef = useRef();
    cartRef.current = cart;
    grossValRef.current = grandTotal;
    voucherRef.current = vouchers;
    countryRef.current = country;

    const productIsDeliverable = (product) => {
        if(typeof product === "number")
            product = products.find(p => p.id === product);
        return deliverable_products.includes(product.id);
    }

    const handleAddToCart = (product) => {
        if(productIsDeliverable(product)){
            if(product.variants.length === 1){
                dispatch(updateCart(product.variants[0].id, 1));
                notify(t("Zum Warenkorb hinzugefügt"), "success");
            } else {
                notify(t("Ein Fehler ist aufgetreten"), "error");
            }
        } else {
            notify(t("Produkt aktuell nicht lieferbar"), "error");
        }
    }

    const isVoucherActive = (voucher) => {

        // Check minimum and/or maximum cart value
        let maxOkay = voucher.max_order_value ? (productsTotal <= voucher.max_order_value) : true;
        let minOkay = voucher.min_order_value ? (productsTotal >= voucher.min_order_value) : true;

        // Check only single
        let singleOkay = voucher.only_single ? vouchers.length === 1 : true;

        // All have to be satisfied for the voucher to work
        return maxOkay && minOkay && singleOkay;
    }

    useEffect(() => {
        setCartItems(products.map(p => p.variants).flat().filter(v => cart.map(c => c.id).includes(v.id)).map(v => {
            return produce(v, draft => {
                draft["quantity"] = cart.find(c => c.id === v.id).quantity;
                draft["product"] = products.find(p => p.id === v.product);
            });
        }));
    }, [products, cart]);

    useEffect(() => {
        // Compute the product total
        let tmp = 0;
        for(let i = 0; i < cartItems.length; i++){
            if(productIsDeliverable(cartItems[i].product.id)){
                tmp += (cartItems[i].quantity * cartItems[i].price);
            }
        }
        setProductsTotal(tmp);
    }, [deliverable_products, cartItems]);

    useEffect(() => {

        // Initiate position data
        let posData = [
            {name: 'Summe Produkte', value: productsTotal, oldValue: null},
            {name: 'Versandkosten', value: shippingCost, oldValue: null}
        ];

        // Iterate through all vouchers and apply them (create positions)
        let v;
        let voucherValue = 0;
        let voucherValueTotal = 0;
        for(let i = 0; i < vouchers.length; i++){
            // Save current voucher locally for easier access
            v = vouchers[i];
            // Check if the voucher is active
            if(isVoucherActive(v)){

                // Reset current voucher value
                voucherValue = 0;

                // Fixed cart voucher as own position
                if(v['voucher_type'] === 'fixed_cart'){
                    voucherValue = -parseFloat(v['value']);
                    posData = posData.concat([{
                        name: 'Gutschein: ' + v['code'],
                        value: voucherValue,
                        oldValue: null,
                    }]);
                }

                if(v['voucher_type'] === 'percentage'){
                    voucherValue = -((parseFloat(v['value']) / 100) * productsTotal);
                    posData = posData.concat([{
                        name: 'Gutschein: ' + v['code'],
                        value: voucherValue,
                        oldValue: null,
                    }]);
                }

                if(v['voucher_type'] === 'fixed_product'){

                    voucherValue = 0;
                    for(let i = 0; i < cartItems.length; i++){
                        if(productIsDeliverable(cartItems[i].product.id)){
                            if(v.products.includes(cartItems[i].product.id)){
                                voucherValue -= (cartItems[i].quantity * (cartItems[i].price - (cartItems[i].price - parseFloat(v['value']))));
                            }
                        }
                    }

                    posData = posData.concat([{
                        name: 'Gutschein: ' + v['code'],
                        value: voucherValue,
                        oldValue: null,
                    }]);

                }

                // Add the voucher information to the list of voucher
                voucherValueTotal += voucherValue;
            }

        }

        // Set all the positions (after handling vouchers)
        setPositions(posData);

        // Set the total sum of the cart (inclusive all vouchers)
        setGrandTotal((productsTotal + (shippingCost ? shippingCost : 0) + voucherValueTotal) >= 0 ? (productsTotal + (shippingCost ? shippingCost : 0) + voucherValueTotal) : 0);

    }, [cartItems, productsTotal, vouchers, deliverable_products, shippingCost, country]);

    return { positions, productsTotal, grandTotal, cartItems, handleAddToCart, productIsDeliverable, isVoucherActive }
}