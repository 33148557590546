import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import useTranslator from "../../hooks/useTranslator";

function Unsubscribe({sendMessage}){

    const { t } = useTranslator();

    useEffect(() => {
        if(sendMessage){
            sendMessage({
                type: 'unsubscribe'
            });
        }
    }, []);

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: "#F3F3F3",
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100vh - 418px)",
                paddingTop: 4,
                paddingBottom: 4
            }}
        >
            <Container maxWidth="md">
                <Grid container sx={{pt: 10}} spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h2">
                            {t("Du bist abgemeldet")}
                        </Typography>
                        <Typography sx={{mt: 2}} variant="subtitle1">
                            {t("Schade, dass du gehst. Aber vielleicht ja bis bald!")}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Unsubscribe;