import PropTypes from "prop-types";
import React from "react";
import ProductImages from "./ProductImages";
import ProductDescription from "./ProductDescription";


const ProductSelection = ({ product, sendMessage }) => {

    return (
        <div className='shop-are pt-100 pb-100'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <ProductImages product={product} thumbPosition="left" />
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <ProductDescription sendMessage={sendMessage} product={product} />
                    </div>
                </div>
            </div>
        </div>
    );
};

ProductSelection.propTypes = {
    product: PropTypes.object,
    sendMessage: PropTypes.func
};

export default ProductSelection;