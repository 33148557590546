import React from "react";
import useImageLoader from "../../../omnia/hooks/use-image-loader";

function WishlistImage({image}){

    const { imageSrc } = useImageLoader(image);

    return (
        <div style={{width: 90, height: 90, borderRadius: 5, backgroundImage: 'url(' + imageSrc + ')', backgroundPosition: 'center', backgroundSize: 'cover'}} />
    )
}

export default WishlistImage;