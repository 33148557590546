import React from "react";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router";
import { Heart } from "react-feather";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/system";
import useShopTheme from "../../../hooks/useShopTheme";
import useTranslator from "../../../hooks/useTranslator";

// const useStyles = makeStyles((theme) => ({
//     badge: {
//         backgroundColor: theme.palette.primary.main,
//     }
// }));

function WishlistMenu({themeMode}){

    // const classes = useStyles();
    const { t } = useTranslator();
    const { wishlist, products } = useSelector(state => state.shop);
    const { getTheme } = useShopTheme();
    const theme = useTheme();
    const navigate = useNavigate();

    const goToWishlist = () => {
        navigate('/wishlist');
    }

    return (
        <div style={{width: 35}} className="same-style d-none d-lg-block">
            <Tooltip placement="bottom" title={t("Meine Wunschliste")}>
                <IconButton size="small" onClick={goToWishlist}>
                    <Badge
                        componentsProps={{style: {backgroundColor: theme.palette.primary.main}}}
                        badgeContent={(products.filter(p => wishlist.includes(p.id))).length}
                        color="primary"
                    >
                        <Heart style={getTheme(themeMode)} />
                    </Badge>
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default WishlistMenu;