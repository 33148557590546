import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
    markTranslationSynced,
    resetMissingTranslations,
    resetVouchers,
    saveLanguages,
    saveSetting,
    setAllCountries,
    setCountry,
    setDeliverableCountries,
    setDeliverableProducts,
    updateCart,
    updateScrollPosition,
    updateShippingCost, updateVisitor
} from "./redux/actions/shopActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import produce from "immer";
import Component from "./pages/cms/Component";
import SimplyWarmLayout from "./layouts/SimplyWarmLayout";
import debounce from "lodash.debounce";
import BlogView from "./pages/blog/BlogView";
import ProductView from "./pages/shop/ProductView";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import SimplyCheckout from "./pages/shop/CheckoutView";
import SimplyCart from "./pages/shop/CartView";
import PageNotFound from "./pages/PageNotFound";
import Wishlist from "./pages/shop/WishlistView";
import ThankYou from "./pages/shop/ThankYouView";
import MaintenanceMode from "./pages/MaintenanceMode";
import { changeLanguage } from "redux-multilanguage";
import SimplyPayment from "./pages/shop/CheckoutView/Payment";
import ShopOffline from "./pages/ShopOffline";
import Privacy from "./pages/static/privacy";
import Imprint from "./pages/static/imprint";
import Shop from "./pages/shop/ShopView";
import { useShopFinder } from "./hooks/useShopFinder";
import useTranslator from "./hooks/useTranslator";
import { useCartManager } from "./hooks/useCartManager";
import { useNotifications } from "./omnia/hooks/use-notifications";
import MotivationCoordinator from "./components/motivators/MotivationCoordinator";
import Payments from "./pages/static/payments";
import Delivery from "./pages/static/delivery";
import ReactPixel from 'react-facebook-pixel';
import { useIsBot } from "./omnia/hooks/use-is-bot";
import useApi from "./omnia/hooks/use-api";
import Unsubscribe from "./pages/user/Unsubscribe";
import { useHook } from "./hooks/useHook";

const defaultSiteSetup = {
    background: {type: 'none'},
    preload: 'none',
    menu: {design: 'standard', transparent: false, showTopbar: false},
    footer: 'full'
};

function SiteBuilder(){

    const { url, identifier } = useParams();
    const [pixelActive, setPixelActive] = useState(false);
    const [connectionProblems, setConnectionProblems] = useState(false);
    const [chooseCountry, setChooseCountry] = useState(false);
    const [currentPage, setCurrentPage] = useState(null);
    const [transitionLoading, setTransitionLoading] = useState(true);
    const { cartItems } = useCartManager();
    const { currentLanguageCode } = useSelector(state => state.multilanguage);
    const { country, allowPrivacy, facebook_pixel, maintenance } = useSelector(state => state.shop);
    const { shopUrl } = useShopFinder();
    const { t } = useTranslator();
    const { get } = useApi();
    const [scrolledBuffer, setScrolledBuffer] = useState(0);
    const dispatch = useDispatch();
    const { notify } = useNotifications();
    const [ siteSetup, setSiteSetup ] = useState(defaultSiteSetup);
    const [ carePackageSent, setCarePackageSent ] = useState(false);
    const { sites, initialized, visitor, vouchers, missing_translations, deliverable_products } = useSelector(state => state.shop);
    const siteRef = useRef();
    const countryRef = useRef();

    siteRef.current = currentPage;
    countryRef.current = country;

    const isBot = useIsBot();

    const handleCountry = (data) => {

        let openCountrySetup = false;
        if(!countryRef.current){
            // The visitor did not confirm the country yet
            openCountrySetup = true;
        } else {
            // The visitor did already confirm the country
            if(data){
                if(data.code !== countryRef.current.code)
                    openCountrySetup = true;
            }
        }
        if(openCountrySetup && (siteRef.current.type !== 'checkout'))
            setChooseCountry(true);
    }

    const handleShippingCost = () => {
        sendMessage({
            type: 'getShippingCost'
        })
    }

    const debouncedChangeHandler = useCallback(debounce(handleCountry, 20000), []);

    const debouncedShippingHandler = useCallback(debounce(handleShippingCost, 1000), []);

    const { sendMessage, socketOnline } = useHook('shop/visit/', (message) => {

        // Check if the requesting instance is a bot
        if(!isBot){

            if(message['type'] === 'carePackage'){
                dispatch(changeLanguage(message['data']['wanted_language']));
                storeData(message['data']);
                setTransitionLoading(false);
            }

            if(message['type'] === 'countryUpdate'){
                if(message.data){
                    if(!countryRef.current)
                        dispatch(setCountry(message.data));
                    debouncedChangeHandler(message.data);
                }
            }

            if(message['type'] === 'deliverablesUpdate'){
                dispatch(setDeliverableProducts(message['data']));
            }

            if(message['type'] === 'missingTranslationHandshake'){
                if(typeof missing_translations[message['phrase']] !== "undefined"){
                    dispatch(markTranslationSynced(missing_translations[message['phrase']].value));
                }
            }

            if(message['type'] === 'checkoutLink'){
                if(message['data']['payment_status'] !== 'open'){
                    console.log(message['data']['error']);
                    if(typeof message['data']['cartProblems'] !== "undefined"){
                        const data = message['data']['cartProblems'];
                        for(let i = 0; i < data.length; i++){
                            if(data[i]['error'] === 'maxStock'){
                                let maxStock = parseFloat(data[i]['max']);
                                if(maxStock < 0)
                                    maxStock = 0;
                                dispatch(updateCart(data[i]['variant_id'], maxStock))
                            }
                            if(data[i]['error'] === 'notDeliverable')
                                dispatch(updateCart(data[i]['variant_id'], 0))
                        }
                        notify(t("Einige Artikel sind leider nicht mehr verfügbar. Warenkorb wurde angepasst."), "error");
                    } else {
                        if(message['data']['display_error'] !== null){
                            notify(t(message['data']['display_error']), "error");
                        } else {
                            notify(t("Da hat leider etwas nicht geklappt. Bitte probiere es erneut."), "error");
                        }
                    }
                }

                dispatch(saveSetting(message['data'], 'payment_status'));
                dispatch(saveSetting(message['data'], 'checkout_link'));
            }

            if(message['type'] === 'shippingCostUpdate'){
                dispatch(updateShippingCost(message['cost']));
            }

            if(message['type'] === 'paymentStatusUpdate'){
                dispatch(saveSetting(message['data'], 'payment_status'));
            }

            // TODO: more messages will come here

        }

    }, true);

    const handleEmailUpdate = (email) => {
        sendMessage({
            type: 'emailUpdate',
            email: email,
            allow: email !== null,
        })
        dispatch(updateVisitor(produce(visitor, draft => {
            draft['email'] = email ? email : null;
            draft['allows_contacting'] = email !== null;
            draft['handled_email'] = true;
        })));
    }

    const handleCloseChooseCountry = () => {
        setChooseCountry(false);
    }

    const resetCheckoutStorage = () => {
        dispatch(saveSetting({initialized: null}, 'initialized'));
        dispatch(saveSetting({checkout_link: null}, 'checkout_link'));
        dispatch(saveSetting({payment_status: null}, 'payment_status'));
    }

    const resetVoucherData = () => {
        dispatch(resetVouchers());
    }

    const storeData = (data) => {

        // Load translations of the website
        dispatch(saveLanguages(data['languages']['texts']));

        // Save the default list of deliverable products
        dispatch(setDeliverableProducts(data['deliverable_products']));
        dispatch(setDeliverableCountries(data['deliverable_countries']));

        // Save all countries
        dispatch(setAllCountries(data['countries'].sort((a, b) => a.name > b.name ? 1 : -1)));

        // Save the language setup
        dispatch(saveSetting(data['languages'], 'languages'));

        // Save all settings
        dispatch(saveSetting(data, 'analytics'));
        dispatch(saveSetting(data, 'chat_on'));
        dispatch(saveSetting(data, 'blocked'));
        dispatch(saveSetting(data, 'menus'));
        dispatch(saveSetting(data, 'sites'));
        dispatch(saveSetting(data, 'products'));
        dispatch(saveSetting(data, 'maintenance'));
        dispatch(saveSetting(data, 'connection'));

        // Store additional information
        dispatch(saveSetting(data, 'facebook_pixel'));
        dispatch(saveSetting(data, 'contact_email'));

        // Handle last purchase information
        dispatch(saveSetting(data, 'open_order'));

    }

    const handleScroll = () => {
        setScrolledBuffer(prev => prev + 1);
        dispatch(updateScrollPosition(getScrollPercent()));
    }

    const getScrollPercent = () => {
        // Create helper variables
        let h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        // Compute the percentage of scroll atm
        let scroll = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
        // Return it
        return scroll ? scroll : 0;
    }

    const handleCountryChange = (value) => {
        // tell Omnia that the country has changed
        dispatch(setCountry(value));
    }

    useEffect(() => {
        if(allowPrivacy.includes('usage')){
            if(typeof(missing_translations) !== "undefined"){
                let keys = Object.keys(missing_translations);
                for(let i = 0; i < keys.length; i++){
                    if(!missing_translations[keys[i]].synced){
                        sendMessage({
                            'type': 'missingTranslation',
                            'phrase': missing_translations[keys[i]].value,
                        });
                    }
                }
            } else {
                // Initiate the translations store
                dispatch(resetMissingTranslations());
            }
        }
    }, [missing_translations, socketOnline]);

    useEffect(() => {

        let sendData = false;
        if(scrolledBuffer > 5){
            setScrolledBuffer(0);
            sendData = true;
        }

        if((getScrollPercent() === 100) || (getScrollPercent() === 0)){
            sendData = true;
        }

        if(siteRef.current && sendData){
            if(['cms', 'shop'].includes(siteRef.current.type)){
                if(allowPrivacy.includes('usage'))
                    sendMessage({
                        'type': 'activityUpdate',
                        'method': 'scroll',
                        'content': Math.round(getScrollPercent()),
                        'site_id': siteRef.current.id,
                    });
            }
            if(siteRef.current.type === 'product'){
                if(allowPrivacy.includes('usage'))
                    sendMessage({
                        'type': 'activityUpdate',
                        'method': 'scroll',
                        'content': Math.round(getScrollPercent()),
                        'product_id': siteRef.current.id,
                    });
            }
            // TODO: what about the other page types?
        }

    }, [scrolledBuffer]);

    useEffect(() => {

        if(siteRef.current){
            if((siteRef.current.type === 'cms') && (typeof siteRef.current.setup !== "undefined")){
                // Load selected
                setSiteSetup(JSON.parse(siteRef.current.setup));
            } else {
                // Select default
                setSiteSetup(defaultSiteSetup);
            }
            if(['cms', 'shop'].includes(siteRef.current.type)){
                if(allowPrivacy.includes('usage'))
                    sendMessage({
                        'type': 'activityUpdate',
                        'method': 'scroll',
                        'content': Math.round(getScrollPercent()),
                        'site_id': siteRef.current.id,
                    });
            } else {

                // Check if the current page is cart or checkout
                if(siteRef.current.type === "cart"){
                    if(!country){
                        setChooseCountry(true);
                        debouncedChangeHandler(null);
                    }
                }
                if(siteRef.current.type === "checkout"){
                    if(!country){
                        setChooseCountry(false);
                        debouncedChangeHandler(null);
                    }
                }

                if(siteRef.current.type){
                    if(allowPrivacy.includes('usage'))
                        sendMessage({
                            'type': 'activityUpdate',
                            'method': 'visit',
                            'content': siteRef.current.type,
                        });
                } else {
                    console.log('site type is not known');
                    console.log(siteRef.current);
                }
            }
        }

        if(pixelActive){
            ReactPixel.pageView();
        }

    }, [currentPage, pixelActive]);

    useEffect(() => {
        if(socketOnline){

            // Dismiss the re-connect warning
            setConnectionProblems(false);

            // Handle the care package for the server
            if(!carePackageSent){
                sendMessage({
                    'type': 'carePackage',
                    'data': {
                        'referrer': document.referrer
                    }
                });
                setCarePackageSent(true);
            }

            // In case the connection dies, re-send current vouchers
            sendMessage({
                type: 'voucherUpdate',
                codes: vouchers.map(v => v.code)
            });

        } else {
            setCarePackageSent(false);
            if(!isBot){
                const timer = setTimeout(() => {
                    setConnectionProblems(true);
                }, 2500);
                return () => clearTimeout(timer);
            }
        }
    }, [socketOnline]);

    useEffect(() => {
        if(currentLanguageCode){
            sendMessage({
                'type': 'languageUpdate',
                'lan': currentLanguageCode,
            });
        }
    }, [currentLanguageCode]);

    useEffect(() => {
        if(country){
            debouncedShippingHandler();
            sendMessage({
                'type': 'countryUpdate',
                'code': country.code,
            });
        }
    }, [country]);

    useEffect(() => {
        if(url && sites.filter(s => s.url === url).length === 1){
            setCurrentPage(produce(sites.find(s => s.url === url), draft => {
                draft.elements = draft.elements.sort((a, b) => a.position > b.position ? 1 : -1);
            }));
        } else if((typeof url === "undefined") && sites.filter(s => s.is_landing).length === 1) {
            setCurrentPage(produce(sites.find(s => s.is_landing), draft => {
                draft.elements = draft.elements.sort((a, b) => a.position > b.position ? 1 : -1);
            }));
        } else if(url && (url === 'product')){
            setCurrentPage({
                type: 'product',
                id: parseInt(identifier)
            });
        } else if(url && (url === 'wishlist')){
            setCurrentPage({
                type: 'wishlist'
            });
        } else if(url && (url === 'cart')){
            setCurrentPage({
                type: 'cart'
            });
        } else if(url && (url === 'thankyou')){
            setCurrentPage({
                type: 'thankyou'
            });
        } else if(url && (url === 'checkout')) {
            setCurrentPage({
                type: 'checkout'
            });
        }  else if(url && (url === 'unsubscribe')) {
            setCurrentPage({
                type: 'unsubscribe'
            });
        }  else if(url && (url === 'pay')) {
            setCurrentPage({
                type: 'pay'
            });
        }  else if(url && (url === 'privacy')) {
            setCurrentPage({
                type: 'privacy'
            });
        }  else if(url && (url === 'imprint')) {
            setCurrentPage({
                type: 'imprint'
            });
        } else if(url && (url === 'delivery')) {
            setCurrentPage({
                type: 'delivery'
            });
        }  else if(url && (url === 'payments')) {
            setCurrentPage({
                type: 'payments'
            });
        } else {
            setCurrentPage(null);
        }
    }, [sites, url]);

    useEffect(() => {
        debouncedShippingHandler();
        sendMessage({
            'type': 'orderUpdate',
            'cart': cartItems.map(item => {
                return {
                    'variant': item.id,
                    'quantity': item.quantity
                };
            })
        })
    }, [cartItems, deliverable_products]);

    useEffect(() => {
        debouncedShippingHandler();
        sendMessage({
            type: 'voucherUpdate',
            codes: vouchers.map(v => v.code)
        });
    }, [vouchers]);

    useEffect(() => {
        setTransitionLoading(true);
        const timer = setTimeout(() => {
            setTransitionLoading(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [url]);

    useEffect(() => {

        // Only do this when no bot is active
        if(!isBot){
            // Check whether the facebook pixel is given
            if(facebook_pixel && facebook_pixel !== ""){

                // Check whether the user has allowed facebook
                if(allowPrivacy.includes('fb')){

                    // Enable facebook
                    const options = { autoConfig: true, debug: false };
                    ReactPixel.init(facebook_pixel, null, options);
                    ReactPixel.grantConsent();
                    setPixelActive(true);

                } else {

                    // Check if the pixel is active and deactivate it
                    if(pixelActive){
                        ReactPixel.revokeConsent();
                    }

                }

            }
        }

    }, [isBot, facebook_pixel, allowPrivacy]);

    useEffect(() => {
        resetCheckoutStorage();
        resetVoucherData();

        if(isBot){
            get('shopper/care_package', {is_bot: isBot}).then(message => {
                dispatch(changeLanguage(message['data']['wanted_language']));
                storeData(message['data']);
                setTransitionLoading(false);
            })
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    if(connectionProblems)
        return (
            <ShopOffline />
        )

    if(!initialized || transitionLoading || (typeof siteSetup.menu === "undefined"))
        return (
            <div style={{height: '100vh', display: 'flex',  justifyContent:'center', alignItems:'center', backgroundColor: '#ffffff'}}>
                <CircularProgress />
            </div>
        )

    if(maintenance)
        return (
            <MaintenanceMode />
        )

    if(!currentPage)
        return <PageNotFound siteSetup={siteSetup} sendMessage={sendMessage} />;

    return (
        <Fragment>
            {/*{currentPage.type === 'cms' ? (*/}
            {/*    <MetaTags>*/}
            {/*        <title>{t(currentPage.title)}</title>*/}
            {/*        <meta name='description' content={currentPage.description} />*/}
            {/*        <meta name='keywords' content={currentPage.content} />*/}
            {/*    </MetaTags>*/}
            {/*) : (*/}
            {/*    <MetaTags>*/}
            {/*        <title>{t("SIMPLY WARM | Wohltuende Wärme für Dein Pferd")}</title>*/}
            {/*    </MetaTags>*/}
            {/*)}*/}
            <MotivationCoordinator
                countryOpen={chooseCountry}
                onEmailUpdate={handleEmailUpdate}
                onCloseCountry={handleCloseChooseCountry}
                onSetCountry={handleCountryChange}
            />
            <>
                {['shop', 'product', 'checkout', 'cart', 'wishlist'].includes(currentPage.type) && (
                    <>
                        <BreadcrumbsItem to='/'>Simpy Warm</BreadcrumbsItem>
                        <BreadcrumbsItem to={shopUrl}>
                            {t("Shop")}
                        </BreadcrumbsItem>
                        {currentPage.type === 'product' && (
                            <BreadcrumbsItem to={shopUrl + '/product'}>
                                {t("Produkt")}
                            </BreadcrumbsItem>
                        )}
                        {currentPage.type === 'checkout' && (
                            <BreadcrumbsItem to={shopUrl + '/cart/checkout'}>
                                {t("Checkout")}
                            </BreadcrumbsItem>
                        )}
                        {currentPage.type === 'cart' && (
                            <BreadcrumbsItem to={shopUrl + '/cart'}>
                                {t("CartView")}
                            </BreadcrumbsItem>
                        )}
                        {currentPage.type === 'wishlist' && (
                            <BreadcrumbsItem to={shopUrl + '/wishlist'}>
                                {t("Wunschliste")}
                            </BreadcrumbsItem>
                        )}
                    </>
                )}
            </>
            <SimplyWarmLayout
                stickyNav={siteSetup.menu.stickyNavbar}
                sendMessage={sendMessage}
                siteSetup={siteSetup}
                navbar={siteSetup.menu.design}
                menuTheme={siteSetup.menu.theme}
                headerTransparent={siteSetup.menu.transparent}
                showTopbar={siteSetup.menu.showTopbar}
                footer={currentPage.type === 'checkout' ? {} : siteSetup.footer}
            >

                {/*  CMS System   */}

                {currentPage.type === 'cms' && (
                    <>
                        {currentPage.elements.map((element, index) => (
                            <Component component={element} sendMessage={sendMessage} key={'component-' + index} />
                        ))}
                    </>
                )}

                {/*  Static ShopView Pages  */}

                {currentPage.type === 'shop' && (
                    // <ShopView sendMessage={sendMessage} />
                    <Shop sendMessage={sendMessage} />
                )}

                {currentPage.type === 'thankyou' && (
                    <ThankYou sendMessage={sendMessage} />
                )}

                {currentPage.type === 'product' && (
                    <ProductView sendMessage={sendMessage} />
                )}

                {currentPage.type === 'wishlist' && (
                    <Wishlist />
                )}

                {currentPage.type === 'cart' && (
                    <SimplyCart />
                )}

                {currentPage.type === 'checkout' && (
                    <SimplyCheckout sendMessage={sendMessage} onSetCountry={handleCountryChange} />
                )}

                {currentPage.type === 'pay' && (
                    <SimplyPayment sendMessage={sendMessage} />
                )}

                {/*  Static Blog Pages  */}

                {currentPage.type === 'blog' && (
                    <BlogView />
                )}

                {currentPage.type === 'unsubscribe' && (
                    <Unsubscribe sendMessage={sendMessage} />
                )}

                {/* STATIC PAGES */}

                {/* TODO: account pages will follow soon */}

                {currentPage.type === 'privacy' && (
                    <Privacy />
                )}
                {currentPage.type === 'imprint' && (
                    <Imprint />
                )}
                {currentPage.type === 'payments' && (
                    <Payments />
                )}
                {currentPage.type === 'delivery' && (
                    <Delivery />
                )}

            </SimplyWarmLayout>
        </Fragment>
    );

}

export default SiteBuilder;