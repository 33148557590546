export const createTypography = (config) => {

    let headlineFont = config?.headlineFont || 'Roboto';
    let bodyFont = config?.bodyFont || 'Roboto';
    bodyFont = bodyFont + ', -apple-system, BlinkMacSystemFont, \'Segoe UI\', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"';

    return {
        fontFamily: bodyFont,
        body1: {
            fontSize: config?.body1Size || '1rem',
            fontWeight: config?.body1FontWeight || 400,
            lineHeight: config?.body1LineHeight || 1.5
        },
        body2: {
            fontSize: config?.body2Size || '0.875rem',
            fontWeight: config?.body2FontWeight || 400,
            lineHeight: config?.body2LineHeight || 1.57
        },
        button: {
            fontWeight: 600
        },
        caption: {
            fontSize: config?.captionSize || '0.75rem',
            fontWeight: config?.captionFontWeight || 500,
            lineHeight: config?.captionLineHeight || 1.66
        },
        subtitle1: {
            fontSize: config?.subtitle1Size || '1rem',
            fontWeight: config?.subtitle1FontWeight || 500,
            lineHeight: config?.subtitle1LineHeight || 1.57
        },
        subtitle2: {
            fontSize: config?.subtitle2Size || '0.875rem',
            fontWeight: config?.subtitle2FontWeight || 500,
            lineHeight: config?.subtitle2LineHeight || 1.57
        },
        overline: {
            fontSize: config?.overlineSize || '0.75rem',
            fontWeight: config?.overlineFontWeight || 600,
            letterSpacing: '0.5px',
            lineHeight: config?.overlineLineHeight || 2.5,
            textTransform: 'uppercase'
        },
        h1: {
            fontFamily: headlineFont,
            fontWeight: config?.h1FontWeight || 700,
            fontSize: config?.h1Size || '3.5rem',
            lineHeight: config?.h1LineHeight || 1.2
        },
        h2: {
            fontFamily: headlineFont,
            fontWeight: config?.h2FontWeight || 700,
            fontSize: config?.h2Size || '3rem',
            lineHeight: config?.h2LineHeight || 1.2
        },
        h3: {
            fontFamily: headlineFont,
            fontWeight: config?.h3FontWeight || 700,
            fontSize: config?.h3Size || '2.25rem',
            lineHeight: config?.h3LineHeight || 1.2
        },
        h4: {
            fontFamily: headlineFont,
            fontWeight: config?.h4FontWeight || 700,
            fontSize: config?.h4Size || '2rem',
            lineHeight: config?.h4LineHeight || 1.2
        },
        h5: {
            fontFamily: headlineFont,
            fontWeight: config?.h5FontWeight || 700,
            fontSize: config?.h5Size || '1.5rem',
            lineHeight: config?.h5LineHeight || 1.2
        },
        h6: {
            fontFamily: headlineFont,
            fontWeight: config?.h6FontWeight || 700,
            fontSize: config?.h6Size || '1.125rem',
            lineHeight: config?.h6LineHeight || 1.2
        }
    };
};
