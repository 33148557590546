import React from "react";
import { Grid, IconButton } from "@mui/material";
import { Facebook, Linkedin, Mail, Twitter } from "react-feather";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";
import { useParams } from "react-router-dom";

function SocialShare({...rest}){

    const { identifier } = useParams();
    const shareUrl = 'https://simply-warm.com/product/' + identifier;

    return (
        <Grid container spacing={1} {...rest}>
            <Grid item xs={12}>
                <EmailShareButton url={shareUrl}>
                    <IconButton sx={{mr: 1, mb: 1}}>
                        <Mail />
                    </IconButton>
                </EmailShareButton>
                <FacebookShareButton url={shareUrl}>
                    <IconButton sx={{mr: 1, mb: 1}}>
                        <Facebook />
                    </IconButton>
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl}>
                    <IconButton sx={{mr: 1, mb: 1}}>
                        <Twitter />
                    </IconButton>
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl}>
                    <IconButton sx={{mr: 1, mb: 1}}>
                        <Linkedin />
                    </IconButton>
                </LinkedinShareButton>
            </Grid>
        </Grid>
    )

}

export default SocialShare;