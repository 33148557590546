import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

export const useShopFinder = () => {

    const { sites } = useSelector(state => state.shop);
    const [shopUrl, setShopUrl] = useState("/");
    const [shopPageExists, setShopPageExists] = useState(false);

    useEffect(() => {
        let shopPage = sites.filter(s => s.type === 'shop').length > 0 ? sites.find(s => s.type === 'shop') : null;
        if(shopPage){
            setShopPageExists(true);
            setShopUrl('/' + shopPage.url);
        } else {
            setShopPageExists(false);
            setShopUrl('/');
        }
    }, [sites]);

    return { shopUrl, shopPageExists }
}