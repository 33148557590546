import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Divider, Grid, Typography } from "@mui/material";
import useStyleBuilder from "../../../hooks/useStyleBuilder";
import { useIsMobile } from "../../../omnia/hooks/use-is-mobile";
import { useTheme } from "@mui/system";

function Text({ component, texts, setup, images, sendMessage, t }) {

    const { getTextStyles } = useStyleBuilder();
    const theme = useTheme();
    const [headline, setTitle] = useState(null);
    const [topline, setTopline] = useState(null);
    const [body, setBody] = useState(null);
    const { isMobile } = useIsMobile();

    useEffect(() => {
        let hl, to, bo;
        hl = (component.texts.filter(t => t.identifier === "headline").length > 0) ? component.texts.find(t => t.identifier === "headline") : null;
        to = (component.texts.filter(t => t.identifier === "topline").length > 0) ? component.texts.find(t => t.identifier === "topline") : null;
        bo = (component.texts.filter(t => t.identifier === "body").length > 0) ? component.texts.find(t => t.identifier === "body") : null;
        setTitle(hl);
        setTopline(to);
        setBody(bo);
    }, [component]);

    return (
        <Grid container spacing={3}>
            {topline && (
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Typography variant="caption" style={getTextStyles(topline.styles, { textAlign: "center" })}>
                        {t(topline.value)}
                    </Typography>
                    {/*<Divider />*/}
                </Grid>
            )}
            {headline && (
                <Grid item xs={12}>
                    <Typography variant="h1" style={getTextStyles(headline.styles, { textAlign: "center" })}>
                        {t(headline.value)}
                    </Typography>
                </Grid>
            )}
            {body && (
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography variant="subtitle1" style={getTextStyles(body.styles, {})}>
                        {t(body.value)}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

Text.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func
};

export default Text;