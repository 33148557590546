import React, { Fragment } from "react";
// import MetaTags from "react-meta-tags";
import useTranslator from "../hooks/useTranslator";
import { CircularProgress, Typography } from "@mui/material";
import Logo from "../components/header/Logo";

function ShopOffline(){

    const {t} = useTranslator();

    return (
        <Fragment>
            {/*<MetaTags>*/}
            {/*    <title>Simply Warm | Offline</title>*/}
            {/*    <meta name="Simlpy Warm" content="500,simply-warm,offline" />*/}
            {/*</MetaTags>*/}
            <div className="error-area pt-80 pb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8 text-center">
                            <div className="error" style={{paddingTop: '20vh'}}>
                                <Logo height={60} style={{marginBottom: 40}} theme='light' />
                                <h2>{t('Verbindung herstellen')}</h2>
                                <Typography variant="body1" color="textSecondary">
                                    {t('Es scheint Probleme mit der Verbindung zu geben')}
                                </Typography>
                                <div style={{height: 150, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                    <CircularProgress />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ShopOffline;