import React, { useRef, useState } from "react";
import { Settings } from "react-feather";
import {
    IconButton,
    Popover,
    Tooltip,
    TextField,
    Box,
    Typography,
} from "@mui/material";
import useTranslator from "../../../hooks/useTranslator";
import useShopTheme from "../../../hooks/useShopTheme";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "redux-multilanguage";
import { addPrivacySetting, removePrivacySetting } from "../../../redux/actions/shopActions";
import PrivacyDialogOptions from "../../PrivacyDialogOptions";
import CountrySelector from "../CountrySelector";

function LanguagesDropdown({themeMode}){

    const [userOpen, setUserOpen] = useState(false);
    const userPopover = useRef();
    const { t } = useTranslator();
    const dispatch = useDispatch();
    const { languages, allowPrivacy } = useSelector(state => state.shop);
    const { currentLanguageCode } = useSelector(state => state.multilanguage);
    const { getTheme } = useShopTheme();

    const handleLanguageChange = (ident) => {
        dispatch(changeLanguage(ident));
    }

    const handleOpenPopover = () => {
        setUserOpen(true);
    }

    const handleClosePopover = () => {
        setUserOpen(false);
    }

    const handleChange = (event) => {
        if(!allowPrivacy.includes(event.target.name)){
            dispatch(addPrivacySetting(event.target.name));
        } else {
            dispatch(removePrivacySetting(event.target.name));
        }
    }

    return (
        <>
            <div style={{width: 35}} className="same-style d-none d-lg-block">
                <Tooltip title={t("Einstellungen")}>
                    <IconButton
                        size="small"
                        ref={userPopover}
                        onClick={handleOpenPopover}
                    >
                        <Settings style={getTheme(themeMode)} />
                    </IconButton>
                </Tooltip>
            </div>
            <Popover
                open={userOpen}
                onClose={handleClosePopover}
                anchorEl={userPopover.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2}>
                    <Typography variant="h4" style={{fontSize: 20, marginBottom: 25}} color="textPrimary">
                        {t("Einstellungen")}
                    </Typography>
                    <Box mt={2}>
                        <TextField
                            fullWidth
                            label={t("Sprache")}
                            name="language"
                            onChange={(event) => handleLanguageChange(event.target.value)}
                            select
                            SelectProps={{native: true}}
                            value={currentLanguageCode}

                        >
                            {languages.map(lan => (
                                <option
                                    key={lan.ident}
                                    value={lan.ident}
                                >
                                    {t(lan.name)}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                    <Box mt={2}>
                        <CountrySelector />
                    </Box>
                    <Box mt={2}>
                        <Typography variant="h4" style={{fontSize: 20, marginTop: 25, marginBottom: 8}} color="textPrimary">
                            {t("Datenschutz")}
                        </Typography>
                        <PrivacyDialogOptions
                            sx={{maxWidth: 400}}
                            handleChange={handleChange}
                            allowPrivacy={allowPrivacy}
                        />
                    </Box>
                </Box>
            </Popover>
        </>
    )
}

export default LanguagesDropdown;