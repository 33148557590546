import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import useTranslator from "../../hooks/useTranslator";

function Delivery({sendMessage}){

    const { t } = useTranslator();

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: "#F3F3F3",
                display: "flex",
                flexDirection: "column",
                minHeight: "calc(100vh - 300px)",
                paddingTop: 4,
                paddingBottom: 4
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <Typography color="textPrimary" variant="h2">
                            {t("Versand und Lieferung")}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{mt: 8}}>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Die auf den Produktseiten genannten Preise enthalten die gesetzliche Umsatzsteuer und alle sonstige Preisbestandteile. Angaben über die Lieferfrist sind unverbindlich, soweit nicht der Liefertermin verbindlich zugesagt wurde. Wir behalten uns vor, vom Vertrag zurückzutreten, wenn die bestellte Ware bei uns nicht mehr verfügbar sein sollte.")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Falls der Artikel nicht vorrätig sein sollte, wirst du per Mail über die Lieferzeit von uns informiert.")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Die Lieferung erfolgt an die von dir im Rahmen der Bestellung angegebene Lieferadresse. Lieferungen erfolgen erst nach Erhalt des Kaufpreises oder bestätigter Zahlungsanweisung über Vorkasse, Überweisung, PayPal, SOFORT Überweisung, Kreditkarte, Apple Pay und Klarna.")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Wir liefern zur Zeit nach Deutschland, Österreich, Niederlande, Dänemark. Zusätzlich zu den angegebenen Preisen berechnen wir für die Lieferung innerhalb Deutschlands zwischen 5,00 EUR und 9,50 EUR und die weiteren aufgezählten Länder pauschal 18,00 Euro pro Bestellung. Die Versandkosten werden dir im Warenkorbsystem und auf der Bestellseite nochmals deutlich mitgeteilt.")}
                        </Typography>
                        <Typography color="textPrimary" variant="body1" sx={{mb: 2, lineHeight: 1.8}}>
                            {t("Eine Lieferung in andere Länder ist möglich, schreib uns bitte kurz dazu an, dann können wir dir die genauen Lieferkosten dafür mitteilen.")}
                        </Typography>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    )
}

export default Delivery;