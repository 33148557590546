import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import useTranslator from "../../hooks/useTranslator";
import {
    Box,
    Grid,
    Container,
    Typography,
    Link,
    IconButton,
    Divider
} from "@mui/material";
import { Facebook, Instagram, Email } from "@mui/icons-material";
import useDimensions from "../../omnia/hooks/use-dimensions";
import { styled } from '@mui/material/styles';
import PlusIcon from '@mui/icons-material/Add';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useSelector } from "react-redux";
import moment from "moment";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    // border: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#171717',
    border: 'none',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<PlusIcon sx={{ color: 'white', fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    // backgroundColor:
    //     theme.palette.mode === 'dark'
    //         ? 'rgba(255, 255, 255, .05)'
    //         : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => {
    return ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    });
});

const Footer = ({footer, sendMessage}) => {

    const { t } = useTranslator();
    const { openMotivations } = useSelector(state => state.shop);
    const [checkButtonActive, setCheckoutButton] = useState(false);
    const [simplyLinks, setLinks] = useState([]);
    const [shopLink, setShop] = useState('/');
    const [expanded, setExpanded] = useState(null);
    const { width } = useDimensions();
    const { sites } = useSelector(state => state.shop);
    const navigate = useNavigate();

    const footerContent = [
        {
            name: 'Hilfe',
            links: [
                {name: 'support@simply-warm.com', link: 'mailto:support@simply-warm.com'},
                {name: 'Kontakt', link: 'mailto:support@simply-warm.com'},
                {name: 'Retoure', link: 'doc::/assets/docs/retoure.pdf'}
            ]
        },
        {
            name: 'Shop',
            links: [
                {name: 'Der Shop', link: shopLink},
                {name: 'Versand und Lieferung', link: '/delivery'},
                {name: 'Zahlungsweisen', link: '/payments'},
            ]
        },
        {
            name: 'Simply Warm',
            links: simplyLinks
        },
        {
            name: 'Company',
            links: [
                {name: 'Datenschutzerklärung', link: 'doc::/assets/docs/data_protection.pdf'},
                {name: 'AGB', link: 'doc::/assets/docs/agb.pdf'},
                {name: 'Widerruf', link: 'doc::/assets/docs/cancellation_policy.pdf'},
                {name: 'Impressum', link: '/imprint'},
            ]
        },
    ];

    const moveTo = (link, newTarget) => {

        if(link.includes('doc::')){
            window.open(link.substr(5, link.length - 1), '_blank').focus();
        } else {
            if(link.substr(0, 1) !== "/"){
                if(typeof newTarget !== "undefined" && newTarget) {
                    window.open(link, '_blank').focus();
                } else {
                    window.location.href = link;
                }
            } else {
                navigate(link);
            }
        }

    }

    const handleChange = (panel) => {
        setExpanded(prev => prev ? (prev === panel ? null : panel) : panel);
    }

    useEffect(() => {
        if(typeof openMotivations !== "undefined"){
            if(openMotivations.includes('checkoutButton')){
                setCheckoutButton(true);
            } else {
                setCheckoutButton(false);
            }
        } else {
            setCheckoutButton(false);
        }
    }, [openMotivations]);

    useEffect(() => {
        if(sites.filter(s => s.type === 'shop').length > 0){
            setShop('/' + sites.filter(s => s.type === 'shop')[0].url);
        } else {
            setShop('/');
        }
        setLinks(sites.filter(s => !s.is_landing && s.type === 'cms').map(s => {
            return {
                name: s.title,
                link: '/' + s.url
            }
        }));
    }, [sites]);

    return (
        <Box sx={{minHeight: 100, backgroundColor: '#171717'}}>
            <Divider />
            <Container maxWidth="lg" sx={{pb: (checkButtonActive && (width <= 600)) ? 10 : 2, pt: (width <= 600) ? 2 : 4}}>
                {width <= 600 ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {footerContent.map((column, i) => (
                                <div key={'accord-' + i}>
                                    <Accordion expanded={expanded === column.name} onChange={() => handleChange(column.name)}>
                                        <AccordionSummary aria-controls={"panel" + i + "d-content"} id={"panel" + i + "d-header"}>
                                            <Typography textTransform="uppercase" color="textPrimary">
                                                {t(column.name)}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={1}>
                                                {column.links.map((link, j) => (
                                                    <Grid key={'col-' + i + '-link-' + j} item xs={12}>
                                                        <Link underline="hover" onClick={() => moveTo(link.link)} color="textPrimary" sx={{pl: 2.7}}>
                                                            {t(link.name)}
                                                        </Link>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Divider />
                                </div>
                            ))}
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container spacing={2}>
                        {footerContent.map((column, i) => (
                            <Grid key={'element-' + i} item xs={12} sm={3}>
                                <Typography variant="h6" style={{fontSize: 14}} color="textPrimary" textTransform="uppercase" sx={{mb: 4}}>
                                    {t(column.name)}
                                </Typography>
                                <Box mt={2}>
                                    <Grid container spacing={1}>
                                        {column.links.map((link, j) => (
                                            <Grid key={'container-item-' + i + '-' + j} item xs={12}>
                                                <Link color="textPrimary" underline="hover" to="#" key={'link-bottom-' + i + '-' + j} onClick={() => moveTo(link.link)}>
                                                    {t(link.name)}
                                                </Link>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                )}
                {(width > 600) ? (
                    <Box mt={8}>
                        <Divider />
                    </Box>
                ) : (
                    <Box sx={{mt: 4, display: 'flex', justifyContent: 'center'}}>
                        <IconButton onClick={() => moveTo('https://www.facebook.com/simply.warm1/', true)}>
                            <Facebook color="white" fontSize="large" />
                        </IconButton>
                        <IconButton onClick={() => moveTo('https://www.instagram.com/', true)}>
                            <Instagram color="white" fontSize="large" />
                        </IconButton>
                        <IconButton onClick={() => moveTo('mailto:post@simply-warm.com', true)}>
                            <Email color="white" fontSize="large" />
                        </IconButton>
                    </Box>
                )}
                <Box style={{ display: (width <= 600 ? 'inline' : 'flex'), justifyContent: 'space-between', textAlign: (width <= 600 ? 'center' : 'left')}}>
                    <Box mt={width > 600 ? 2 : 4}>
                        <Typography variant="caption" sx={{textTransform: 'uppercase'}} color="textSecondary">
                            © {moment(new Date()).format('YYYY')} {t("Simply Warm UG")}
                        </Typography>
                    </Box>
                    {(width > 600) && (
                        <>
                            <Box flexGrow={1} />
                            <Box mt={width > 600 ? 1 : 3}>
                                <IconButton onClick={() => moveTo('https://www.facebook.com/simply.warm1/', true)}>
                                    <Facebook />
                                </IconButton>
                                <IconButton>
                                    <Instagram onClick={() => moveTo('https://www.instagram.com/', true)} />
                                </IconButton>
                                <IconButton onClick={() => moveTo('mailto:support@simply-warm.com', true)}>
                                    <Email />
                                </IconButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

Footer.propTypes = {
    footer: PropTypes.string,
};

Footer.defaultProps = {
    footer: 'full'
}

export default Footer;
