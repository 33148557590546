import React, {useCallback, useEffect, useState, useMemo, useRef} from "react";
import { isDesktop } from "react-device-detect";
import { toast } from "react-hot-toast";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";

export const useNotifications = () => {
    const theme = useTheme();
    const [notifications, setNotifications] = useState([]);
    const [screenIsVisible, setScreenIsVisible] = useState(true);
    const themeRef = useRef();
    themeRef.current = theme;

    const closeAllNotifications = useCallback(() => {
        if (isScreenVisible() && desktopNotificationsAllowed()) {
            notifications.forEach((notification) => notification.close());
            setNotifications([]);
        }
    }, [notifications]);

    const desktopNotificationsAllowed = useCallback(() => {
        if (!isDesktop) return false;
        if (typeof window === "undefined") return false;
        if (!("Notification" in window)) return false;

        if (Notification.permission === "granted") {
            return true;
        } else {
            if (Notification.permission === "default") Notification.requestPermission();
            return false;
        }
    }, []);

    const isScreenVisible = useCallback(() => {
        return document.visibilityState === "visible";
    }, []);

    const flashDesktopNotification = useCallback((message, title) => {
        if (desktopNotificationsAllowed()) {
            const tmp = new Notification(title, {
                title,
                body: message,
                icon: "/favicon.ico",
                dir: "ltr",
            });
            setNotifications((prev) => prev.concat([tmp]));
            document.addEventListener("visibilitychange", closeAllNotifications);
            tmp.addEventListener('close', () => {
                document.removeEventListener("visibilitychange", closeAllNotifications);
            });
        }
    }, [desktopNotificationsAllowed, closeAllNotifications]);

    const notify = useCallback(
        (message, variant = "default", asDesktop = false, duration = 3000) => {
            if (!isScreenVisible() && asDesktop && desktopNotificationsAllowed())
                flashDesktopNotification(message, "On");

            const commonProps = {
                duration,
                style: { backgroundColor: themeRef.current.palette.background.default },
            };

            const content = (
                <Typography variant="body1" color={themeRef.current.palette.text.primary}>
                    {message}
                </Typography>
            );

            if (variant === "success") {
                toast.success(content, commonProps);
            } else if (variant === "error") {
                toast.error(content, commonProps);
            } else {
                toast(content, commonProps);
            }
        },
        [isScreenVisible, desktopNotificationsAllowed, flashDesktopNotification]
    );

    const flashStatusChange = useCallback(
        (message) => {
            toast(
                <Typography variant="body1" color={themeRef.current.palette.text.primary}>
                    {message}
                </Typography>,
                {
                    style: {
                        backgroundColor: themeRef.current.palette.background.default,
                    },
                }
            );
        },
        []
    );

    useEffect(() => {
        const onVisibilityChange = () => {
            const isVisible = document.visibilityState === "visible";
            if (screenIsVisible !== isVisible) setScreenIsVisible(isVisible);
        };
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange);
        };
    }, [screenIsVisible]);

    return {
        screenIsVisible,
        notify,
        flashStatusChange,
        flashDesktopNotification,
        isScreenVisible,
    };
};
