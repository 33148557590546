import {useTheme} from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

export const useIsMobile = () => {
    const theme = useTheme();

    // Mobile screen sizes are typically below 'sm'
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Tablet screen sizes are typically between 'sm' and 'md'
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    // Desktop screen sizes are typically above 'md'
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return { isMobile, isTablet, isDesktop }
}