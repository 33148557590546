import React from "react";

const useComponentImage = () => {

    const getImage = (images, id, vertical=false) => {

        if(id){
            if(images.filter(i => i.id === id).length === 1)
                return images.find(i => i.id === id).view;
        }

        return "/assets/img/slider/slider-28.jpg";
    }

    return { getImage };
}

export default useComponentImage;