import React from "react";
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import useTranslator from "src/hooks/useTranslator";
import {Grid as GridIcon, List as TableIcon} from "react-feather";

function ShopActions({sendMessage, sort, view, onViewChange, onSortChange}){

    const { t } = useTranslator();

    const handleChangeSorting = (event) => {
        onSortChange(event.target.value);
    }

    const handleView = (event, newView) => {
        onViewChange(newView);
    }

    return (
        <Grid container spacing={3} pt={7} pb={4}>
            <Grid item xs={12} sm={6} md={4}>
                <TextField
                    select
                    fullWidth
                    size="medium"
                    onChange={handleChangeSorting}
                    labelId="sort-dropdown"
                    value={sort}
                    id="demo-simple-select-outlined"
                    label={t("Sortieren")}
                >
                    <MenuItem value={'new'}>{t("Neue Produkte zuerst")}</MenuItem>
                    <MenuItem value={'price_asc'}>{t("Preis aufsteigend")}</MenuItem>
                    <MenuItem value={'price_desc'}>{t("Preis absteigend")}</MenuItem>
                </TextField>
            </Grid>
        </Grid>
    )
}

export default ShopActions;