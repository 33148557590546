import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";
import shopReducer from "./shopReducer";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "lan_1" }),
  shop: shopReducer,
});

export default rootReducer;
