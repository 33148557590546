import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Logo from "src/components/header/Logo";
import { Box, Typography } from "@mui/material";
import useTranslator from "../../../hooks/useTranslator";
import useApi from "../../../omnia/hooks/use-api";
import { useSelector } from "react-redux";
import { useNotifications } from "../../../omnia/hooks/use-notifications";
import { useNavigate } from "react-router";

function SimplyPayment({sendMessage}){

    const { identifier } = useParams();
    const { t } = useTranslator();
    const { notify } = useNotifications();
    const { post } = useApi(false, (error) => {
        if(error === "Not found"){
            notify(t("Der Link ist nicht mehr gültig"), "error");
        } else {
            notify(t("Es ist ein Fehler aufgetreten"), "error");
        }
        navigate('/');
    });
    const navigate = useNavigate();

    useEffect(() => {
        if(identifier){
            post('shopper/pay', {id: identifier})
                .then(data => {
                    if(data['has_error']){
                        notify("Etwas ist schief gelaufen", "error");
                        console.log(data['error']);
                        navigate('/');
                    } else {
                        setTimeout(() => {
                            window.location.href = data['checkout_link'];
                        }, 2000);
                    }
                });
        }
    }, [identifier]);

    return (
        <>
            <div style={{
                height: 150,
                marginTop: 100,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Logo theme="light" height={70} />
            </div>
            <div style={{
                height: 200,
                marginBottom: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Box>
                    <Typography variant="h2" textAlign="center" style={{ marginBottom: 15 }}>
                        {t("Weiterleitung")}
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 20 }} textAlign="center">
                        {t("Du wirst jetzt gleich zum Bezahlen weitergeleitet ...")}
                    </Typography>
                </Box>
            </div>
        </>
    )
}

SimplyPayment.propTypes = {
    sendMessage: PropTypes.func
}

export default SimplyPayment;