import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import useStyleBuilder from "../../../hooks/useStyleBuilder";
import { useSelector } from "react-redux";

function ActionButton({component, texts, setup, sendMessage, buttonId, t, ...rest}){

    const [buttonText, setButtonText] = useState(null);
    const [buttonStyle, setButtonStyle] = useState(null);
    const [style, setStyle] = useState("contained");
    const [color, setColor] = useState("primary");
    const { allowPrivacy } = useSelector(state => state.shop);
    const [suffix, setSuffix] = useState("");
    const { getTextStyles } = useStyleBuilder();
    const navigate = useNavigate();

    const handleClick = () => {
        let url = setup['misc']['buttonLink' + suffix];
        if(allowPrivacy.includes('usage')){
            sendMessage({
                'type': 'activityUpdate',
                'method': 'button click',
                'content': url,
                'site_id': component.site,
            });
        }
        if(url.includes('def:custom')){
            window.location.href = url.substr(10, url.length);
        } else if(url.includes('def:')) {
            let identifier = url.split(':')[1];
            navigate(identifier);
        } else {
            navigate(url);
        }
    }

    useEffect(() => {
        setSuffix(buttonId === "" ? "" : "-" + buttonId)
    }, [buttonId]);

    useEffect(() => {
        if(typeof setup['misc'] !== "undefined"){

            if(typeof setup['misc']['buttonColor' + suffix] !== "undefined"){
                setColor(setup['misc']['buttonColor' + suffix]);
            } else {
                setColor("primary");
            }

            setStyle(setup['misc']['buttonStyle' + suffix]);
        }
        if(component.texts.filter(t => t.identifier === ("buttonText" + suffix)).length > 0){
            setButtonText(component.texts.find(t => t.identifier === ("buttonText" + suffix)).value);
            setButtonStyle(component.texts.find(t => t.identifier === ("buttonText" + suffix)).value);
        }
    }, [component, suffix, setup]);

    if(!buttonText)
        return null;

    return (
        <Grid container spacing={3} style={{textAlign: 'center'}} {...rest}>
            <Grid item xs={12}>
                <Button
                    style={getTextStyles(buttonStyle)}
                    variant={style}
                    size="medium"
                    onClick={handleClick}
                    color={color}
                >
                    {t(buttonText)}
                </Button>
            </Grid>
        </Grid>
    );
}

ActionButton.propTypes = {
    component: PropTypes.object,
    buttonId: PropTypes.string,
    setup: PropTypes.object,
    texts: PropTypes.array,
    images: PropTypes.array,
    sendMessage: PropTypes.func,
    t: PropTypes.func,
}

ActionButton.defaultProps = {
    buttonId: ""
}

export default ActionButton;