import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIsMobileVersion } from "src/hooks/useIsMobileVersion";

const Logo = ({ theme, logoClass, height, ...rest }) => {

    const [headerOffset, setHeaderOffset] = useState(0);
    const [offsetLogo, setOffsetLogo] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const { scroll } = useSelector(state => state.shop);
    const { isMobile } = useIsMobileVersion();

    useEffect(() => {
        const header = document.querySelector(".sticky-bar");
        setOffsetLogo(!(window.scrollY > headerOffset));
    }, [scroll]);

    useEffect(() => {
        const header = document.querySelector(".sticky-bar");
        if(header)
            setHeaderOffset(header.offsetTop);
    }, []);

    useEffect(() => {
        if(isMobile){
            setImageUrl("/assets/img/logo/logo.png")
        } else {
            setImageUrl((theme === 'light') ? "/assets/img/logo/logo.png" : "/assets/img/logo/logo-2.png");
        }
    }, [theme, isMobile]);

    if(height)
        return (
            <div className='logo' {...rest}>
                <Link to='/'>
                    <img alt="" src={imageUrl} style={{height: height}} />
                </Link>
            </div>
        )

    return (
        <div className={`${logoClass ? logoClass : ""}`} style={isMobile ? {} : (offsetLogo ? { marginTop: 17, marginBottom: 17 } : {marginTop: 12, marginBottom: 12})} {...rest}>
            <Link to='/'>
                <img alt="" src={imageUrl} style={isMobile ? {height: 30} : (offsetLogo ? { height: 54 } : { height: 42 })} />
            </Link>
        </div>
    );
};

Logo.propTypes = {
    imageUrl: PropTypes.string,
    height: PropTypes.number,
    logoClass: PropTypes.string
};

Logo.defaultProps = {
    height: null,
    logoClass: 'logo'
}

export default Logo;
