import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import Breadcrumb from "src/wrappers/breadcrumb/Breadcrumb";
import useTranslator from "../../../hooks/useTranslator";
import { useDispatch, useSelector } from "react-redux";
import { addVoucher, clearCart, removeVoucher, updateCart } from "../../../redux/actions/shopActions";
import { useNotifications } from "../../../omnia/hooks/use-notifications";
import { useShopFinder } from "../../../hooks/useShopFinder";
import { ShoppingCart } from "react-feather";
import useApi from "../../../omnia/hooks/use-api";
import { useCartManager } from "../../../hooks/useCartManager";
import { Box, Chip, Grid, Button, ButtonGroup, Typography } from "@mui/material";
import useImageLoader from "../../../omnia/hooks/use-image-loader";
import NumberFormat from "react-number-format";

function Position({data}) {

    const { t } = useTranslator();

    if (data.old_value) {
        return (
            <h5 style={{margin: 5}}>
                {t("Versand")}{" "}
                <span style={{marginLeft: 5}}>
                    <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale
                        value={parseFloat(data.value)}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={' €'}
                    />
                </span>
                <span style={{textDecoration: 'line-through'}}>
                    <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale
                        value={parseFloat(data.old_value)}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={' €'}
                    />
                    {/*{"€ " + (data.old_value).toFixed(2)}*/}
                </span>
            </h5>
        )
    } else {
        return (
            <h5 style={{ margin: 5 }}>
                {t(data.name)}{" "}
                <span>
                    {data.value === 0 ? (
                        'Kostenlos'
                    ) : (
                        <>
                            <NumberFormat
                                decimalScale={2}
                                fixedDecimalScale
                                value={parseFloat(data.value)}
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                suffix={' €'}
                            />
                            {/*{"€ " + (data.value).toFixed(2)}*/}
                        </>
                    )}
                </span>
            </h5>
        )
    }

}

function GrandTotal({positions, grandTotal, ...rest}){

    const { t } = useTranslator();
    const navigate = useNavigate();

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', width: '100%'}}>
            <div className="grand-totall" style={{height: '100%'}} {...rest}>
                <div className="title-wrap">
                    <h4 className="cart-bottom-title section-bg-gary-cart">
                        {t("Warenkorb Summe")}
                    </h4>
                </div>
                {positions.map((pos, i) => (
                    <Position data={pos} key={'position-' + i} />
                ))}
                <h4 className="grand-totall-title" style={{marginTop: 20}}>
                    {t("Gesamtsumme")}{" "}
                    <span>
                        <NumberFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={parseFloat(grandTotal)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={' €'}
                        />
                        {/*{"€ " + grandTotal.toFixed(2)}*/}
                    </span>
                </h4>
                {/*<Link to={"/checkout"}>*/}
                {/*    {t("Zum Checkout")}*/}
                {/*</Link>*/}
                <Button fullWidth onClick={() => navigate('/checkout')} size="large" variant="contained">
                    {t("Zum Checkout")}
                </Button>
            </div>
        </div>
    )

}

function CouponChecker({isVoucherActive}){

    const { t } = useTranslator();
    const [value, setValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { vouchers } = useSelector(state => state.shop);
    const dispatch = useDispatch();
    const { notify } = useNotifications();
    const { post } = useApi();

    const handleRemoveVoucher = (code) => {
        dispatch(removeVoucher(code));
        notify("Gutschein entfernt", "success");
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    }

    const handleSubmit = (event) => {
        // Disable page reload on submit of form
        event.preventDefault();
        setIsLoading(true);
        // Check the code
        post('shopper/check_voucher', {code: value}).then(data => {
            if(vouchers.filter(c => c.id === data.id).length > 0){
                notify("Gutschein ist bereits hinzugefügt", "error");
            } else {
                notify("Erfolgreich hinzugefügt", "success");
                dispatch(addVoucher(data));
            }
            setValue("");
        }).finally(() => {
            setIsLoading(false);
        }).catch(errors => {
            console.log(errors);
        })
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
            <div className="discount-code-wrapper" style={{height: '100%'}}>
                <div className="title-wrap">
                    <h4 className="cart-bottom-title section-bg-gray">
                        {t("Gutschein verwenden")}
                    </h4>
                </div>
                <div>
                    <Box mt={2}>
                        {vouchers.map((v, i) => (
                            <Chip
                                key={'voucher-' + i}
                                sx={{mr: 1, mb: 1}}
                                color={isVoucherActive(v) ? "primary" : "default"}
                                label={isVoucherActive(v) ? v.code : (v.code + ' (nicht anwendbar)')}
                                onDelete={() => handleRemoveVoucher(v.code)}
                            />
                        ))}
                    </Box>
                </div>
                <div className="discount-code">
                    <p>{t("Füge einen Gutschein hinzu wenn du einen Code hast.")}</p>
                    <form onSubmit={handleSubmit}>
                        <input value={value} disabled={isLoading} onChange={handleChange} type="text" required name="voucher" />
                        {/*<button className="cart-btn-2" disabled={isLoading} style={isLoading ? {backgroundColor: 'grey'} : {}} type="button" onClick={handleSubmit}>*/}
                        {/*    {t("Gutschein anwenden")}*/}
                        {/*</button>*/}
                        <Button fullWidth disabled={isLoading} onClick={handleSubmit} size="large" variant="outlined">
                            {t("Gutschein anwenden")}
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    )
}

function CartItem({cartItem, decreaseQuantity, increaseQuantity, deleteFromCart}){

    const [isDeliverable, setIsDeliverable] = useState(true);
    const [quantityLimit, setQuantityLimit] = useState(true);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const { imageSrc } = useImageLoader(cartItem.product.featured ? cartItem.product.featured.view : null)
    const { t, tp } = useTranslator();
    const { productIsDeliverable } = useCartManager();

    useEffect(() => {
        setIsSoldOut(cartItem.product.warehouse_active && cartItem.stock === 0);
        if(cartItem.product.warehouse_active){
            setQuantityLimit(cartItem.stock <= cartItem.quantity);
        } else {
            setQuantityLimit(false);
        }
        setIsDeliverable(productIsDeliverable(cartItem.product.id));
    }, [cartItem]);

    return (
        <>
            <td className="product-thumbnail">
                <Link to={"/product/" + cartItem.product.id}>
                    {/*<img className="img-fluid" src={cartItem.product.featured ? cartItem.product.featured.view : "/assets/images/empty_product_vertical.jpg"} alt="" />*/}
                    <div
                        className="img-fluid"
                        style={{
                            backgroundImage: 'url(' + imageSrc + ')',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            height: 80,
                            width: 80,
                            borderRadius: 3
                        }}
                    />
                </Link>
            </td>
            <td className="product-name">
                <Link to={"/product/" + cartItem.product.id}>
                    {tp(cartItem.product, 'name')}
                </Link>
            </td>
            <td className="product-price-cart">

                {cartItem.price_euro ? (
                    <span className="amount">
                        <NumberFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={parseFloat(cartItem.price)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={' €'}
                        />
                        {/*{"€ " + parseFloat(cartItem.price).toFixed(2)}*/}
                    </span>
                ) : (
                    <>
                        {cartItem.product.offer_price_euro ? (
                            <>
                                <span className="amount old">
                                    <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={parseFloat(cartItem.product.price_euro)}
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        suffix={' €'}
                                    />
                                    {/*{"€ " + parseFloat(cartItem.product.price_euro).toFixed(2)}*/}
                                </span>
                                <span className="amount">
                                    <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={parseFloat(cartItem.product.offer_price_euro)}
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        suffix={' €'}
                                    />
                                    {/*{"€ " + parseFloat(cartItem.product.offer_price_euro).toFixed(2)}*/}
                                </span>
                            </>
                        ) : (
                            <span className="amount">
                                <NumberFormat
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={parseFloat(cartItem.price)}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    suffix={' €'}
                                />
                                {/*{"€ " + parseFloat(cartItem.price).toFixed(2)}*/}
                            </span>
                        )}
                    </>
                )}

            </td>
            <td className="product-quantity">
                <div className="cart-plus-minus">
                    <ButtonGroup size="medium" aria-label="large button group">
                        <Button disabled={isSoldOut || !isDeliverable} onClick={() => decreaseQuantity(cartItem)}>-</Button>
                        <Button disabled={isSoldOut || !isDeliverable}>{cartItem.quantity}</Button>
                        <Button disabled={isSoldOut || !isDeliverable || quantityLimit} onClick={() => increaseQuantity(cartItem)}>+</Button>
                    </ButtonGroup>
                    {/*<button className="dec qtybutton" onClick={() => decreaseQuantity(cartItem)}>*/}
                    {/*    -*/}
                    {/*</button>*/}
                    {/*<input*/}
                    {/*    className="cart-plus-minus-box"*/}
                    {/*    type="text"*/}
                    {/*    value={cartItem.quantity}*/}
                    {/*    readOnly*/}
                    {/*/>*/}
                    {/*<button className="inc qtybutton" onClick={() => increaseQuantity(cartItem)}>*/}
                    {/*    +*/}
                    {/*</button>*/}
                </div>
            </td>
            <td className="product-subtotal">
                <NumberFormat
                    decimalScale={2}
                    fixedDecimalScale
                    value={parseFloat(cartItem.price * cartItem.quantity)}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    suffix={' €'}
                />
                {/*{"€ " + (cartItem.price * cartItem.quantity).toFixed(2)}*/}
            </td>
            <td className="product-remove">
                <button onClick={() => deleteFromCart(cartItem)}>
                    <i className="fa fa-times" />
                </button>
            </td>
        </>
    )
}

function SimplyCart() {

    const dispatch = useDispatch();
    const { cartItems, isVoucherActive, productIsDeliverable, positions, grandTotal } = useCartManager();
    const { notify } = useNotifications();
    const { t } = useTranslator();
    const navigate = useNavigate();
    const { shopUrl, shopPageExists } = useShopFinder();

    const decreaseQuantity = (item) => {
        if(item.quantity === 1){
            if(window.confirm(t("Soll das Produkt wirklich aus dem Warenkorb entfernt werden?"))){
                dispatch(updateCart(item.id, 0));
            }
        } else {
            dispatch(updateCart(item.id, item.quantity - 1));
        }
    };

    const increaseQuantity = (item) => {
        dispatch(updateCart(item.id, item.quantity + 1));
    };

    const deleteFromCart = (item) => {
        dispatch(updateCart(item.id, 0));
        notify(t("Aus Warenkorb entfernt"), "success");
    };

    const deleteAllFromCart = () => {
        if(window.confirm(t("Soll der Warenkorb wirklich geleert werden?"))){
            dispatch(clearCart());
            notify(t("Warenkorb geleert"), "success");
        }
    };

    return (
        <>
            <Breadcrumb />
            <div className="cart-main-area pt-90 pb-100">
                <div className="container">
                    {cartItems.length > 0 ? (
                        <Fragment>
                            <h3 className="cart-page-title">{t("Dein Warenkorb")}</h3>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-content table-responsive cart-table-content">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>{t("Vorschau")}</th>
                                                <th>{t("Produkt")}</th>
                                                <th>{t("Stückpreis")}</th>
                                                <th>{t("Menge")}</th>
                                                <th>{t("Zwischensumme")}</th>
                                                <th>{t("Aktion")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {cartItems.map((cartItem, key) => (
                                                <tr key={key} style={!productIsDeliverable(cartItem.product) ? {textDecoration: 'line-through'} : {}}>
                                                    <CartItem
                                                        cartItem={cartItem}
                                                        decreaseQuantity={decreaseQuantity}
                                                        increaseQuantity={increaseQuantity}
                                                        deleteFromCart={deleteFromCart}
                                                    />
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="cart-shiping-update-wrapper">
                                        <div className="cart-shiping-update">
                                            {shopPageExists ? (
                                                <Button color="secondary" onClick={() => navigate(shopUrl)} size="large" variant="text">
                                                    {t("Weiter einkaufen")}
                                                </Button>
                                                // <Link to={shopUrl}>
                                                //     {t("Weiter einkaufen")}
                                                // </Link>
                                            ) : (
                                                <Button color="secondary" onClick={() => navigate('/')} size="large" variant="text">
                                                    {t("Zur Startseite")}
                                                </Button>
                                                // <Link to={"/"}>
                                                //     {t("Zur Startseite")}
                                                // </Link>
                                            )}
                                        </div>
                                        <div className="">
                                            <Button color="secondary" onClick={deleteAllFromCart} size="large" variant="text">
                                                {t("Warenkorb leeren")}
                                            </Button>
                                            {/*<button onClick={deleteAllFromCart}>*/}
                                            {/*    {t("Warenkorb leeren")}*/}
                                            {/*</button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Grid container alignItems="stretch">
                                <Grid item xs={12} sm={6} style={{display: 'flex'}}>
                                    <CouponChecker isVoucherActive={isVoucherActive} />
                                </Grid>
                                <Grid item xs={12} sm={6} style={{display: 'flex'}}>
                                    <GrandTotal grandTotal={grandTotal} positions={positions} />
                                </Grid>
                            </Grid>
                            {/*<div className="row">*/}
                            {/*    <div className="col-lg-6 col-md-6" style={{height: '100%'}}>*/}
                            {/*        <CouponChecker isVoucherActive={isVoucherActive} />*/}
                            {/*    </div>*/}
                            {/*    <div className="col-lg-6 col-md-6" style={{height: '100%'}}>*/}
                            {/*        <GrandTotal grandTotal={grandTotal} positions={positions} />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </Fragment>
                    ) : (
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="item-empty-area text-center">
                                    <div className="item-empty-area__icon mb-30">
                                        {/*<i className="pe-7s-cart" />*/}
                                        <ShoppingCart size={95} />
                                    </div>
                                    <div className="">
                                        <Typography variant="subtitle1">
                                            {t("Du hast keine Produkte im Warenkorb")}
                                        </Typography>
                                        <br />
                                        <Box mt={4}>
                                            {shopPageExists ? (
                                                <Button size="large" variant="contained" onClick={() => navigate(shopUrl)}>
                                                    {t("Jetzt einkaufen")}
                                                </Button>
                                                // <Link to={shopUrl}>
                                                //     {t("Jetzt einkaufen")}
                                                // </Link>
                                            ) : (
                                                <Button size="large" variant="contained" onClick={() => navigate('/')}>
                                                    {t("Zur Startseite")}
                                                </Button>
                                                // <Link to={"/"}>
                                                //    {/*{t("Zur Startseite")}*/}
                                                // </Link>
                                            )}
                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default SimplyCart;
