import PropTypes from "prop-types";
import React, { Fragment } from "react";
// import MetaTags from "react-meta-tags";
import useTranslator from "../hooks/useTranslator";
import SimplyWarmLayout from "../layouts/SimplyWarmLayout";
import { Typography } from "@mui/material";

const MaintenanceMode = () => {

    const { t } = useTranslator();

    return (
        <Fragment>
            {/*<MetaTags>*/}
            {/*    <title>Simply Warm | Wartungsarbeiten</title>*/}
            {/*    <meta name="Simlpy Warm" content="404,simply-warm,not found" />*/}
            {/*</MetaTags>*/}
            <div className="error-area pt-80 pb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8 text-center">
                            <div className="error">
                                <h1>:(</h1>
                                <h2>{t('Wir sind aktuell nicht erreichbar')}</h2>
                                <Typography variant="body1">
                                    {t('Wir befinden uns aktuell im Wartungsmodus - bitte probiere es später noch einmal, uns zu erreichen.')}
                                </Typography>
                                {/*<form className="searchform mb-50">*/}
                                {/*    <input*/}
                                {/*        type="text"*/}
                                {/*        name="search"*/}
                                {/*        id="error_search"*/}
                                {/*        placeholder={t('Search...')}*/}
                                {/*        className="searchform__input"*/}
                                {/*    />*/}
                                {/*    <button type="submit" className="searchform__submit">*/}
                                {/*        <i className="fa fa-search" />*/}
                                {/*    </button>*/}
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

MaintenanceMode.propTypes = {
    location: PropTypes.object
};

export default MaintenanceMode;
