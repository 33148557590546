import React from "react";
import { useTheme } from "@mui/system";
import { useIsMobile } from "../omnia/hooks/use-is-mobile";

const useShopTheme = () => {

    const muiTheme = useTheme();
    const { isMobile } = useIsMobile();

    const getTheme = (theme) => {
        if(isMobile)
            return {color: muiTheme.palette.text.primary};
        return theme === 'light' ? {color: muiTheme.palette.text.primary} : {color: 'white'}
    }

    return { getTheme };

}

export default useShopTheme;