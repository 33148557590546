// Global Omnia Application Settings

export const APP_SETTING = {

    /**
     * Domain and Protocol
     *
     * Select the domain that you want your omnia application to run on. Also
     * select what procotol you want to be using - we strongly recommend only
     * using HTTPS and WSS for secure data transmission. In development you
     * can select localhost:8000 if you have a django dev server running in
     * your IDE. In this case, switch to HTTP and WS only in order to connect
     * the omnia application to your local API.
     */
    // domain: "localhost:8000",
    // protocol: "http",
    // socket_protocol: 'ws',
    domain: "simply-warm.groon.io",
    protocol: "https",
    socket_protocol: 'wss',

    /**
     * API Settings
     // *
     * In most cases Omnia will run in a subfolder from your main application
     * so that requests will be performed to /api in order to proxy the request
     * in the container to the correct service (the omnia api).
     */
    api: {
        base_url: '/api',
        timeout: 10000000,
    },

    /**
     * State Logger
     *
     * Select true if you want to enable the redux state container logger to
     * monitor all actions and state transitions of your application.
     *
     */
    redux: {
        'enable_logger': false
    },

    /**
     * Omnia Modules
     *
     * Please tick what modules are installed and shall be built in this omnia
     * distribution. If you select false, no routes an UI will be built for the
     * specific module
     */
    modules: {
        spaces: true,
        visitors: false,
        contacts: true,
        resources: true,
        cms: true,
    },

    /**
     * Sidebar Items
     *
     * If there are individual modules, please add their main page here in order to
     * attach them to the sidebar of this omnia distribution
     */
    sidebar: [],

    /**
     * Widgets
     *
     * You can register your widgets here
     */
    widgets: [],

    /**
     * Topbar Items
     *
     * If there are individual modules that contain some key objects and you want them
     * to appear under the top right user menu please add them here e.g. "Deine Anträge"
     */
    topbar: [],

    /**
     * Google Analytics Key
     *
     * This will make omnia connect to your google analytics account so that you can measure
     * the usage and types of usages of your omnia distribution
     */
    google_analytics_key: null

}

// Define the existing themes
export const THEMES = {
    LIGHT: 'LIGHT',
    DARK: 'DARK',
};
