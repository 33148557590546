import PropTypes from "prop-types";
import React, { useState } from "react";
import { Tabs, Tab, Divider, Grid, Box, Typography } from "@mui/material";
import useTranslator from "../../../../hooks/useTranslator";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const ProductDetails = ({ product, sendMessage }) => {

    const [tab, setTab] = useState('details');
    const { t, tp } = useTranslator();
    const { identifier } = useParams();
    const { allowPrivacy } = useSelector(state => state.shop);

    const renderDescription = () => {
        return {
            __html: tp(product, 'description')
        }
    }

    const handleTabChange = (event, val) => {
        // Send a message using sendMessage which contains an activity update
        if(allowPrivacy.includes('usage')) {
            sendMessage({
                type: 'activityUpdate',
                method: 'tab-change',
                content: val,
                product_id: identifier,
            });
        }
        setTab(val);
    }

    return (
        <Box pb={5}>
            <div className="container">
                <Grid className="description-review-area" container spacing={2}>
                    <Grid item xs={12}>
                        <Tabs value={tab} onChange={handleTabChange}>
                            <Tab label={t("Beschreibung")} value='details' />
                            <Tab label={t("Bewertungen")} value='reviews' />
                        </Tabs>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {tab === 'details' && (
                            <p dangerouslySetInnerHTML={renderDescription()} />
                        )}
                        {tab === 'reviews' && (
                            <div style={{height: 150, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                <Typography variant="caption">
                                    {t("Noch keine Bewerungen hinzugefügt")}
                                </Typography>
                            </div>
                        )}
                    </Grid>
                </Grid>
            </div>
        </Box>
    )
};

ProductDetails.propTypes = {
    product: PropTypes.object,
    sendMessage: PropTypes.func
};

export default ProductDetails;
