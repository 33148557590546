import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useTranslator from "../../hooks/useTranslator";
import useShopTheme from "../../hooks/useShopTheme";

const NavMenu = ({ strings, theme, menuWhiteClass, sidebarMenu }) => {

    const { sites } = useSelector(state => state.shop);
    const { t } = useTranslator();
    const { getTheme } = useShopTheme();

    return (
        <div className={` ${sidebarMenu ? "sidebar-menu" : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`} `}>
            <nav>
                <ul>
                    {sites.filter(s => !s.is_landing).map((s, i) => (
                        <li key={'site-' + i}>
                            <Link to={"/" + s['url']} style={{...getTheme(theme), ...{textTransform: 'uppercase', fontSize: 13, marginRight: 5, letterSpacing: 1.5}}}>
                                {t(s['title'])}
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

NavMenu.propTypes = {
    menuWhiteClass: PropTypes.string,
    sidebarMenu: PropTypes.bool,
    strings: PropTypes.object
};

export default multilanguage(NavMenu);
