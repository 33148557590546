import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl,
    Grid,
    InputLabel, Select
} from "@mui/material";
import useTranslator from "../../hooks/useTranslator";
import CountrySelector from "../header/CountrySelector";

function SelectCountry({open, onClose, onSetCountry}){

    const { t } = useTranslator();
    const [value, setValue] = useState(null);
    const { country } = useSelector(state => state.shop);

    const saveCountry = () => {
        if(onSetCountry)
            onSetCountry(value);
        if(onClose)
            onClose();
    }

    useEffect(() => {
        if(country)
            setValue(country);
    }, [country]);

    return (
        <Dialog
            open={open}
            maxWidth="sm"
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{fontSize: 28, paddingTop: 30, paddingBottom: 5}}>
                {t('Ländereinstellung')}
            </DialogTitle>
            <DialogContent style={{paddingBottom: 25, paddingTop: 10, minWidth: 300}}>
                <DialogContentText id="alert-dialog-description">
                    {t('Wähle ein anderes Land oder eine andere Region, um Inhalte für deinen Standort zu sehen und online einzukaufen.')}
                </DialogContentText>
                <CountrySelector sx={{mt: 2}} />
            </DialogContent>
            <DialogActions style={{padding: 20}}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Button
                            onClick={onClose}
                            fullWidth
                            size="large"
                            type="button"
                        >
                            {t('Abbrechen')}
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            color="primary"
                            onClick={saveCountry}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                        >
                            {t('Speichern')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

export default SelectCountry;