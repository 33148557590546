import PropTypes from "prop-types";
import React from "react";
import LanguageSelector from "src/components/helpers/LanguageSelector";
import useTranslator from "src/hooks/useTranslator";
import useShopTheme from "../../../hooks/useShopTheme";

const TopBar = ({ theme }) => {

    const { t } = useTranslator();
    const { getTheme } = useShopTheme();

    return (
        <div className={'header-top-wap'}>
            <LanguageSelector theme={theme}>
                <div className="same-language-currency">
                    <p style={getTheme(theme)}>{t('Anrufen: +49 40 73931069')}</p>
                </div>
            </LanguageSelector>

            {/*<div className="same-language-currency use-style">*/}
            {/*    <span>*/}
            {/*      {currency.currencyName} <i className="fa fa-angle-down" />*/}
            {/*    </span>*/}
            {/*    <div className="lang-car-dropdown">*/}
            {/*        <ul>*/}
            {/*            <li>*/}
            {/*                <button value="USD" onClick={e => setCurrencyTrigger(e)}>*/}
            {/*                    USD*/}
            {/*                </button>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <button value="EUR" onClick={e => setCurrencyTrigger(e)}>*/}
            {/*                    EUR*/}
            {/*                </button>*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <button value="GBP" onClick={e => setCurrencyTrigger(e)}>*/}
            {/*                    GBP*/}
            {/*                </button>*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="header-offer">
                <p style={getTheme(theme)}>
                    {t('Schön, dass Sie da sind!')}
                    {/*<span>*/}
                    {/*    {currency.currencySymbol + (200 * currency.currencyRate).toFixed(2)}*/}
                    {/*</span>*/}
                </p>
            </div>
        </div>
    );
};

TopBar.propTypes = {
    theme: PropTypes.string
};

TopBar.defaultProps = {
    theme: "light"
}

export default TopBar;
