import React  from "react";
import PropTypes from "prop-types";
import WishlistMenu from "./TopBar/WishlistMenu";
import CartMenu from "./TopBar/CartMenu";
import { Menu, Tool } from "react-feather";
import { useIsMobile } from "../../omnia/hooks/use-is-mobile";
import LanguagesDropdown from "./TopBar/LanguagesDropdown";

const ActionGroup = ({ theme }) => {

    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.add("active");
    };
    const { isMobile } = useIsMobile();

    return (
        <div className={'header-right-wrap'}>

            {/*<UserMenu theme={theme} />*/}
            <LanguagesDropdown themeMode={theme} />
            <WishlistMenu themeMode={theme} />
            <CartMenu themeMode={theme} />

            <div className="same-style mobile-off-canvas d-block d-lg-none" style={isMobile ? {width: 27} : {width: 35}}>
                <button className="mobile-aside-button" onClick={() => triggerMobileMenu()}>
                    <Menu theme={theme} />
                </button>
            </div>
        </div>
    );
};

ActionGroup.propTypes = {
    theme: PropTypes.string
};

export default ActionGroup;
