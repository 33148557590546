import React from "react";
import { Fab, Fade, Grow } from "@mui/material";
import useTranslator from "../../hooks/useTranslator";
import { CheckCircle as CheckoutIcon } from "react-feather";
import { useNavigate } from "react-router";
import { useIsMobile } from "../../omnia/hooks/use-is-mobile";
import { useCartManager } from "../../hooks/useCartManager";
import NumberFormat from "react-number-format";

function GoToCheckout(){

    const { t } = useTranslator();
    const navigate = useNavigate();
    const { isMobile } = useIsMobile();
    const { grandTotal } = useCartManager();

    const handleGoCheckout = () => {
        navigate('/checkout');
    }

    return (
        <Grow in>
            <Fab
                size="large"
                style={isMobile ? {position: 'fixed', bottom: 20, left: 20, zIndex: 10, right: 20} : {position: 'fixed', bottom: 20, left: 20, zIndex: 10}}
                onClick={handleGoCheckout}
                sx={{boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.3)'}}
                color="primary"
                variant="extended"
            >
                <CheckoutIcon />
                <span style={{marginLeft: 10}}>
                    {t("Checkout") + '   '}
                    <span style={{fontSize: 15}}>
                        <NumberFormat
                            decimalScale={2}
                            fixedDecimalScale
                            value={parseFloat(grandTotal)}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={'€'}
                        />
                    </span>
                </span>
            </Fab>
        </Grow>
    )
}

export default GoToCheckout;