import { useEffect, useState } from "react";

const useImageLoader = (src, loadingImage='/assets/images/loading_image.jpg') => {

    const [imageSrc, setImageSrc] = useState(loadingImage);
    const [sourceLoaded, setSourceLoaded] = useState(null);

    const handleSourceLoaded = () => {
        setSourceLoaded(src);
        setImageSrc(src);
    }

    useEffect(() => {
        if(src){
            const img = new Image();
            img.src = src;
            img.onload = handleSourceLoaded;
        }
    }, [src])

    return { sourceLoaded, imageSrc };
}

export default useImageLoader;