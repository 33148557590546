import React from "react";

let useRouter, useReactRouterLocation;

// Use static imports; webpack (or other bundlers) will handle tree shaking to eliminate dead code
try {
    useRouter = require('next/router').useRouter;
} catch (err) {
    useRouter = null;
}

try {
    useReactRouterLocation = require('react-router-dom').useLocation;
} catch (err) {
    useReactRouterLocation = null;
}

const useLocation = () => {
    const nextLocation = useRouter ? useRouter : null;
    const reactRouterLocation = useReactRouterLocation ? useReactRouterLocation : null;

    if (nextLocation) {
        return nextLocation;
    } else if (reactRouterLocation) {
        return reactRouterLocation;
    } else {
        throw new Error('useLocation hook is used outside of Next.js and React Router context.');
    }
}

export default useLocation;
