import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

function Blog({component, texts, setup, images, sendMessage, t}){

    return (
        <div style={{height: 150, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <Typography variant="caption">
                Element "{component.component}" in Arbeit ...
            </Typography>
        </div>
    );
}

Blog.propTypes = {
    component: PropTypes.object,
    images: PropTypes.array,
    texts: PropTypes.array,
    setup: PropTypes.object,
    sendMessage: PropTypes.func
}

export default Blog;