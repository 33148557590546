import chroma from "chroma-js";

export default function adjustLuminanceToTarget(primaryColor, targetLuminance) {
    let adjustedColor = chroma(primaryColor);
    let currentLuminance = adjustedColor.luminance();

    // Define a threshold for how close we need to get to the target luminance
    const threshold = 0.0005;
    let iterations = 0;
    let stepSize;

    // Loop to adjust the color luminance until it's close to the target
    while (Math.abs(currentLuminance - targetLuminance) > threshold) {

        stepSize = Math.abs(currentLuminance - targetLuminance);

        if (currentLuminance < targetLuminance) {
            // If current luminance is less than target, slightly increase luminance
            adjustedColor = adjustedColor.luminance(currentLuminance + stepSize);
        } else {
            // If current luminance is more than target, slightly decrease luminance
            adjustedColor = adjustedColor.luminance(currentLuminance - stepSize);
        }
        iterations += 1;
        if(iterations >= 100){
            break;
        }
        currentLuminance = adjustedColor.luminance();
    }

    return adjustedColor.hex();
}