import {useEffect, useState} from "react";
import useDimensions from "src/omnia/hooks/use-dimensions";

export const useIsMobileVersion = () => {

    const { width } = useDimensions();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if(width <= 991){
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [width]);

    return { isMobile }
}