import React from "react";
import useTranslator from "../../../hooks/useTranslator";

const MobileWidgets = () => {

    const { t } = useTranslator();

    return (
        <div className="offcanvas-widget-area">
            <div className="off-canvas-contact-widget">
                <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                        <li>
                            <a className="fa fa-phone" />{" "}
                            <a href="tel://004941189880">{t("+49 40 4118 98 80")}</a>
                        </li>
                        <li>
                            <i className="fa fa-envelope" />{" "}
                            <a href={"mailto:" + t("support@simply-warm.com")}>{t("support@simply-warm.com")}</a>
                        </li>
                    </ul>
                </div>
            </div>
            {/*Off Canvas Widget Social Start*/}
            <div className="off-canvas-widget-social">
                {/*<a href="//twitter.com" title="Twitter">*/}
                {/*    <i className="fa fa-twitter"></i>*/}
                {/*</a>*/}
                {/*<a href="//instagram.com" title="Instagram">*/}
                {/*    <i className="fa fa-instagram"></i>*/}
                {/*</a>*/}
                {/*<a href="//facebook.com" title="Facebook">*/}
                {/*    <i className="fa fa-facebook"></i>*/}
                {/*</a>*/}
                {/*<a href="//pinterest.com" title="Pinterest">*/}
                {/*    <i className="fa fa-pinterest"></i>*/}
                {/*</a>*/}
            </div>
            {/*Off Canvas Widget Social End*/}
        </div>
    );
};

export default MobileWidgets;
