import PropTypes from "prop-types";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import debounce from "lodash.debounce";
import Breadcrumb from "src/wrappers/breadcrumb/Breadcrumb";
import {
    Button,
    Dialog,
    Fade,
    Grid,
    InputLabel,
    Typography,
    FormHelperText,
    Select,
    TextField,
    FormControl,
    Box,
    Tooltip,
    Checkbox,
    FormControlLabel,
    CircularProgress, Autocomplete
} from "@mui/material";
import useTranslator from "../../../hooks/useTranslator";
import { clearCart, saveSetting, updateVisitor } from "../../../redux/actions/shopActions";
import { useNotifications } from "../../../omnia/hooks/use-notifications";
import Logo from "../../../components/header/Logo";
import { useShopFinder } from "../../../hooks/useShopFinder";
import { useCartManager } from "../../../hooks/useCartManager";
import { useIsMobile } from "../../../omnia/hooks/use-is-mobile";
import NumberFormat from "react-number-format";
import { ShoppingCart } from "react-feather";
import CountrySelector from "../../../components/header/CountrySelector";
import produce from "immer";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade ref={ref} {...props} />;
});

const CheckoutButton = ({grandTotal, errors, handleSubmit, loadingLink, t}) => {
    return (
        <>
            {(grandTotal === 0) ? (
                <Tooltip title={t("Bitte wähle lieferbare Produkte aus")}>
                    <div className="mt-25">
                        <LoadingButton
                            fullWidth
                            disabled
                            variant="contained"
                            loadingPosition="end"
                            size="large"
                        >
                            {t("Jetzt bezahlen")}
                        </LoadingButton>
                    </div>
                </Tooltip>
            ) : (
                <>
                    <div className="mt-25">
                        <LoadingButton
                            fullWidth
                            onClick={handleSubmit}
                            disabled={loadingLink}
                            loading={loadingLink}
                            variant="contained"
                            loadingPosition="end"
                            size="large"
                        >
                            {t("Jetzt bezahlen")}
                        </LoadingButton>
                    </div>
                </>
            )}
        </>
    )
}

const YourOrder = ({ productIsDeliverable, cartItems, setFieldValue, grandTotal, positions, values, t }) => {

    const { tp } = useTranslator();

    return (
        <>
            <div>
                <div className="your-order-wrap gray-bg-4" style={{borderRadius: 7, padding: 17}}>
                    <div className="your-order-product-info">
                        <div className="your-order-top">
                            <ul>
                                <li style={{fontWeight: 600}}>{t("Position")}</li>
                                <li style={{fontWeight: 600}}>{t("Zwischensumme")}</li>
                            </ul>
                        </div>
                        <div className="your-order-middle" style={{marginBottom: 15, paddingTop: 0, borderTop: 'none'}}>
                            <ul>
                                {cartItems.map((cartItem, key) => (
                                    <Fragment key={'item-' + key}>
                                        {productIsDeliverable(cartItem.product.id) ? (
                                            <>
                                                <li style={{margin: 0}} key={key}>
                                                    <span className="order-middle-left">
                                                        {cartItem.quantity + " X " + tp(cartItem.product, 'name') + " "}
                                                    </span>
                                                    <span className="order-price" style={{minWidth: 60, marginLeft: 20, textAlign: 'right'}}>
                                                        <NumberFormat
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            value={parseFloat(cartItem.price * cartItem.quantity)}
                                                            displayType={'text'}
                                                            thousandSeparator={'.'}
                                                            decimalSeparator={','}
                                                            suffix={' €'}
                                                        />
                                                    </span>
                                                </li>
                                            </>
                                        ) : (
                                            <Tooltip placement="left-start" title={t("In dieses Land nicht lieferbar")}>
                                                <li style={{margin: 0}} key={key}>
                                                    <span className="order-middle-left" style={{textDecoration: 'line-through'}}>
                                                        {cartItem.quantity + " X " + tp(cartItem.product, 'name') + " "}
                                                    </span>
                                                    <span className="order-price" style={{textDecoration: 'line-through', minWidth: 60, marginLeft: 20, textAlign: 'right'}}>
                                                        <NumberFormat
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            value={parseFloat(cartItem.price * cartItem.quantity)}
                                                            displayType={'text'}
                                                            thousandSeparator={'.'}
                                                            decimalSeparator={','}
                                                            suffix={' €'}
                                                        />
                                                    </span>
                                                </li>
                                            </Tooltip>
                                        )}
                                    </Fragment>
                                ))}
                            </ul>
                        </div>
                        {positions.filter(p => p.name !== "Produkte").map((p, i) => (
                            <div className="your-order-bottom" key={'position-' + i}>
                                <ul style={{margin: 0}} >
                                    <li className="your-order-shipping" style={{fontSize: 14}}>{t(p.name)}</li>
                                    <li>
                                        {p.value === 0 ? 'Kostenlos' : (
                                            <NumberFormat
                                                decimalScale={2}
                                                fixedDecimalScale
                                                value={parseFloat(p.value)}
                                                displayType={'text'}
                                                thousandSeparator={'.'}
                                                decimalSeparator={','}
                                                suffix={' €'}
                                            />
                                            // '€ ' + p.value.toFixed(2)
                                        )}
                                    </li>
                                </ul>
                            </div>
                        ))}
                        <div className="your-order-total" style={{marginBottom: 0, paddingBottom: 2, borderBottom: 'none'}}>
                            <ul>
                                <li className="order-total" style={{fontWeight: 600}}>{t("Total")}</li>
                                <li style={{fontWeight: 600, fontSize: 18}}>
                                    <NumberFormat
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={parseFloat(grandTotal)}
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        suffix={' €'}
                                    />
                                    {/*{'€ ' + " " + grandTotal.toFixed(2)}*/}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const YourData = ({ errors, touched, onSetCountry, countriesRef, handleChange, handleBlur, values, t }) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <div className=" ">
                    <TextField
                        required
                        error={Boolean(touched.first_name && errors.first_name)}
                        fullWidth
                        label={t("Vorname")}
                        name="first_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}

                    />
                    {errors.first_name && (
                        <FormHelperText error>
                            {t(errors.first_name)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="billing-info ">
                    <TextField
                        required
                        error={Boolean(touched.last_name && errors.last_name)}
                        fullWidth
                        label={t("Nachname")}
                        name="last_name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}

                    />
                    {Boolean(touched.last_name && errors.last_name) && (
                        <FormHelperText error>
                            {t(errors.last_name)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="billing-info ">
                    <TextField
                        required
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        label={t("Email")}
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}

                    />
                    {Boolean(touched.email && errors.email) && (
                        <FormHelperText error>
                            {t(errors.email)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={6}>
                <div className="billing-info ">
                    <TextField
                        error={Boolean(touched.phone && errors.phone)}
                        fullWidth
                        label={t("Telefon")}
                        name="phone"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.phone}

                    />
                    {Boolean(touched.phone && errors.phone) && (
                        <FormHelperText error>
                            {t(errors.phone)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="billing-info ">
                    <TextField
                        error={Boolean(touched.organization && errors.organization)}
                        fullWidth
                        helperText={touched.organization && errors.organization}
                        label={t("Organization")}
                        name="organization"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organization}

                    />
                    {Boolean(touched.organization && errors.organization) && (
                        <FormHelperText error>
                            {t(errors.organization)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="billing-info ">
                    <TextField
                        required
                        error={Boolean(touched.address_line_2 && errors.address_line_2)}
                        fullWidth
                        label={t("Straße Nr.")}
                        name="address_line_2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_line_2}

                    />
                    {Boolean(touched.address_line_2 && errors.address_line_2) && (
                        <FormHelperText error>
                            {t(errors.address_line_2)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="billing-info ">
                    <TextField
                        required
                        error={Boolean(touched.address_line_3 && errors.address_line_3)}
                        fullWidth
                        label={t("PLZ Ort")}
                        name="address_line_3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address_line_3}

                    />
                    {Boolean(touched.address_line_3 && errors.address_line_3) && (
                        <FormHelperText error>
                            {t(errors.address_line_3)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="billing-select ">
                    <CountrySelector
                        onChange={(code) => {
                            handleChange({
                                target: {
                                    name: 'country_code',
                                    value: code
                                }
                            });
                        }}
                        error={Boolean(touched.country_code && errors.country_code)}
                    />
                    {Boolean(touched.country_code && errors.country_code) && (
                        <FormHelperText error>
                            {t(errors.country_code)}
                        </FormHelperText>
                    )}
                </div>
            </Grid>
        </Grid>
    )
}

const YourOkay = ({values, setFieldValue, t}) => {

    return (
        <div>
            <Box sx={{pl: 2, mt: 1}}>
                <Typography variant="caption" style={{lineHeight: '5px'}}>
                    {t("Mit deinem Kauf stimmst du unseren AGB's zu")}
                </Typography>
            </Box>
            <Box sx={{pl: 2, mt: 2}}>
                <FormControlLabel
                    control={(
                        <Checkbox
                            onChange={() => setFieldValue('allows_contacting', !values.allows_contacting)}
                            checked={values.allows_contacting}
                            name="allows_contacting"
                            value={values.allows_contacting}
                        />
                    )}
                    label={(
                        <Typography
                            variant="body1"
                            color="textSecondary"
                        >
                            {t("Simply Warm darf mich bei Neuigkeiten kontaktieren")}
                        </Typography>
                    )}
                    labelPlacement="end"
                />
            </Box>
        </div>
    )
}

const SimplyCheckout = ({ sendMessage, onSetCountry }) => {

    const { t } = useTranslator();
    const { visitor, checkout_link, countries, vouchers, payment_status,deliverable_countries } = useSelector(state => state.shop);
    const dispatch = useDispatch();
    const { shopPageExists, shopUrl } = useShopFinder();
    const { isMobile } = useIsMobile();
    const navigate = useNavigate();
    const { cartItems, grandTotal, positions, productIsDeliverable } = useCartManager();
    const [loadingLink, setLoadingLink] = useState(false);
    const [redirectMessage, setRedirectMessage] = useState(false);
    const { notify } = useNotifications();
    const visitorRef = useRef();
    const deliveryRef = useRef();
    const countriesRef = useRef();
    const grandTotalRef = useRef();
    const voucherRef = useRef();
    const valueRef = useRef();

    visitorRef.current = visitor;
    voucherRef.current = vouchers;
    deliveryRef.current = deliverable_countries;
    countriesRef.current = countries;
    grandTotalRef.current = grandTotal;

    const performCheckout = (values) => {
        if(grandTotalRef.current > 0){

            // Reset whatever is still active in settings
            dispatch(saveSetting({checkout_link: null}, 'checkout_link'));
            dispatch(saveSetting({payment_status: null}, 'payment_status'));

            // Set the state to loading
            setLoadingLink(true);

            // Send a checkout link request
            sendMessage({
                type: "getCheckoutLink",
                visitor: values,
                vouchers: voucherRef.current.map(v => v.code)
            });
        } else {
            notify(t("Füge lieferbare Produkte hinzu"), "error");
        }
    };

    const handleDataUpdate = (values) => {
        dispatch(updateVisitor(values));
        debouncedChangeHandler();
    };

    const syncChanges = () => {
        sendMessage({
            "type": "orderUpdate",
            "visitor": visitorRef.current
        });
    };

    const initialValues = {
        ...{
            first_name: '',
            last_name: '',
            organization: '',
            phone: '',
            email: '',
            address_line_2: '',
            address_line_3: '',
            country_code: '',
            address_notes: '',
        },
        ...visitor
    };

    const debouncedChangeHandler = useCallback(debounce(syncChanges, 1000), []);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            first_name: Yup.string().required(t("Bitte gebe deinen Vornamen an")),
            last_name: Yup.string().required(t("Bitte gebe deinen Nachnamen an")),
            organization: Yup.string(),

            phone: Yup.string(),
            email: Yup.string().nullable().email(t("Bitte gebe eine gültige Email Adresse ein")).required(t("Bitte gebe deine Email Adresse ein")),

            address_line_2: Yup.string().required(t("Bitte gebe deine Adresse an")),
            address_line_3: Yup.string().required(t("Bitte gebe deinen Ort an")),

            country_code: Yup.string().test('str', t("Bitte gebe dein Land an"), (val) => {
                if(typeof val === "undefined")
                    return false;
                return true;
            }).test('str', t('In dieses Land liefern wir leider nicht'), (val, vals) => {
                if(typeof val === "undefined")
                    return false;
                return deliveryRef.current.includes(countriesRef.current.find(c => c.code === val).code);
            }).required(t("Bitte gebe dein Land an")),

            address_notes: Yup.string()
        }),
        onSubmit: performCheckout
    })

    useEffect(() => {
        let timer = null;
        if(loadingLink){
            timer = setTimeout(() => {
                notify(t("Das hat leider nicht geklappt. Bitte probiere es erneut."), "error");
                setLoadingLink(false);
            }, 20000);
        }
        return () => {
            if(timer)
                clearTimeout(timer);
        };
    }, [loadingLink]);

    useEffect(() => {

        // Check if loading link at the moment
        if (loadingLink) {

            if (payment_status) {
                if (payment_status !== "open") {
                    setLoadingLink(false);
                    dispatch(saveSetting({
                        "payment_status": null
                    }, "payment_status"));
                }
            }

            if (checkout_link) {
                setRedirectMessage(true);
                setTimeout(() => {
                    dispatch(clearCart());
                    window.location.href = checkout_link;
                }, 2000);
            }

        }

    }, [payment_status, checkout_link, loadingLink]);

    useEffect(() => {
        handleDataUpdate(formik.values);
    }, [formik.values]);

    if (typeof (visitor) === "undefined")
        return null;

    return (
        <Fragment>
            <Breadcrumb />
            <Dialog
                fullScreen
                open={redirectMessage}
                TransitionComponent={Transition}
            >
                <div style={{
                    height: 150,
                    marginTop: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Logo theme="light" height={70} />
                </div>
                <div style={{
                    height: 200,
                    marginBottom: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Box>
                        <Typography variant="h2" textAlign="center" style={{ marginBottom: 15 }}>
                            {t("Weiterleitung")}
                        </Typography>
                        <Typography variant="body1" style={{ fontSize: 20 }} textAlign="center">
                            {t("Du wirst jetzt gleich zum Bezahlen weitergeleitet ...")}
                        </Typography>
                    </Box>
                </div>
            </Dialog>
                <div className={"checkout-area " + (isMobile ? "pt-10 pb-50" : "pt-95 pb-100")}>
                    <div className="container">
                        {cartItems && cartItems.length > 0 ? (
                            <div className="row">
                                {isMobile ? (
                                    <div className="col-lg-12">
                                        <div className="your-order-area">
                                            <h3>{t("Deine Bestellung")}</h3>
                                            <YourOrder
                                                productIsDeliverable={productIsDeliverable}
                                                cartItems={cartItems}
                                                setFieldValue={formik.setFieldValue}
                                                grandTotal={grandTotal}
                                                positions={positions}
                                                values={formik.values}
                                                t={t}
                                            />
                                            <Box mt={8}>
                                                <YourData
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    onSetCountry={onSetCountry}
                                                    countriesRef={countriesRef}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    values={formik.values}
                                                    t={t}
                                                />
                                            </Box>
                                            <YourOkay
                                                values={formik.values}
                                                setFieldValue={formik.setFieldValue}
                                                t={t}
                                            />
                                            <CheckoutButton
                                                grandTotal={grandTotal}
                                                errors={formik.errors}
                                                handleSubmit={formik.handleSubmit}
                                                loadingLink={loadingLink}
                                                t={t}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="col-lg-7">
                                            <div className="your-order-area">
                                                <h3>{t("Deine Daten")}</h3>
                                                <YourData
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                    onSetCountry={onSetCountry}
                                                    countriesRef={countriesRef}
                                                    handleChange={formik.handleChange}
                                                    handleBlur={formik.handleBlur}
                                                    values={formik.values}
                                                    t={t}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="your-order-area">
                                                <h3>{t("Deine Bestellung")}</h3>
                                                <YourOrder
                                                    productIsDeliverable={productIsDeliverable}
                                                    cartItems={cartItems}
                                                    setFieldValue={formik.setFieldValue}
                                                    grandTotal={grandTotal}
                                                    positions={positions}
                                                    values={formik.values}
                                                    t={t}
                                                />
                                                <YourOkay
                                                    values={formik.values}
                                                    setFieldValue={formik.setFieldValue}
                                                    t={t}
                                                />
                                                <CheckoutButton
                                                    grandTotal={grandTotal}
                                                    errors={formik.errors}
                                                    handleSubmit={formik.handleSubmit}
                                                    loadingLink={loadingLink}
                                                    t={t}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="item-empty-area text-center">
                                        <div className="item-empty-area__icon mb-30">
                                            <ShoppingCart size={95} />
                                        </div>
                                        <div className="">
                                            <Typography variant="subtitle1">
                                                {t("Du hast keine Produkte im Warenkorb")}
                                            </Typography>
                                            <br />
                                            <Box mt={4}>
                                                {shopPageExists ? (
                                                    <Button size="large" variant="contained" onClick={() => navigate(shopUrl)}>
                                                        {t("Jetzt einkaufen")}
                                                    </Button>
                                                ) : (
                                                    <Button size="large" variant="contained" onClick={() => navigate('/')}>
                                                        {t("Zur Startseite")}
                                                    </Button>
                                                )}
                                            </Box>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
        </Fragment>
    );
};

SimplyCheckout.propTypes = {
    sendMessage: PropTypes.func,
    onSetCountry: PropTypes.func
};

export default SimplyCheckout;
