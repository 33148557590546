import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Logo from "./Logo";
import NavMenu from "./NavMenu";
import MobileMenu from "./MobileMenu";
import ActionGroup from "./ActionGroup";
import TopBar from "./TopBar";
import { Box, Stack } from "@mui/material";

const Header = ({ theme, navbar, stickyNav, transparent, showTopbar, sendMessage }) => {

    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);
    const [stick, setStick] = useState(false);

    useEffect(() => {
        const header = document.querySelector(".sticky-bar");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    useEffect(() => {
        if(stickyNav && (scroll > headerTop)){
            setStick(true);
        } else {
            setStick(false);
        }
    }, [scroll, headerTop]);

    if(navbar === "none")
        return null;

    return (
        <header className={`header-area clearfix ${transparent ? "header-absolute" : ""}`} style={transparent ? {} : (theme === 'light' ? {backgroundColor: '#fff'} : {backgroundColor: '#000'})}>

            {showTopbar && (
                <div className={`d-none d-lg-block header-top-area`}>
                    <div className="container">
                        <TopBar theme={theme} />
                    </div>
                </div>
            )}

            <div className={'sticky-bar header-res-padding clearfix' + (stick ? "stick" : "")}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-6 col-4">
                            <Logo theme={stick ? 'light' : theme} />
                        </div>
                        <div className="col-xl-8 col-lg-8 d-none d-lg-block">
                            <NavMenu theme={stick ? 'light' : theme} />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-8">
                            <ActionGroup theme={stick ? 'light' : theme} />
                        </div>
                    </div>
                </div>
                <MobileMenu />
            </div>
        </header>
    );
};

Header.propTypes = {
    navbar: PropTypes.string,
    theme: PropTypes.string,
    showTopbar: PropTypes.bool,
    transparent: PropTypes.bool,
    stickyNav: PropTypes.bool,
    layout: PropTypes.string,
};

Header.defaultProps = {
    theme: "light",
    stickyNav: true,
    transparent: false,
}

export default Header;
