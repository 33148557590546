import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "redux-multilanguage";
import useShopTheme from "../../hooks/useShopTheme";

const LanguageSelector = ({theme, children, ...rest}) => {

    const dispatch = useDispatch();
    const [currentLanguageName, setCurrentLanguageName] = useState("Deutsch");
    const { languages } = useSelector(state => state.shop);
    const { currentLanguageCode } = useSelector(state => state.multilanguage);
    const { getTheme } = useShopTheme();

    const changeLanguageTrigger = e => {
        const languageCode = e.target.value;
        dispatch(changeLanguage(languageCode));
    };

    useEffect(() => {
        if (languages.filter(l => l.ident === currentLanguageCode).length > 0) {
            setCurrentLanguageName(languages.filter(l => l.ident === currentLanguageCode)[0].name);
        } else {
            if(languages.length > 0){
                setCurrentLanguageName(languages[0].name);
            }
        }
    }, [languages, currentLanguageCode]);

    return (
        <div className="language-currency-wrap" {...rest}>
            <div className="same-language-currency language-style">
                <span style={getTheme(theme)}>
                    {currentLanguageName + " "}
                    <i style={getTheme(theme)} className="fa fa-angle-down" />
                </span>
                <div className="lang-car-dropdown">
                    {languages && (
                        <ul>
                            {languages.map((l, i) => (
                                <li key={"lan-" + i}>
                                    <button value={l["ident"]} onClick={e => changeLanguageTrigger(e)}>
                                        {l["name"]}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
            {children}
        </div>
    );
};

export default LanguageSelector;