import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Price from "src/pages/shop/ShopView/ProductPreview/Price";
import { useDispatch, useSelector } from "react-redux";
import { useNotifications } from "src/omnia/hooks/use-notifications";
import _ from "lodash";
import { addToWishlist, removeFromWishlist, updateCart } from "src/redux/actions/shopActions";
import { Facebook, Heart } from "react-feather";
import useTranslator from "src/hooks/useTranslator";
import {
    RadioGroup,
    Tooltip,
    FormLabel,
    Chip,
    FormControl,
    FormControlLabel,
    Radio,
    Grid,
    ButtonGroup,
    Button,
    Box, Alert, Typography, IconButton, Stack
} from "@mui/material";
import produce from "immer";
import { useCartManager } from "../../../../hooks/useCartManager";
import { useTheme } from "@mui/system";
import SocialShare from "../../../../components/SocialShare";

const ProductDescription = ({ product, sendMessage }) => {

    const [productStockActive, setStockActive] = useState(false);
    const [isOnWishlist, setIsOnWishlist] = useState(false);
    const [maximumReached, setMaximumReached] = useState(false);
    const [isDeliverable, setIsDeliverable] = useState(true);
    const [isSoldOut, setIsSoldOut] = useState(false);
    const { productIsDeliverable } = useCartManager();
    const [currentVariant, setCurrentVariant] = useState([]);
    const [choosenVariant, setChoosenVariant] = useState(null);
    const [productSettings, setProductSettings] = useState([]);
    const [availableVariants, setAvailableVariants] = useState([]);
    const [productAddable, setProductAddable] = useState(false);
    const [productInCart, setProductInCart] = useState(false);
    const [currentAmount, setCurrentAmount] = useState(1);
    const { notify } = useNotifications();
    const theme = useTheme();
    const { wishlist, cart, allowPrivacy, deliverable_products } = useSelector(state => state.shop);
    const { t, tp } = useTranslator();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleVisitCart = () => {
        navigate('/cart');
    }

    const handleVisitCheckout = () => {
        navigate('/checkout');
    }

    const renderDescription = () => {
        return {
            __html: tp(product, 'short_description')
        }
    }

    const handleAddToCart = () => {
        if(choosenVariant){
            if(allowPrivacy.includes('usage')){
                sendMessage({
                    type: 'activityUpdate',
                    method: 'add-to-cart',
                    content: "Added " + currentAmount + " time variant #" + choosenVariant.id + " to cart",
                    product_id: product.id,
                });
            }
            dispatch(updateCart(choosenVariant.id, currentAmount));
            setCurrentVariant([]);
            setCurrentAmount(1);
            notify(t("Erfolgreich hinzugefügt"), "success");
        } else {
            notify(t("Bitte Eigenschaften einstellen"), "error");
        }
    }

    const isOptionAvailable = (name, value) => {
        // Check if there is only one setting
        if(productSettings.length === 1)
            return true;
        // Construct the variant that would need to exist
        let toCheckVariant = produce(currentVariant, draft => {
            let index = _.findIndex(currentVariant, {'name': name});
            if(index !== -1){
                draft[index]['value'] = value;
            } else {
                draft = currentVariant.concat({ name: name, value: value });
            }
            return draft;
        });
        // Check if this variant exists in availableVariants
        return availableVariants.filter(v => {
            let allSettingsOkay = true;
            for(let i = 0; i < toCheckVariant.length; i++){
                if(v.variant_values.filter(vv => (vv.name === toCheckVariant[i].name && vv.value === toCheckVariant[i].value)).length === 0){
                    allSettingsOkay = false;
                    break;
                }
            }
            return allSettingsOkay;
        }).length > 0;
    }

    const handleVariantChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if(typeof name !== "undefined" && typeof value !== "undefined"){
            if(allowPrivacy.includes('usage')) {
                sendMessage({
                    type: 'activityUpdate',
                    method: 'variant-change',
                    content: "Changed variant " + name + ": " + value,
                    product_id: product.id,
                });
            }
            setCurrentVariant(produce(currentVariant, draft => {
                let index = _.findIndex(currentVariant, {name: name});
                if(index !== -1){
                    draft[index]['value'] = value;
                } else {
                    draft = currentVariant.concat([{
                        name: name,
                        value: value
                    }]);
                }
                return draft;
            }));
        }
    }

    const handleIncreaseAmount = () => {
        let increaseOkay = true;
        if(product.warehouse_active && choosenVariant && (currentAmount >= choosenVariant.stock))
            increaseOkay = false;
        if(increaseOkay)
            setCurrentAmount(prev => prev + 1);
    }

    const handleDecreaseAmount = () => {
        setCurrentAmount(prev => prev === 1 ? 1 : prev - 1);
    }

    const handleAddToWishlist = () => {
        if(allowPrivacy.includes('usage')){
            sendMessage({
                type: 'activityUpdate',
                method: 'add-to-wishlist',
                content: "Added product #" + product.id + " to wishlist",
                product_id: product.id,
            });
        }
        dispatch(addToWishlist(product.id));
        notify(t("Zu Wunschliste hinzugefügt"), "success");
    }

    const handleRemoveFromWishlist = () => {
        if(allowPrivacy.includes('usage')){
            sendMessage({
                type: 'activityUpdate',
                method: 'remove-from-wishlist',
                content: "Removed product #" + product.id + " from wishlist",
                product_id: product.id,
            });
        }
        dispatch(removeFromWishlist(product.id));
        notify(t("Von Wunschliste entfernt"), "success");
    }

    const getSettingRanges = (variants) => {
        let takenMetrics = [];
        let settings = [];
        let metricName = [];
        let index = -1;
        let metricValue = [];
        for(let i = 0; i < variants.length; i++){
            for(let v = 0; v < variants[i].variant_values.length; v++){
                metricName = variants[i].variant_values[v].name;
                metricValue = variants[i].variant_values[v].value;
                if(takenMetrics.includes(metricName)){
                    // Already appended
                    index = _.findIndex(settings, {name: metricName});
                    if(!settings[index]['range'].includes(metricValue))
                        settings[index]['range'] = settings[index]['range'].concat([metricValue]);
                } else {
                    // New metric
                    takenMetrics = takenMetrics.concat([metricName]);
                    settings = settings.concat([{
                        'name': metricName,
                        'range': [metricValue]
                    }]);
                }
            }
        }
        return settings;
    }

    const isOptionChecked = (name, value) => {
        return currentVariant.filter(v => v.name === name && v.value === value).length > 0;
    }

    useEffect(() => {
        let settings = getSettingRanges(product.variants);
        setIsDeliverable(productIsDeliverable(product.id));
        setProductSettings(settings);
    }, [deliverable_products, product]);

    useEffect(() => {
        let availableVariants = product.variants.filter(v => v.variant_values.filter(vv => {
            if(currentVariant.filter(s => s.name === vv.name).length === 0){
                return true;
            } else {
                let vvSetting = currentVariant.find(s => s.name === vv.name);
                return vvSetting.value === vv.value;
            }
        }).length > 0);
        let newChoosenVariant = null;
        if(product.variants.length === 1){
            newChoosenVariant = product.variants[0];
        } else {
            newChoosenVariant = product.variants.find(v => {
                if(v.variant_values.length === currentVariant.length){
                    let settingsMatch = true;
                    for(let i = 0; i < v.variant_values.length; i++){
                        if(currentVariant.filter(cv => cv.name === v.variant_values[i].name && cv.value === v.variant_values[i].value).length === 0){
                            settingsMatch = false;
                            break;
                        }
                    }
                    return settingsMatch;
                } else {
                    return false;
                }
            });
        }

        if(typeof newChoosenVariant === "undefined"){
            setChoosenVariant(null);
        } else {
            setChoosenVariant(newChoosenVariant);
        }
        setAvailableVariants(availableVariants);
    }, [product, productSettings, currentVariant]);

    useEffect(() => {
        setIsSoldOut(product.warehouse_active && choosenVariant && choosenVariant.stock === 0);
        setIsOnWishlist(wishlist.includes(product.id));
        setStockActive(product.warehouse_active);
    }, [product, wishlist, choosenVariant]);

    useEffect(() => {
        let stockOkay;
        let variantSelected = (choosenVariant !== null);
        if(productStockActive){
            stockOkay = (currentAmount <= product.stock) && (currentAmount > 0);
            if(choosenVariant){
                if(currentAmount > choosenVariant.stock){
                    if(currentAmount !== 1)
                        notify(t("Es sind nur noch " + choosenVariant.stock + " Produkte verfügbar"), "error");
                    setCurrentAmount(choosenVariant.stock);
                }
            }
        } else {
            stockOkay = currentAmount > 0;
        }
        if(product.warehouse_active && choosenVariant){
            setMaximumReached(currentAmount >= choosenVariant.stock);
        } else {
            setMaximumReached(false);
        }
        setProductAddable(stockOkay && variantSelected);
    }, [product, currentAmount, currentVariant, choosenVariant, productStockActive]);

    useEffect(() => {
        setProductInCart(cart.filter(c => product.variants.map(v => v.id).includes(c.id)).length > 0);
    }, [product, cart]);

    return (
        <div className="product-details-content ml-70">
            <Stack direction="column" spacing={1}>
                <h2>{tp(product, 'name')}</h2>
                <Typography variant="h5" sx={{color: theme.palette.primary.main, fontWeight: 600}}>
                    <Price product={product} variant={choosenVariant} />
                </Typography>
                <Typography variant="caption" color="textSecondary">
                    {t('Inkl. MwSt. | zzgl. Versandkosten')}
                </Typography>
            </Stack>
            <div className="pro-details-list">
                <p dangerouslySetInnerHTML={renderDescription()} />
                <Grid container spacing={1} mt={2}>
                    <Grid item xs={12}>
                        {product.categories.map((single, key) => (
                            <Chip color="primary" size="small" key={key} label={single} sx={{mr: 1, mb: 1}} />
                        ))}
                    </Grid>
                </Grid>
            </div>
            {product.variants.length > 1 && (
                <Grid container spacing={1} sx={{mb: 3}}>
                    {productSettings.map((setting, i) => (
                        <Grid item xs={12} key={'setting-' + i}>
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{mb: 0}}>{t(setting.name)}</FormLabel>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    {setting.range.map((r, i) => {
                                        let isChecked = isOptionChecked(setting.name, r);
                                        return (
                                            <div key={i}>
                                                {isOptionAvailable(setting.name, r) ? (
                                                    <FormControlLabel
                                                        value={r}
                                                        control={<Radio checked={isChecked} />}
                                                        name={setting.name}
                                                        checked={isChecked}
                                                        onClick={handleVariantChange}
                                                        label={t(r)}
                                                    />
                                                ) : (
                                                    <Tooltip placement="top" title={t("Nicht verfügbar")}>
                                                        <FormControlLabel
                                                            value={r}
                                                            control={<Radio checked={isChecked} />}
                                                            name={setting.name}
                                                            checked={isChecked}
                                                            onClick={handleVariantChange}
                                                            label={t(r)}
                                                            disabled
                                                        />
                                                    </Tooltip>
                                                )}
                                            </div>
                                        )
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    ))}
                </Grid>
            )}
            {maximumReached && (
                <Grid container spacing={1} sx={{mb: 3}}>
                    <Grid item xs={12}>
                        <Alert severity="info">
                            {choosenVariant.stock === 0 ? (
                                <Typography variant="body1" color="textPrimary">
                                    {t("Das Produkt ist leider ausverkauft")}
                                </Typography>
                            ) : (
                                <Typography variant="body1" color="textPrimary">
                                    {t("Eine größere Menge ist aktuell leider nicht verfügbar")}
                                </Typography>
                            )}
                        </Alert>
                    </Grid>
                </Grid>
            )}
            <div className="pro-details-quality" style={{marginTop: 1}}>

                {productInCart ? (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Alert severity="success">
                                {t("Das Produkt befindet sich im Warenkorb")}
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button size="medium" onClick={handleVisitCart} fullWidth variant="outlined">
                                {t("Zum Warenkorb")}
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button size="medium" onClick={handleVisitCheckout} fullWidth variant="contained">
                                {t("Zum Checkout")}
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        <ButtonGroup size="medium" aria-label="large button group">
                            <Button disabled={isSoldOut || !isDeliverable} onClick={handleDecreaseAmount}>-</Button>
                            <Button disabled={isSoldOut || !isDeliverable}>{currentAmount}</Button>
                            <Button disabled={isSoldOut || !isDeliverable} onClick={handleIncreaseAmount}>+</Button>
                        </ButtonGroup>
                        <Box sx={{ml: 1}}>
                            {!isDeliverable ? (
                                <Button size="medium" disabled variant="contained">
                                    {t("Nicht Verfügbar")}
                                </Button>
                            ) : (
                                <Button size="medium" disabled={!productAddable} onClick={handleAddToCart} variant="contained">
                                    {isSoldOut ? t("Ausverkauft") : t("Hinzufügen")}
                                </Button>
                            )}
                        </Box>
                        <Box sx={{ml: 1}}>
                            {isOnWishlist ? (
                                <Tooltip title={t("Auf der Wunschliste")} placement="top">
                                    <Button size="medium" variant="contained" onClick={handleRemoveFromWishlist}>
                                        <Heart />
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Button size="medium" variant="outlined" onClick={handleAddToWishlist}>
                                    <Heart />
                                </Button>
                            )}
                        </Box>
                    </>
                )}
            </div>
            {product.categories && (
                <Grid container spacing={1} sx={{mt: 6}}>
                    <Grid item xs={12}>
                        <FormLabel sx={{mb: 0}}>{t("Schlagworte")}</FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                        {product.tags.map((single, key) => (
                            <Chip size="small" key={key} sx={{mr: 1, mb: 1}} label={single} />
                        ))}
                    </Grid>
                </Grid>
            )}
            <SocialShare style={{marginTop: 10}} />
        </div>
    );
};

ProductDescription.propTypes = {
    product: PropTypes.object,
    onAddToCart: PropTypes.func,
};

export default ProductDescription;
