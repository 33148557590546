import {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {APP_SETTING} from "src/setup";
import authService from "src/omnia/services/auth-service";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import {useMounted} from "src/omnia/hooks/use-mounted";

/**
 * Gn Socket SDK
 *
 * This hook can be used by an gn application to easily connect to the gn Socket API
 * and exchange data. It also supports the management of the sockets of the gn API.
 *
 * TODO WARNING: DEPRECATED! Use "use-socket" instead!
 */

const RECONNECT_MS = 5000;

export const useHook = (url, onReceive, ignoreLogin=false) => {

    const [client, setClient] = useState(null);
    const [socketOnline, setSocketOnline] = useState(false);
    const [queue, setQueue] = useState([]);
    const { screenIsVisible } = useNotifications();
    const isMountedRef = useMounted();
    const accountData = useSelector(state => state.account);
    const [loggedIn, setLoggedIn] = useState(((typeof accountData !== "undefined") && (typeof accountData.user !== "undefined")));
    const userRef = useRef(null);
    const clientRef = useRef(null);
    const visibilityRef = useRef(null);
    const urlRef = useRef(null);

    userRef.current = ((typeof accountData !== "undefined") && (typeof accountData.user !== "undefined")) ? accountData.user : null;
    visibilityRef.current = screenIsVisible;
    clientRef.current = client;
    urlRef.current = url;

    const omniaEncode = (data) => {
        // initiate encrypted variable
        // let encoded = "";
        // let tmp = 0;
        let original = data;

        // check if message is already string
        if(typeof data !== 'string')
            original = JSON.stringify(data);

        // // check if contains emoji
        // if(/\p{Extended_Pictographic}/u.test(original)){
        //     encoded = 'i6asuzdk' + original;
        // } else {
        //     encoded += String.fromCharCode(tmp + 1);
        // }
        //
        // // encode the string
        // for(let i = 0; i < original.length; i++){
        //     tmp = original.charCodeAt(i);
        //     encoded += String.fromCharCode(tmp + 1);
        // }

        // todo: later return encoded
        return original;
    }

    const omniaDecode = (data) => {
        // // initiate encrypted variable
        // let decoded = "";
        // let tmp = 0;
        // let original = data;
        // // check if message is already string
        // for(let i = 0; i < original.length; i++){
        //     tmp = original.charCodeAt(i);
        //     decoded += String.fromCharCode(tmp - 1);
        // }

        // todo: later return decoded
        return JSON.parse(data);
    }

    const generateRandomString = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;

        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    }

    const createUrl = (url) => {

        let domain = APP_SETTING.domain;
        if(APP_SETTING.domain === 'localhost:3000')
            domain = 'localhost:8000';

        // Initiate a response variable
        let response = APP_SETTING.socket_protocol + "://" + domain + '/api';

        // Check for correct slashes etc.
        if(url.substr(0, 1) !== "/")
            url = "/" + url
        if(url.substr(url.length - 1, url.length) === "/"){
            url = url.substr(0, url.length - 1);
        }

        // Check whether there is an omnia id otherwise create one
        let omnia_id = localStorage.getItem('omnia_id');
        if (!omnia_id){
            omnia_id = generateRandomString(20);
            localStorage.setItem('omnia_id', omnia_id);
        }

        // Create actual response URL string
        if(ignoreLogin){
            response += url + '?omnia_id=' + omnia_id;
        } else {
            response += url + "?token=" + authService.getAccessToken() + '&omnia_id=' + omnia_id;
        }

        // Return the string
        return response;
    }

    const greenLightForReconnect = () => {
        // Determines whether to reconnect when disconnected
        if(!visibilityRef.current)
            return true;
        return isMountedRef();
    }

    const handleInboundMessage = (message) => {

        // decode incoming data package
        let packet = omniaDecode(message.data);

        // check for availability test
        if( packet && ("type" in packet) && (packet['type'] === 'availability_test') ){
            // respond to availability test
            sendMessage(JSON.stringify({
                systemMessage: 'oa7sgdp56sdf7zt',
                available: true,
                visible: visibilityRef.current
            }));
        } else {
            // call receive handler
            if(onReceive){
                onReceive(packet);
            }
        }

    }

    const handleConnectionLoss = (userRef) => {
        setSocketOnline(false);
        if(isMountedRef()){
            // attempt reconnection
            if(ignoreLogin){
                setTimeout(connectToWebsocket, RECONNECT_MS);
            } else if (userRef.current) {
                setTimeout(connectToWebsocket, RECONNECT_MS);
            }
        }
    }

    const handleConnectionEstablished = () => {
        setSocketOnline(true);
    }

    const handleConnectionError = () => {

    }

    const connectToWebsocket = () => {
        if(greenLightForReconnect()){
            try {
                let newSocket = new WebSocket( createUrl(urlRef.current));
                newSocket.onmessage = (message) => handleInboundMessage(message);
                newSocket.onclose = () => handleConnectionLoss(userRef);
                newSocket.onopen = () => handleConnectionEstablished(userRef);
                newSocket.onerror = () => handleConnectionError(userRef);

                // TODO: this could potentially be something for the future
                // if(!ignoreLogin){
                //     const token = authService.getAccessToken();
                //     newSocket.send(JSON.stringify({
                //         systemMessage: 'socket.auth',
                //         token: token,
                //     }));
                // }

                setClient(newSocket);
            } catch (e) {
                console.log('Websocket connection not possible');
            }
        }
    }

    const disconnectFromWebsocket = (ref) => {
        if(ref && ref.current){
            ref.current.close();
        } else {
            if(client)
                client.close()
        }
        setClient(null);
    }

    const sendMessage = (message) => {
        if(client && client.readyState === 1){
            // initiate encrypted variable
            client.send(omniaEncode(message));
        } else {
            setQueue(prev => prev.concat([message]));
        }
    }

    useEffect(() => {
        // Try to get the user from the account data (if exists)
        let user = ((typeof accountData !== "undefined") && (typeof accountData.user !== "undefined")) ? accountData.user : null;
        let userExists = user !== null;
        if(userExists && !loggedIn)
            setLoggedIn(true);
        if(!userExists && loggedIn)
            setLoggedIn(false);
    }, [loggedIn, accountData]);

    useEffect(() => {
        sendMessage(JSON.stringify({
            systemMessage: 'u65azfdjtku7',
            visible: screenIsVisible
        }));
    }, [screenIsVisible]);

    useEffect(() => {
        if(socketOnline && queue.length > 0){
            for(let i = 0; i < queue.length; i++)
                sendMessage(queue[i]);
            setQueue([]);
        }
    }, [socketOnline, queue]);

    useEffect(() => {
        if(ignoreLogin){
            connectToWebsocket();
        } else {
            if(loggedIn){
                connectToWebsocket();
            } else {
                disconnectFromWebsocket();
            }
        }
        return () => {
            disconnectFromWebsocket(clientRef);
        };
    }, [url, loggedIn, ignoreLogin]);

    return { sendMessage, socketOnline };

}