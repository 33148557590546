import {
    UPDATE_SETTING,
    UPDATE_SCROLL,
    UPDATE_VISITOR,
    RESET_TRANSLATION_STORE,
    UPDATE_MISSING_TRANSLATION,
    MARK_SYNCED_MISSING_TRANSLATION,
    UPDATE_CART, CLEAR_CART, REMOVE_VOUCHER,
    REMOVE_FROM_CART, ADD_VOUCHER,
    UPDATE_WISHLIST, CLEAR_WISHLIST,
    REMOVE_FROM_WISHLIST, RESET_VOUCHERS,
    SET_COUNTRY, RESET_COUNTRY,
    SET_DELIVERABLE_PRODUCTS,
    SET_DELIVERABLE_COUNTRIES, UPDATE_SHIPPING_COST,
    SET_COUNTRIES, SET_PRIVACY_HANDLED, ADD_PRIVACY_SETTING,
    REMOVE_PRIVACY_SETTING, UPDATE_PRIVACY_SETTING, UPDATE_OPEN_MOTIVATIONS
} from "../actions/shopActions";
import produce from "immer";
import _ from "lodash";

const initState = {

    // Core data from the site
    sites: [],
    languages: [],
    products: [],

    // Stores the open motivations
    openMotivations: [],

    // List of product ids that are deliverable based on the current position
    deliverable_products: [],
    deliverable_countries: [],

    // Site setup information
    chat_on: false,
    blocked: false,
    analytics: false,
    maintenance: false,
    connection: false,
    initialized: false,
    contact_email: "",

    // Privacy settings
    handled: false,
    allowPrivacy: [],

    // CartView and checkout
    cart: [],
    shippingCost: null,
    wishlist: [],

    // The country that the user is in
    country: null,
    countries: [],

    // Coupons that are active
    vouchers: [],

    // Storage of translations that the translator could not handle
    missing_translations: {},

    // CheckoutView and current payment information
    checkout_link: null,
    payment_status: null,

    // Tracking software
    facebook_pixel: null,
    google_analytics_key: null,

    // The last open order
    open_order: null,

    // Data about this visitor
    visitor: {},

    // Where the visitor is currently scrolled
    scroll: 0,
};

const shopReducer = (state = initState, action) => {

    // Check the action type
    switch (action.type) {

        case UPDATE_OPEN_MOTIVATIONS: {
            const { motivations } = action.payload;
            return produce(state, draft => {
                draft.openMotivations = motivations;
            })
        }

        case UPDATE_PRIVACY_SETTING: {
            const { allPrivacySettings } = action.payload;
            return produce(state, draft => {
                draft.allowPrivacy = allPrivacySettings;
            });
        }

        case ADD_PRIVACY_SETTING: {
            const { privateToAdd } = action.payload;
            return produce(state, draft => {
                if(!state.allowPrivacy.includes(privateToAdd)){
                    draft.allowPrivacy.push(privateToAdd);
                }
            });
        }

        case REMOVE_PRIVACY_SETTING: {
            const { privateToRemove } = action.payload;
            return produce(state, draft => {
                draft.allowPrivacy = state.allowPrivacy.filter(p => p !== privateToRemove);
            });
        }

        case SET_PRIVACY_HANDLED: {
            return produce(state, draft => {
                draft.handled = true;
            });
        }

        case SET_COUNTRIES: {
            const { allCountries } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.countries = allCountries;
            });
        }

        case UPDATE_SHIPPING_COST: {
            const { shippingCost } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.shippingCost = shippingCost;
            });
        }

        case SET_DELIVERABLE_PRODUCTS: {
            const { deliverables } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.deliverable_products = deliverables;
            });
        }

        case SET_DELIVERABLE_COUNTRIES: {
            const { deliverableCountries } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.deliverable_countries = deliverableCountries;
            })
        }

        case SET_COUNTRY: {
            const { countryData } = action.payload;
            return produce(state, draft => {
                draft.country = countryData;
                draft.visitor.country_code = countryData.code;
            })
        }

        case RESET_COUNTRY: {
            return produce(state, draft => {
                draft.country = null;
            })
        }

        case REMOVE_VOUCHER: {
            const { voucherCode } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.vouchers, {'code': voucherCode})
                if(index !== -1)
                    draft.vouchers = state.vouchers.filter(i => i.code !== voucherCode);
            })
        }

        case RESET_VOUCHERS: {
            return produce(state, draft => {
                draft.vouchers = [];
            });
        }

        case ADD_VOUCHER: {
            const { voucher } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.vouchers, {id: voucher.id});
                if(index !== -1){
                    draft.vouchers[index] = voucher;
                } else {
                    draft.vouchers = state.vouchers.concat([voucher])
                }
            });
        }

        case UPDATE_CART: {
            const { variantId, quantity } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.cart, {id: variantId});
                if(index !== -1){
                    if(quantity === 0){
                        draft.cart = state.cart.filter(c => c.id !== variantId);
                    } else {
                        draft.cart[index]['quantity'] = quantity;
                    }
                } else {
                    if(quantity > 0){
                        draft.cart = state.cart.concat([{
                            id: variantId,
                            quantity: quantity
                        }])
                    }
                }
            });
        }

        case UPDATE_WISHLIST: {
            const { productId } = action.payload;
            return produce(state, draft => {
                if(productId){
                    if(!state.wishlist.includes(productId))
                        draft.wishlist = state.wishlist.concat([productId]);
                }
                draft.wishlist = draft.wishlist.filter(i => i !== null);
            })
        }

        case REMOVE_FROM_WISHLIST: {
            const { removeProductId } = action.payload;
            return produce(state, draft => {
                let index = state.wishlist.indexOf(removeProductId);
                if(index !== -1)
                    draft.wishlist = state.wishlist.filter(i => i !== removeProductId);
            })
        }

        case REMOVE_FROM_CART: {
            const { removeCartId } = action.payload;
            return produce(state, draft => {
                // let index = state.cart.map(c => c.id).indexOf(removeCartId);
                let index = _.findIndex(state.cart, {'id': parseInt(removeCartId)})
                if(index !== -1)
                    draft.cart = state.cart.filter(i => i.id !== removeCartId);
            })
        }

        case CLEAR_CART: {
            return produce(state, draft => {
                draft.cart = [];
            })
        }

        case CLEAR_WISHLIST: {
            return produce(state, draft => {
                draft.wishlist = [];
            })
        }

        case RESET_TRANSLATION_STORE: {
            return produce(state, draft => {
                draft.missing_translations = {};
            })
        }

        case MARK_SYNCED_MISSING_TRANSLATION: {
            const { translation } = action.payload;
            return produce(state, draft => {
                if(Object.keys(state.missing_translations).includes(translation)){
                    draft.missing_translations[translation]['synced'] = true;
                }
            })
        }

        case UPDATE_MISSING_TRANSLATION: {
            const { phrase } = action.payload;
            return produce(state, draft => {
                // check if no dict
                if(typeof(state.missing_translations) !== "undefined") {
                    if (!Object.keys(state.missing_translations).includes(phrase)) {
                        draft.missing_translations[phrase] = {
                            value: phrase,
                            active: new Date(),
                            synced: null
                        }
                    }
                } else {
                    draft.missing_translations = {};
                }
            })
        }

        case UPDATE_VISITOR: {
            const { visitor } = action.payload;
            return produce(state, draft => {
                if(typeof(state.visitor) === "undefined")
                    draft.visitor = {}
                draft.visitor = visitor;
            });
        }

        case UPDATE_SCROLL: {
            const { scroll } = action.payload;
            return produce(state, draft => {
                draft.scroll = scroll;
            })
        }

        case UPDATE_SETTING: {
            const { data, key } = action.payload;
            return produce(state, draft => {
                if(typeof data[key] !== "undefined"){
                    draft[key] = data[key];
                    if(key === "sites")
                        draft.initialized = true;
                } else {
                    console.log('Key ' + key + ' does not exist in care package.');
                }
            });
        }

        default: {
            return state;
        }

    }

};

export default shopReducer;
